
<div class="px-0 py-4">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane">
            
            <h6>Web Crawl</h6>
            <div class="comingSoonCenter">
                <div>
                    <img src="assets/Icons/coming-soon.png" width="65px">
                    Our team is hard at work to bring you an exciting new feature!
                </div>
            </div> 

           
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <h6>Insights & Engagement Zone</h6>
            <div class="comingSoon text-center">
                <img src="assets/images/analytics_bg.png" style="width: 100%; height: calc(100vh - 50vh); object-fit: cover; object-position: -236px; ">
                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
            </div>
        </div>
    </div>
</div>