<div class="px-0 pb-2">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div
        class="d-flex mb-2"
        style="align-items: center; justify-content: space-between"
      >
        <h5>Organization Hierarchy</h5>
      </div>
      <div id="wrapper">
        <div class="contain">
          <span id="organisationName" class="label p1">{{ orgName }}</span>
          <div class="branch lv1">
            <ng-container *ngFor="let port of dataList; let i = index">
              <div class="entry">
                <span class="label p2">
                  <div class="d-flex justify-content-between">
                    <span class="port-truncate-name">{{ port?.name }}</span>
                    <i
                      class="fas fa-edit edit cursor-pointer"
                      (click)="
                        onClickCreatePortfolio(
                          createPortfolioPopup,
                          'update',
                          port
                        )
                      "
                    ></i>
                  </div>
                  <div class="d-flex mt-2" *ngIf="port?.owner">
                    <mat-icon style="font-size: 14px">account_box</mat-icon>
                    <span class="truncate-name"
                      >Owner : {{ port?.owner?.first_name }}
                      {{ port?.owner?.last_name }}</span
                    >
                  </div>
                  <span class="tooltiptext"
                    ><span class="span-text">Portfolio : </span> {{ port?.name
                    }}<br />
                    <span class="span-text">Owner : </span
                    >{{ port?.owner?.first_name }}
                    {{ port?.owner?.last_name }}</span
                  >
                </span>

                <div class="branch lv2">
                  <ng-container *ngFor="let subport of port?.children">
                    <div class="entry cursor-pointer">
                      <span class="label p3">
                        <div class="d-flex justify-content-between">
                          <span class="port-truncate-name">{{
                            subport?.name
                          }}</span>
                          <i
                            class="fas fa-edit edit cursor-pointer"
                            (click)="
                              onClickCreateSubPortfolio(
                                port,
                                createSubPortfolioPopup,
                                subport
                              )
                            "
                          ></i>
                          <!-- <mat-icon  style="font-size: 14px;height: 14px;width: 14px;" >edit</mat-icon> -->
                        </div>
                        <div class="d-flex mt-2" *ngIf="subport?.owner">
                          <mat-icon style="font-size: 14px"
                            >account_box</mat-icon
                          >
                          <span class="truncate-name"
                            >Owner : {{ subport?.owner?.first_name }}
                            {{ subport?.owner?.last_name }}</span
                          >
                        </div>
                        <span class="tooltiptext"
                          ><span class="span-text"> Portfolio :</span>
                          {{ port?.name }}<br />
                          <span class="span-text">Subportfolio :</span>
                          {{ subport?.name }}<br />
                          <span class="span-text">Owner :</span>
                          {{ subport?.owner?.first_name }}
                          {{ subport?.owner?.last_name }}<br />
                          <span class="span-text">Cost Center Name :</span>
                          {{ subport?.cost_center_name }}<br />
                          <span class="span-text">Cost Center Code :</span>
                          {{ subport?.cost_center_code }}<br />
                          <span class="span-text"
                            >Organization Unit Type :</span
                          >
                          {{ getunitType(subport?.org_unit_type) }}
                        </span>
                      </span>
                    </div>
                  </ng-container>
                  <div
                    class="entry cursor-pointer"
                    (click)="
                      onClickCreateSubPortfolio(port, createSubPortfolioPopup)
                    "
                    [ngClass]="{ sole: port?.children.length === 0 }"
                  >
                    <span class="label p5 d-flex justify-content-center">
                      <mat-icon style="font-size: 20px; margin-top: -2px"
                        >add</mat-icon
                      >
                      <span>Create Subportfolio</span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              class="entry cursor-pointer"
              [ngClass]="{ sole: dataList?.length === 0 }"
              (click)="onClickCreatePortfolio(createPortfolioPopup, 'add')"
            >
              <span class="label p4 d-flex justify-content-center">
                <mat-icon style="font-size: 20px">add</mat-icon>
                <span>Create Portfolio</span>
              </span>
            </div>
            <!-- <div class="entry"><span class="label p2">Entry-5</span></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
    </div>
  </div>
</div>

<ng-template #createPortfolioPopup>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Create Portfolio'">
      </app-modal-header-common>
      <div class="modal-body p-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Portfolio</mat-label>
          <input matInput [(ngModel)]="portfolioName" placeholder="Portfolio" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Owner</mat-label>
          <mat-select [(ngModel)]="selectedOwner">
            <mat-option *ngFor="let user of userList" [value]="user.id"
              >{{ user?.first_name }} {{ user?.last_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="float-right">
          <button
            type="submit"
            class="btn btn-primary ml-2 mb-2"
            (click)="savePortfolio()"
          >
            {{ portfolioId ? "Update" : "Submit" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createSubPortfolioPopup>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Create Subportfolio'">
      </app-modal-header-common>
      <div class="modal-body p-4">
        <div class="d-flex mb-2" style="align-items: center">
          <span class="sjec obj">Portfolio: </span>
          <p class="mb-0">{{ portfolioName }}</p>
        </div>
        <mat-form-field appearance="outline" class="w-100 mt-1">
          <mat-label>Subportfolio</mat-label>
          <input
            matInput
            [(ngModel)]="subportfolioName"
            placeholder="Subportfolio"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Owner</mat-label>
          <mat-select [(ngModel)]="selectedOwner">
            <mat-option *ngFor="let user of userList" [value]="user.id"
              >{{ user?.first_name }} {{ user?.last_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 mt-1">
          <mat-label>Cost Center Name</mat-label>
          <input
            matInput
            [(ngModel)]="costCenterName"
            placeholder="Cost Center Name"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 mt-1">
          <mat-label>Cost Center Code</mat-label>
          <input
            matInput
            [(ngModel)]="costCenterCode"
            placeholder="Cost Center Code"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Organization Unit Type</mat-label>
          <mat-select [(ngModel)]="orgUnitType">
            <mat-option *ngFor="let org of orgUnitTypeList" [value]="org.id">{{
              org?.display_name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="float-right">
          <button
            type="submit"
            class="btn btn-primary ml-2 mb-2"
            (click)="saveSubPortfolio()"
          >
            {{ subPortfolioId ? "Update" : "Submit" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
