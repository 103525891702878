<div class="single-center-pane">
  <div class="row">
    <div class="col-sm-5 trn-trc">
      <h5>Governance Lighthouse</h5>
      <button
        class="small-icon-button mb-2"
        mat-icon-button
        (click)="showImageDialog()"
        matTooltip="More information"
      >
        <mat-icon>info</mat-icon>
      </button>
    </div>
    <div class="col-sm-7 trn-trc-1 ml-auto">
      <button
        mat-flat-button
        mat-button
        color="primary"
        class="bg-primary text-white mr-2"
        (click)="navigate(1)"
      >
        Governance Lighhouse Library
      </button>
      <button
        mat-flat-button
        mat-button
        color="primary"
        class="bg-primary text-white"
        (click)="navigate()"
      >
        <mat-icon>add</mat-icon> Create Assessment
      </button>
    </div>
  </div>
  <app-loading *ngIf="!assessmentsLoaded"></app-loading>
  <div *ngIf="assessmentsLoaded">
    <div class="filter-container mb-2 mt-3">
      <div class="filter-icon">
        <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
      </div>
      <input
        type="text"
        placeholder="Search Category..."
        class="form-control text-input"
        [(ngModel)]="filterCategory"
        (ngModelChange)="search()"
        style="
          min-width: 13rem;
          border-radius: 8px;
          font-size: 12px;
          height: 40px;
        "
      />
      <input
        type="text"
        placeholder="Search Name..."
        class="form-control text-input"
        [(ngModel)]="filterName"
        (ngModelChange)="search()"
        style="
          min-width: 13rem;
          border-radius: 8px;
          font-size: 12px;
          height: 40px;
        "
      />
      <mat-form-field
        appearance="outline"
        class="filter-dropdown"
        style="font-size: 12px"
      >
        <input
          matInput
          [matDatepicker]="picker1"
          autocomplete="off"
          (click)="picker1.open()"
          [(ngModel)]="filterDueDate"
          (ngModelChange)="search()"
          placeholder="Search due date..."
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterStatus"
          (ngModelChange)="search()"
          placeholder="Search Status..."
        >
          <mat-option value=""></mat-option>
          <mat-option value="pending">Pending</mat-option>
          <mat-option value="In-progress">In-Progress</mat-option>
          <mat-option value="completed">Completed</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterPortfolio"
          (ngModelChange)="search()"
          placeholder="Search Portfolio..."
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let portfolio of portfolioList"
            value="{{ portfolio.id }}"
            >{{ portfolio.display_name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterSubportfolio"
          (ngModelChange)="search()"
          placeholder="Search Subportfolio..."
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let subportfolio of subportfolioList"
            value="{{ subportfolio.id }}"
            >{{ subportfolio.display_name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterProgram"
          (ngModelChange)="search()"
          placeholder="Search Program..."
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let program of programList"
            value="{{ program.id }}"
            >{{ program.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterProject"
          (ngModelChange)="search()"
          placeholder="Search Project..."
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let project of projectList"
            value="{{ project.id }}"
            >{{ project.project_name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterVendor"
          (ngModelChange)="search()"
          placeholder="Search Vendor..."
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let vendor of vendorList"
            value="{{ vendor.id }}"
            >{{ vendor.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-select
          [(ngModel)]="filterOwner"
          (ngModelChange)="search()"
          placeholder="Search Owner..."
        >
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let owner of ownersList" value="{{ owner.id }}">{{
            owner.first_name + " " + owner.last_name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab
        label="Assessments assigned to you ({{
          mappedAssessments.length ? mappedAssessments.length : 0
        }})"
      >
        <div style="overflow-x: hidden">
          <div class="tab-holder mt-3">
            <div class="accordion" id="accordionPanel">
              <div
                *ngFor="let category of mappedAssessments; let i = index"
                class="accordion-item"
              >
                <h2 class="accordion-header" id="heading{{ i }}">
                  <button
                    class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#collapse{{ i }}"
                    aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                    aria-controls="collapse{{ i }}"
                    style="font-size: 13px; font-weight: 700"
                  >
                    {{ category.category }}
                  </button>
                </h2>
                <div
                  id="collapse{{ i }}"
                  class="accordion-collapse collapse {{
                    i === 0 ? 'show' : ''
                  }}"
                  aria-labelledby="heading{{ i }}"
                  data-bs-parent="#accordionPanel"
                >
                  <div class="accordion-body p-2">
                    <div class="flex-container-1">
                      <div
                        *ngIf="
                          category.assessments &&
                          category.assessments?.length <= 0
                        "
                        class="trn-no"
                      >
                        No assessments assigned to you.
                      </div>
                      <app-loading
                        style="width: 100%; min-height: 200px"
                        *ngIf="!category.assessments"
                      ></app-loading>
                      <div
                        class="flex-item p-1"
                        *ngFor="let x of category.assessments"
                      >
                        <div
                          class="trn-x"
                          [ngStyle]="{ background: x.color }"
                          style="height: 200px"
                          (click)="openAssessment(x)"
                        >
                          <h6 [matTooltip]="x.name" class="mb-0 trn-nm">
                            {{ x.name }}
                          </h6>
                          <div class="mb-0 mt-2 trn-ph" *ngIf="x.created_by">
                            <div class="d-flex align-items-center">
                              <mat-icon class="small-icon mr-1"
                                >perm_identity</mat-icon
                              >
                              <span>Owner</span>
                            </div>
                            <span style="display: flex; font-weight: 700">
                              {{
                                x.created_by.first_name +
                                  " " +
                                  x.created_by.last_name
                              }}
                            </span>
                          </div>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Created On
                            <span style="display: flex; font-weight: 700">
                              {{ x.start_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Due By
                            <span style="display: flex; font-weight: 700">
                              {{ x.end_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <span class="shw-status" style="bottom: 45px">{{
                            x.status
                          }}</span>
                          <div class="row tmg">
                            <div class="col-sm-9 ta">
                              <p class="mb-0 tap">
                                Updated {{ x.updated_at | timeago }}
                              </p>
                            </div>
                            <div class="col-sm-3">
                              <app-owner
                                [editable]="false"
                                [ownerdata]="x.owners"
                                *ngIf="x?.owners?.length > 0"
                              ></app-owner>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="mappedAssessments?.length === 0" class="text-center">
              No assessments assigned to you
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="Assessments created by you ({{
          ownAssessments.length ? ownAssessments.length : 0
        }})"
      >
        <div style="overflow-x: hidden">
          <div class="tab-holder mt-3">
            <div class="accordion" id="accordionPanel">
              <div
                *ngFor="let category of ownAssessments; let i = index"
                class="accordion-item"
              >
                <h2 class="accordion-header" id="heading{{ i }}">
                  <button
                    class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#collapse{{ i }}"
                    aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                    aria-controls="collapse{{ i }}"
                    style="font-size: 13px; font-weight: 700"
                  >
                    {{ category.category }}
                  </button>
                </h2>
                <div
                  id="collapse{{ i }}"
                  class="accordion-collapse collapse {{
                    i === 0 ? 'show' : ''
                  }}"
                  aria-labelledby="heading{{ i }}"
                  data-bs-parent="#accordionPanel"
                >
                  <div class="accordion-body p-2">
                    <div class="flex-container-1">
                      <div
                        *ngIf="
                          category.assessments &&
                          category.assessments?.length <= 0
                        "
                        class="trn-no"
                      >
                        You haven't created any assessments yet.
                      </div>
                      <app-loading
                        style="width: 100%; min-height: 200px"
                        *ngIf="!category.assessments"
                      ></app-loading>
                      <div
                        class="flex-item p-1"
                        *ngFor="let x of category.assessments"
                      >
                        <div
                          class="trn-x"
                          [ngStyle]="{ background: x.color }"
                          style="height: 200px"
                          (click)="openResult(x)"
                        >
                          <h6 [matTooltip]="x.name" class="mb-0 trn-nm">
                            {{ x.name }}
                          </h6>
                          <div class="mb-0 mt-2 trn-ph" *ngIf="x.created_by">
                            <div class="d-flex align-items-center">
                              <mat-icon class="small-icon mr-1"
                                >perm_identity</mat-icon
                              >
                              <span>Owner</span>
                            </div>
                            <span style="display: flex; font-weight: 700">
                              {{
                                x.created_by.first_name +
                                  " " +
                                  x.created_by.last_name
                              }}
                            </span>
                          </div>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Created On
                            <span style="display: flex; font-weight: 700">
                              {{ x.start_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Due By
                            <span style="display: flex; font-weight: 700">
                              {{ x.end_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <span class="shw-status" style="bottom: 45px">{{
                            x.status
                          }}</span>
                          <div class="row tmg">
                            <div class="col-sm-9 ta">
                              <p class="mb-0 tap">
                                Updated {{ x.updated_at | timeago }}
                              </p>
                            </div>
                            <div class="col-sm-3">
                              <app-owner
                                [editable]="false"
                                [ownerdata]="x.owners"
                                *ngIf="x?.owners?.length > 0"
                              ></app-owner>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="ownAssessments?.length === 0" class="text-center">
            No assessments created by you
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="Assessments you are audience for ({{
          audience.length ? audience.length : 0
        }})"
      >
        <div style="overflow-x: hidden">
          <div class="tab-holder mt-3">
            <div class="accordion" id="accordionPanel">
              <div
                *ngFor="let category of audience; let i = index"
                class="accordion-item"
              >
                <h2 class="accordion-header" id="heading{{ i }}">
                  <button
                    class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#collapse{{ i }}"
                    aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                    aria-controls="collapse{{ i }}"
                    style="font-size: 13px; font-weight: 700"
                  >
                    {{ category.category }}
                  </button>
                </h2>
                <div
                  id="collapse{{ i }}"
                  class="accordion-collapse collapse {{
                    i === 0 ? 'show' : ''
                  }}"
                  aria-labelledby="heading{{ i }}"
                  data-bs-parent="#accordionPanel"
                >
                  <div class="accordion-body p-2">
                    <div class="flex-container-1">
                      <div
                        *ngIf="
                          category.assessments &&
                          category.assessments?.length <= 0
                        "
                        class="trn-no"
                      >
                        You haven't created any assessments yet.
                      </div>
                      <app-loading
                        style="width: 100%; min-height: 200px"
                        *ngIf="!category.assessments"
                      ></app-loading>
                      <div
                        class="flex-item p-1"
                        *ngFor="let x of category.assessments"
                      >
                        <div
                          class="trn-x"
                          [ngStyle]="{ background: x.color }"
                          style="height: 200px"
                          (click)="openResult(x)"
                        >
                          <h6 [matTooltip]="x.name" class="mb-0 trn-nm">
                            {{ x.name }}
                          </h6>
                          <div class="mb-0 mt-2 trn-ph" *ngIf="x.created_by">
                            <div class="d-flex align-items-center">
                              <mat-icon class="small-icon mr-1"
                                >perm_identity</mat-icon
                              >
                              <span>Owner</span>
                            </div>
                            <span style="display: flex; font-weight: 700">
                              {{
                                x.created_by.first_name +
                                  " " +
                                  x.created_by.last_name
                              }}
                            </span>
                          </div>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Created On
                            <span style="display: flex; font-weight: 700">
                              {{ x.start_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <p class="mb-0 mt-2 trn-ph" *ngIf="x.end_date">
                            Due By
                            <span style="display: flex; font-weight: 700">
                              {{ x.end_date | date : "dd MMM, yyyy" }}
                            </span>
                          </p>
                          <span class="shw-status" style="bottom: 45px">{{
                            x.status
                          }}</span>
                          <div class="row tmg">
                            <div class="col-sm-9 ta">
                              <p class="mb-0 tap">
                                Updated {{ x.updated_at | timeago }}
                              </p>
                            </div>
                            <div class="col-sm-3">
                              <app-owner
                                [editable]="false"
                                [ownerdata]="x.owners"
                                *ngIf="x?.owners?.length > 0"
                              ></app-owner>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="audience?.length === 0" class="text-center">
            No records found
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
