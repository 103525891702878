<div class="container-fluid  container-xxl proposal">
  <div class="custom-height" id="custom-height">
    <div class="row">
      <div class="col-md-12">
        <div class="screen-style">
          <div class="row mb-4">
            <div class="col-md-7">
              <div class="pt-4 mb-4">
                <!-- <small class="text-muted">Proposal List</small> -->
                <h2 class="screen-name mt-1">{{staticText?.menu?.subTitleMenu1}}</h2>
              </div>
            </div>
            <!--  <div class="col-md-5 pr-1">
              <div class="d-flex justify-content-end pt-5">
                <button mat-button class="float-end border primary lightSecondary download-btn  ml-3" mat-flat-button>
                  <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer fs-5">save_alt
                  </mat-icon>
                  Download List
                </button>
              
              </div>
            </div>
          </div> -->

            <!--   <mat-form-field appearance="outline" class="w-100 m-1 search" id="searchInput">
                  <input type="text" matInput class="w-100 p-0 " placeholder="Search by Opportunity Name..."
                    name="searchText" [(ngModel)]="searchText" (input)="onInputChange($event)">
                  <mat-icon matSuffix class="muted iconPos cursor-pointer" (click)="searchName()">search</mat-icon>
                </mat-form-field> -->

            <div class="col-md-12">
              <div class="container-fluid p-0">
                <app-tabmenu [tabcontentdynamic]="portList" [screen_name]="scree_name" 
                  (portfolio)="selectedPortfolioValues($event)"
                  (subportfolioId)="selectedSubportfolioValues($event)" [subportfolio]="subPortfilteredOptions"
                  (clearFilterData)="clearFilter()"></app-tabmenu>
              </div>
            </div>
            <div class="col-md-12">
              <div class="container-fluid p-0">
                <div class="d-flex justify-content-between align-items-center pt-4">
                  <div>
                    <p class="count">{{staticText?.menu?.subMenuText2}} ({{totalCount}})</p>
                  </div>
                  <button mat-button class="float-end border primary lightSecondary download-btn ml-3" *ngIf="proposalLists && proposalLists?.length>0"
                  data-toggle="tooltip" data-placement="top" title="Export as CSV" (click)="getDataSource(true)" mat-flat-button>Download</button>
                </div>
              </div>
            </div>
            <div>
              <app-table-card [proposalLists]="proposalLists" [screen_name]="scree_name"
                (refereshParentData)="callRefereshParentData($event)"></app-table-card>

              <div class="d-flex">
                <div class="col-md-10" *ngIf="!disableShowMore && totalCount > 10" class="show-more-wrapper">
                  <a class="show-more fw-normal" (click)="showAllProposals()">{{staticText?.common?.show_more}}<i
                      class="fas fa-angle-double-down fw-normal m-1"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>