import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { cloneDeep } from "lodash";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-assessment-attend",
  templateUrl: "./assessment-attend.component.html",
  styleUrls: ["./assessment-attend.component.scss"],
})
export class AssessmentAttendComponent implements OnInit {
  user: any;
  assessment: any;
  assessmentId: any;
  questionForm: FormGroup | any;
  current: boolean = false;
  answerOptions: any;
  answers: any;
  assessmentInfo: any;
  audienceInfo: any;

  constructor(
    private router: Router,
    private assessmentCentralService: AssessmentCentralService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      group: this.fb.array([]),
    });

    this.activatedRoute.params.subscribe((params) => {
      this.assessmentId = atob(params.id);
      this.getAssessmentById();
    });
    this.getAnswerOptions();
  }

  getAnswerOptions() {
    this.assessmentCentralService.getAnswerOptions().subscribe((response) => {
      this.answerOptions = response;
    });
  }

  getQuestion(q: any) {
    return this.assessment.questions.filter((e: any) => e.id === q)[0];
  }

  getAnswers() {
    this.answers = [];
    this.assessment.responses.forEach((element: any) => {
      let qobj = this.getQuestion(element.question_id);
      element.group = qobj.group_name;
      element.question = qobj.question;
      element.context = qobj.context;
      let i = this.answers.findIndex((e: any) => e.group == element.group);
      if (i > -1) {
        this.answers[i].questions.push(element);
      } else {
        this.answers.push({
          group: element.group,
          questions: [element],
        });
      }
    });
  }

  initializeAssessmentInfo() {
    this.assessmentInfo = "";
    if (this.assessment.portfolio?.parent_portfolio?.display_name) {
      this.assessmentInfo +=
        this.assessment.portfolio.parent_portfolio.display_name + " | ";
    }
    if (this.assessment.portfolio?.display_name) {
      this.assessmentInfo += this.assessment.portfolio.display_name + " | ";
    }
    if (this.assessment.program?.name) {
      this.assessmentInfo += this.assessment.program.name + " | ";
    }
    if (this.assessment.project?.project_name) {
      this.assessmentInfo += this.assessment.project.project_name + " | ";
    }
    if (this.assessment.vendor?.name) {
      this.assessmentInfo += this.assessment.vendor.name;
    }
    this.assessmentInfo = this.assessmentInfo.trimEnd();
    if (this.assessmentInfo.endsWith("|")) {
      this.assessmentInfo = this.assessmentInfo.slice(0, -1);
    }
  }

  initializeAudienceInfo() {
    this.audienceInfo = "";
    this.assessment.audiance.forEach((item: any) => {
      this.audienceInfo += item.first_name + " " + item.last_name + ", ";
    });
    this.audienceInfo = this.audienceInfo.trimEnd();
    if (this.audienceInfo.endsWith(",")) {
      this.audienceInfo = this.audienceInfo.slice(0, -1);
    }
  }

  getAssessmentById() {
    this.assessmentCentralService
      .getAssessmentById(this.assessmentId)
      .subscribe((res: any) => {
        this.assessment = res;
        this.initializeAssessmentInfo();
        this.initializeAudienceInfo();
        res.questions.forEach((element: any) => {
          let i = this.questionForm.value.group.findIndex(
            (e: any) => e.group == element.group_name
          );
          if (i > -1) {
            let fq: any;
            fq = this.fb.group({
              id: new FormControl(element.id),
              question: new FormControl(element.question, [
                Validators.required,
              ]),
              answer: new FormControl("", [Validators.required]),
              comments: new FormControl(""),
              context: new FormControl(element.context),
              file_name: new FormControl(""),
            });

            this.questionForm
              .get("group")
              .controls[i].get("questions")
              .push(fq);
            // this.questionForm[i].questions.push(element)
          } else {
            let fq: any;
            fq = this.fb.group({
              id: new FormControl(element.id),
              question: new FormControl(element.question, [
                Validators.required,
              ]),
              answer: new FormControl("", [Validators.required]),
              comments: new FormControl(""),
              context: new FormControl(element.context),
              file_name: new FormControl(""),
            });
            this.groupControls.push(
              this.fb.group({
                group: new FormControl(element.group_name, [
                  Validators.required,
                ]),
                questions: this.fb.array([fq]),
              })
            );
          }
        });
        if (this.assessment.status === "submitted") {
          this.getAnswers();
        }
      });
  }

  get groupControls() {
    return this.questionForm.get("group") as FormArray;
  }

  get questionControls() {
    return this.questionForm.get("group").get("questions") as FormArray;
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  navigate() {
    this.router.navigateByUrl(
      "strategicRelevance/assessment-central/assessment-templates"
    );
  }

  onFileSelected(e: any, i: any, j: any) {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error("Please upload less than 5MB");
        return;
      }

      this.questionForm
        .get("group")
        .controls[i].get("questions")
        .controls[j].get("file_name")
        .setValue(file);
      // this.awardDealForm.get('file').setValue(file);
      // this.templateFile = inputElement.files[0].name;
      // this.templateSize= inputElement.files[0].size / 1000000;
      // const ext = inputElement.files[0].name.substring(inputElement.files[0].name.lastIndexOf('.') + 1)
      // this.templatedoc = ext?.toLowerCase()
    }
  }
  // emptyFile() {
  //   this.templatedoc = '';
  //   this.templateSize = '';
  //   this.templateFile = '';
  //   this.awardDealForm.get('file').setValue('');
  // }

  removeFile(i: any, j: any) {
    this.questionForm
      .get("group")
      .controls[i].get("questions")
      .controls[j].get("file_name")
      .setValue("");
  }

  submit() {
    let payload: any = {
      instance: Number(this.assessmentId),
      responses: [],
    };

    let data = cloneDeep(this.questionForm.value);

    data.group.forEach((element: any) => {
      element.questions.forEach((q: any) => {
        let p = { ...q };
        p.question = q.id;
        p.file_name = q.file_name.name;
        //p.file_name = q.file_name.name
        payload.responses.push(p);
      });
    });

    this.assessmentCentralService
      .submitAnswer(payload)
      .subscribe((res: any) => {
        if (res) {
          this.upload_evidences(data);
        }
        this.toastrService.success("Submitted successfully...");
        this.assessment.status = "submitted";
        this.current = true;
      });
  }

  upload_evidences(data: any) {
    this.spinner.show();
    data.group.forEach((element: any) => {
      element.questions.forEach((q: any) => {
        if (q.file_name != "") {
          const formData = new FormData();
          formData.append("file", q.file_name);
          formData.append("instance_id", this.assessmentId);
          formData.append("question_id", q.id);
          this.assessmentCentralService.uploadEvidence(formData).subscribe(
            (res: any) => {},
            (error: any) => {
              this.spinner.hide();
              if (error.error.errors.project_id[0]) {
                this.toastrService.error(error.error.errors.project_id[0]);
              }
              throw error;
            }
          );
        }

        // q.question = q.id
        // payload.responses.push(q)
      });
    });
    this.spinner.hide();
  }

  getCount(e: any) {
    let count = 0;
    e.controls.questions.controls.forEach((element: any) => {
      if (element.status == "VALID") {
        count++;
      }
    });

    return "(" + count + "/" + e.controls.questions.controls.length + ")";
  }
}
