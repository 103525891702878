<div class="single-center-pane">
  <div *ngIf="assessment">
    <div class="row mb-4">
      <div class="col-sm-12" style="display: flex; align-items: center">
        <h5 class="mb-2" style="font-weight: 700">Complete the assessment</h5>
      </div>
      <div class="col-sm-12 trn-trc">
        <h6 class="m-0" style="font-weight: 500">{{ assessment.name }}</h6>
        <p style="font-size: 12px" class="m-0">
          Due Date: <b>{{ assessment.end_date | date : "dd MMM, yyyy" }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Status: <b>{{ assessment.status }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Owner:
          <b>{{
            assessment.created_by.first_name +
              " " +
              assessment.created_by.last_name
          }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Audience:
          <b>{{ audienceInfo }}</b>
        </p>
        <p class="m-0 mb-1" style="font-size: 13px">{{ assessmentInfo }}</p>
      </div>
    </div>
    <div class="attend" *ngIf="assessment.status != 'submitted'">
      <div
        style="
          height: calc(100vh - 275px);
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 10px;
        "
      >
        <form [formGroup]="questionForm">
          <mat-stepper
            orientation="vertical"
            formArrayName="group"
            [linear]="isLinear"
            #stepper
          >
            <mat-step
              [stepControl]="firstFormGroup + i"
              *ngFor="
                let q of questionForm.controls.group.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <ng-template matStepLabel
                >{{ q.value.group }}
                <span class="showcount">{{
                  getCount(questionForm.controls.group.controls[i])
                }}</span></ng-template
              >
              <div formArrayName="questions">
                <div
                  class="row py-1"
                  *ngFor="
                    let data of questionForm.controls.group.controls[i]
                      .controls['questions'].controls;
                    let j = index
                  "
                  [formGroupName]="j"
                  style="border-bottom: 1px solid #e8dede"
                >
                  <div
                    class="col-sm-5"
                    style="display: flex; align-items: center"
                  >
                    <div style="width: 50px; margin-right: 10px">
                      <span class="number" style="font-size: 13px">{{
                        j + 1
                      }}</span>
                    </div>

                    <p class="m-0" style="font-size: 13px">
                      {{ data.value.question
                      }}<span *ngIf="data.value.context"
                        ><br />Measure of Success:
                        {{ data.value.context }}</span
                      >
                    </p>
                  </div>
                  <div
                    class="col-sm-6"
                    style="display: flex; align-items: center"
                  >
                    <div style="width: 35%; margin-right: 10px">
                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Select Answer</mat-label>
                        <mat-select formControlName="answer">
                          <mat-option
                            *ngFor="let opt of answerOptions"
                            value="{{ opt.display_name }}"
                            >{{ opt.display_name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <mat-form-field
                      class="title"
                      appearance="outline"
                      style="width: 65%"
                    >
                      <mat-label>Comments</mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="comments"
                        placeholder="Enter comments..."
                      />
                    </mat-form-field>
                  </div>
                  <div
                    class="col-sm-1"
                    style="display: flex; align-items: center"
                  >
                    <mat-icon
                      (click)="fileInput.click()"
                      *ngIf="
                        questionForm.get('group').controls[i].get('questions')
                          .controls[j].value.file_name === ''
                      "
                      >cloud_upload</mat-icon
                    >
                    <mat-icon
                      style="color: red"
                      matTooltip="{{
                        questionForm.get('group').controls[i].get('questions')
                          .controls[j].value.file_name.name
                      }}"
                      *ngIf="
                        questionForm.get('group').controls[i].get('questions')
                          .controls[j].value.file_name
                      "
                      (click)="removeFile(i, j)"
                      >delete_forever</mat-icon
                    >
                    <input
                      type="file"
                      (change)="onFileSelected($event, i, j)"
                      #fileInput
                      name="file_name"
                      hidden
                    />
                  </div>
                </div>
              </div>

              <div
                style="display: flex; justify-content: right"
                class="my-3"
                *ngIf="i < questionForm.value.group.length - 1"
              >
                <button
                  mat-flat-button
                  mat-button
                  color="primary"
                  class="bg-primary text-white"
                  matStepperNext
                >
                  Next
                </button>
              </div>
              <div
                style="display: flex; justify-content: right"
                class="my-3"
                *ngIf="i == questionForm.value.group.length - 1"
              >
                <button
                  mat-flat-button
                  mat-button
                  color="primary"
                  class="bg-primary text-white"
                  aria-label="add"
                  (click)="submit()"
                  [disabled]="questionForm.invalid"
                >
                  Submit
                </button>
              </div>
            </mat-step>
          </mat-stepper>
        </form>

        <!-- <form [formGroup]="questionForm">
                   







                    <div formArrayName="questions">
                        <div class="row py-1" *ngFor="let data of questionForm.controls.questions.controls; let i = index" [formGroupName]="i" style="border-bottom: 1px solid #e8dede">
                            <div class="col-sm-5" style="display: flex; align-items: center">
                                <div style="width: 50px;">
                                    <span class="number" style="font-size: 13px; ">{{i + 1}}</span> 
                                </div>
                                
                                <p class="m-0"  style="font-size: 13px;">{{data.value.question}}</p>
                            </div>
                            <div class="col-sm-4"  style="display: flex; align-items: center">
                                <div style="width: 100%">
                                    <mat-radio-group style="display: flex; justify-content: space-between; flex-flow: wrap;" formControlName="answer">
                                        <mat-radio-button value="YES" name="q{{i}}answer">Yes</mat-radio-button>
                                        <mat-radio-button value="NO" name="q{{i}}answer">No</mat-radio-button>
                                        <mat-radio-button value="PARTIAL" name="q{{i}}answer">Partial</mat-radio-button>
                                        <mat-radio-button value="N/A" name="q{{i}}answer">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-sm-3"  style="display: flex; align-items: center">
                                <mat-form-field class="title" appearance="outline" floatLabel="always" style="width: 100%">
                                    <mat-label>Comments</mat-label>
                                    <input type="text" matInput formControlName="comments"
                                        placeholder="Enter comments..." />
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: right" class="my-3">
                            <button mat-flat-button color="primary" aria-label="add" (click)="submit()" [disabled]="questionForm.invalid">
                                Submit
                            </button>
                        </div>
                    </div>
                </form> -->
      </div>
    </div>
    <div class="submitted" *ngIf="assessment.status == 'submitted' && current">
      <h6>Thank you for submitting the assessment</h6>
    </div>
    <div *ngIf="assessment.status == 'submitted' && !current">
      <mat-accordion [multi]="true">
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          [expanded]="true"
          *ngFor="let group of answers"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ group.group }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngFor="let data of group.questions; let i = index"
            class="q mb-2 m-2"
          >
            <span>{{ i + 1 }}</span>
            <h6 class="m-0" style="width: 30%">
              <b>{{ data.question }}</b>
              <p class="mb-0" *ngIf="data.context">
                Measure of Success: {{ data.context }}
              </p>
            </h6>
            <h6 class="m-0" style="width: 10%; text-align: center">
              {{ data.answer }}
            </h6>
            <p
              style="
                width: 55%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
              class="mb-0"
            >
              {{ data.comments }}
            </p>
            <p
              class="m-0"
              style="width: 5%; display: flex; align-items: center"
            >
              <mat-icon *ngIf="data.file_name">cloud_download</mat-icon>
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
