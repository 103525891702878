import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { makeStateKey } from "@angular/platform-browser";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GovernanceLighthouseService {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let id = route.params["id"];
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    return this.http.get(`gov-lighthouse/governance_lighthouse_instance/${id}`);
  }

  getAllAssessments() {
    return this.http.get(`gov-lighthouse/governance_lighthouse_instance/`);
  }

  getTemplates() {
    return this.http.get(`gov-lighthouse/governance_lighthouse_template/`);
  }

  downloadTemplate() {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_template/assessment_file_download/7/`,
      { responseType: "blob" }
    );
  }

  uploadFile(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_template/`,
      payload
    );
  }

  createAssessment(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_instance/`,
      payload
    );
  }

  deleteTemplate(id: any) {
    return this.http.delete(
      `gov-lighthouse/governance_lighthouse_template/` + id
    );
  }

  getAssessmentById(id: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/${id}/`
    );
  }

  submitAnswer(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_response/`,
      payload
    );
  }

  getUsers() {
    return this.http.get(`users/user-list/`);
  }

  uploadEvidence(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_response/upload_evidence/`,
      payload
    );
  }

  deleteAssessment(id: any) {
    return this.http.delete(
      `gov-lighthouse/governance_lighthouse_instance/` + id
    );
  }

  getMasterForm() {
    return this.http.get(`dynamic-form/?form_name=new_opportunity`);
  }

  getProgram() {
    return this.http.get(`delivery/get-programs-list/`);
  }

  getProject() {
    return this.http.get(`delivery/get-complete-project-list/`);
  }

  getVendor() {
    return this.http.get(
      `dynamic-form/?form_name=opportunity_empanelled_vendors`
    );
  }

  fileDownload(instance_id: any, question_id: any, user_id: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_response/download_evidence/?instance_id=${instance_id}&question_id=${question_id}&user_id=${user_id}`,
      { responseType: "blob" }
    );
  }

  getAssessmentTemplateCategory() {
    return this.http.get("base_value/?type=gl_category");
  }

  getFilteredAssessmentTemplates(
    category: any,
    name: any,
    applicability: any,
    tags: any,
    owner: any
  ) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_template/?search=category:${category},name:${name},applicability:${applicability},tags:${tags},created_by:${owner}`
    );
  }

  getFilteredAssessmentCentral(
    category: any,
    name: any,
    due_date: any,
    status: any,
    portfolio: any,
    subportfolio: any,
    program: any,
    project: any,
    vendor: any,
    owner: any
  ) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/?search=category:${category},name:${name},end_date:${due_date},status:${status},portfolio:${portfolio},subportfolio:${subportfolio},program:${program},project:${project},vendor:${vendor},created_by:${owner}`
    );
  }

  getAggregateResponseDetails(assessmentId: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/response-details/${assessmentId}/`
    );
  }

  getAnswerOptions() {
    return this.http.get("base_value/?type=gl_response_option");
  }
}
