import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";

@Component({
  selector: "governance-assessment-create-modal",
  templateUrl: "./governance-assessment-create-modal.html",
  styleUrls: ["./governance-assessment-create-modal.scss"],
  providers: [DatePipe],
})
export class GovernanceAssessmentCreateModalDialogComponent implements OnInit {
  assessmentForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    owner: new FormArray([]),
    start_date: new FormControl(""),
    end_date: new FormControl(""),
    portfolio: new FormControl(""),
    subportfolio: new FormControl(""),
    program: new FormControl(""),
    project: new FormControl(""),
    vendor: new FormControl(""),
  });

  selectedOwners: any = [];
  selectedAudience: any = [];
  filterOptionProgram: Observable<any[]> | any;

  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionProject: Observable<any[]> | any;
  filterOptionVendor: Observable<any[]> | any;

  masterInformation: any;

  filteredOptions1: any;
  filteredOptions2: any;
  filteredOptions3: any;

  options1: any;
  options2: any;
  options3: any;

  subPortOptions: any;
  subPortfilteredOptions: any;

  programOption: any;
  projectOption: any;
  vendorOption: any;

  constructor(
    public dialogRef: MatDialogRef<GovernanceAssessmentCreateModalDialogComponent>,
    private governanceLighthouseService: GovernanceLighthouseService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getMaster();
    this.getProgram();
    this.getProject();
    this.getVendor();
  }

  getMaster() {
    this.governanceLighthouseService.getMasterForm().subscribe((res: any) => {
      this.masterInformation = res?.records;

      this.masterInformation?.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.data.portfolio = i.field_status;
          this.options1 = i.field_values;
          this.filteredOptions1 = this.options1;
          this.data.disPortfolio = i.display_name;
          if (!i.field_status) {
            this.assessmentForm.removeControl("portfolio");
          }
        }
        if (i.field_name == "opportunity_sub_portfolio") {
          this.data.subPortfolio = i.field_status;
          this.subPortOptions = i.field_values;
          this.subPortfilteredOptions = this.subPortOptions;
          this.data.disSubPortfolio = i.display_name;
          if (!i.field_status) {
            this.assessmentForm.removeControl("subportfolio");
          }
        }
      });

      this.initAutoComplete();
    });
  }

  getProgram() {
    this.governanceLighthouseService.getProgram().subscribe((res: any) => {
      this.programOption = res;
      this.filterOptionProgram = this.assessmentForm
        .get("program")!
        .valueChanges.pipe(
          startWith(""),
          map((value: any) =>
            typeof value === "string" ? value : value?.name
          ),
          map((name: any) =>
            name ? this.programFilter(name) : this.programOption.slice()
          )
        );
    });
  }

  getProject() {
    this.governanceLighthouseService.getProject().subscribe((res: any) => {
      this.projectOption = res;

      this.filterOptionProject = this.assessmentForm
        .get("project")!
        .valueChanges.pipe(
          startWith(""),
          map((value: any) =>
            typeof value === "string" ? value : value?.name
          ),
          map((name: any) =>
            name ? this.projectFilter(name) : this.projectOption.slice()
          )
        );
    });
  }

  getVendor() {
    this.governanceLighthouseService.getVendor().subscribe((res: any) => {
      this.vendorOption = res.records[0].field_values;
      this.filterOptionVendor = this.assessmentForm
        .get("vendor")!
        .valueChanges.pipe(
          startWith(""),
          map((value: any) =>
            typeof value === "string" ? value : value?.name
          ),
          map((name: any) =>
            name ? this.vendorFilter(name) : this.vendorOption.slice()
          )
        );
    });
  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.assessmentForm.patchValue({
      subportfolio: "",
    });

    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.assessmentForm
      .get("subportfolio")!
      .valueChanges.pipe(
        startWith(""),
        map((value: any) => (typeof value === "string" ? value : value?.name)),
        map((name: any) =>
          name
            ? this.subPortfolioFilter(name)
            : this.subPortfilteredOptions.slice()
        )
      );
  }

  createAssessment() {
    let postdata = this.assessmentForm.value;
    postdata.start_date = this.datePipe.transform(
      postdata.start_date,
      "YYYY-MM-dd"
    );
    postdata.end_date = this.datePipe.transform(
      postdata.end_date,
      "YYYY-MM-dd"
    );
    postdata.user = this.selectedOwners;
    postdata.audiance = this.selectedAudience;
    postdata.assessmenttemplates_id = this.data.template_id;
    postdata.portfolio_id = postdata.portfolio.id;
    postdata.subportfolio_id = postdata.subportfolio.id;
    postdata.program_id = postdata.program.id;
    postdata.project_id = postdata.project.id;
    postdata.vendor_id = postdata.vendor.id;
    this.governanceLighthouseService
      .createAssessment(postdata)
      .subscribe((res: any) => {
        this.dialogRef.close();
        this.toastrService.success("Assessment created successfully...");
        this.router.navigateByUrl(
          "strategicRelevance/governancect/assessment-result/" +
            btoa(res.id + "")
        );
      });
  }

  validateData(type: any) {}

  initAutoComplete(): any {
    this.filterOptionPortfolio = this.assessmentForm
      .get("portfolio")!
      .valueChanges.pipe(
        startWith(""),
        map((value: any) => (typeof value === "string" ? value : value?.name)),
        map((name: any) =>
          name ? this.portfolioFilter(name) : this.filteredOptions1.slice()
        )
      );
  }

  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programOption.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }
  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }
  private vendorFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.vendorOption.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortOptions.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }

  private projectFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.projectOption.filter((i: any) =>
      i.project_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(data: any) {
    return data && data.name ? data.name : "";
  }

  displayFnProject(data: any) {
    return data && data.project_name ? data.project_name : "";
  }

  userdata(e: any) {
    this.selectedOwners = e;
  }

  audiencedata(e: any) {
    this.selectedAudience = e;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
