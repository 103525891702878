<app-modal-header-common [title]="'Activity Detail'"> </app-modal-header-common>
<div class="p-3" *ngIf="data">
  <div class="row mb-3">
    <div class="col-sm-3 label">Activity Name</div>
    <div class="col-sm-9 value">
      <app-label-input
        [inputString]="data.item.name"
        [placeholder]="'Enter activity name'"
        (refreshData)="updateData($event, 'activity', 'name', data.item)"
      ></app-label-input>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">Owner</div>
    <div class="col-sm-9 value">
      <app-owner
        (refereshParentData)="updateData($event, 'activity', 'owner_ids', data.item)"
        [ownerdata]="data.item.owners"
      ></app-owner>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">Status</div>
    <div class="col-sm-9 value">
      <select
        [ngStyle]="{'background-color': data.item.status == 'Yet to Start' ? 'yellow' : data.item.status == 'Work in Progress' ? 'orange' : data.item.status == 'Done' ? '#23ff23' : ''}"
        style="
          font-size: 10px;
          padding: 3px;
          border-radius: 15px;
          text-align: center;
        "
        [(ngModel)]="data.item.status"
        (ngModelChange)="updateData(data.item.status, 'activity', 'status', data.item)"
      >
        <option value="">Select</option>
        <option value="Yet to Start">Yet to Start</option>
        <option value="Work in Progress">Work in Progress</option>
        <option value="Done">Done</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">Workgroup</div>
    <div class="col-sm-9 value">
      <app-workgroup
        [showCount]="1"
        [showLabel]="false"
        (refereshParentData)="updateData($event, 'activity', 'workgroup', data.item)"
        [workgroupdata]="data.item.workgroup"
      ></app-workgroup>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">Start Date</div>
    <div class="col-sm-9 value">
      <span style="font-size: 10px; position: relative">
        <input
          matInput
          [matDatepicker]="i"
          [attr.id]="dtPicker + i"
          [(ngModel)]="data.item.start_date"
          (ngModelChange)="updateData(data.item.start_date, 'activity', 'start_date', data.item)"
          style="
            width: 100%;
            border: none;
            outline: none;
            cursor: pointer;
            padding-left: 5px;
          "
          (click)="i.open()"
        />
        <mat-datepicker #i></mat-datepicker>
        <button
          class="small-icon-button"
          mat-button
          (click)="i.open()"
          *ngIf="!data.item.start_date"
          style="
            position: absolute;
            left: 5px;
            top: -10px;
            font-size: 9px;
            padding: inherit;
          "
        >
          DD/MM/YYYY
        </button>
      </span>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">End Date</div>
    <div class="col-sm-9 value">
      <span style="font-size: 10px; position: relative">
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="data.item.end_date"
          (ngModelChange)="updateData(data.item.end_date, 'activity', 'end_date', data.item)"
          style="
            width: 100%;
            border: none;
            outline: none;
            cursor: pointer;
            padding-left: 5px;
          "
          (click)="picker.open()"
        />
        <mat-datepicker #picker></mat-datepicker>
        <button
          class="small-icon-button"
          mat-button
          (click)="picker.open()"
          *ngIf="!data.item.end_date"
          style="
            position: absolute;
            left: 5px;
            top: -10px;
            font-size: 9px;
            padding: inherit;
          "
        >
          DD/MM/YYYY
        </button>
      </span>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-3 label">Comments</div>
    <div class="col-sm-9 value">
      <app-label-input
        style="padding-left: 5px"
        [inputString]="data.item.comments"
        [placeholder]="'Enter Comments'"
        (refreshData)="updateData($event, 'activity', 'comments', data.item)"
      ></app-label-input>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3 label">File upload</div>
    <div class="col-sm-9 value">
      <div class="d-flex align-items-center gap-2">
        <input
          type="file"
          (change)="this.onFileChange($event, data.item)"
          #fileInput
          hidden
        />
        <mat-icon (click)="fileInput.click()">cloud_upload</mat-icon>
        <span *ngIf="fileName">{{ fileName }}</span>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: end" class="mt-3">
    <button
      mat-raised-button
      color="primary"
      class="templ-btn"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
