<div class="px-0 pb-2">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div
        class="d-flex mb-2"
        style="align-items: center; justify-content: space-between"
      >
        <h5>Contract Central</h5>
        <div class="justify-content-end">
          <button
            mat-button
            class="bg-primary text-white"
            mat-button
            mat-flat-button
            (click)="onClickNewSOWMaster(newSOwMasterPopUp)"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5"
              >add
            </mat-icon>
            New Contract
          </button>
        </div>
      </div>
      <div class="filter-container mb-2 mt-3">
        <div class="filter-icon">
          <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
        </div>
        <input
          type="text"
          placeholder="Search Name..."
          class="form-control text-input"
          [(ngModel)]="filterName"
          (ngModelChange)="search()"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <input
          type="text"
          placeholder="Search Invoice Type..."
          class="form-control text-input"
          [(ngModel)]="filterInvoice"
          (ngModelChange)="search()"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterOu"
            (ngModelChange)="search()"
            placeholder="Search OU/GDP..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let vendor of vendorList"
              value="{{ vendor.id }}"
              >{{ vendor.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterType"
            (ngModelChange)="search()"
            placeholder="Search Type..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let type of typesList"
              value="{{ type.name }}"
              >{{ type.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterStatus"
            (ngModelChange)="search()"
            placeholder="Search Status..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let status of statusList"
              value="{{ status.id }}"
              >{{ status.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterExecutionType"
            (ngModelChange)="search()"
            placeholder="Search Execution Type..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let execType of executionTypesList"
              value="{{ execType.id }}"
              >{{ execType.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterOwner"
            (ngModelChange)="search()"
            placeholder="Search Owner..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let owner of ownersList"
              value="{{ owner.id }}"
              >{{ owner.first_name + " " + owner.last_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterContractParty"
            (ngModelChange)="search()"
            placeholder="Search Contract Party..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let portfolio of portfolioList"
              value="{{ portfolio.id }}"
              >{{ portfolio.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="custom-height">
            <div
              class="custom-table table-responsive"
              style="max-height: calc(100vh - 220px)"
            >
              <table class="table table-bordered">
                <thead style="position: sticky; z-index: 1">
                  <tr>
                    <th
                      *ngFor="let field of headerList"
                      scope="col"
                      class="text-center"
                    >
                      {{ field }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let cont of sowMasterList?.records; let i = index"
                  >
                    <tr>
                      <td class="text-center">{{ startValue + i + 1 }}</td>
                      <td>{{ cont?.contract_name }}</td>
                      <td>{{ cont?.contract_type }}</td>
                      <td>{{ cont?.invoice_type }}</td>
                      <td>{{ cont?.contract_value }}</td>
                      <td>{{ cont?.enterprise_contract_id }}</td>
                      <td>
                        {{
                          cont?.owner?.first_name + " " + cont?.owner?.last_name
                        }}
                      </td>
                      <!-- <td>{{cont?.slt}}</td> -->
                      <td>{{ cont?.start_date }}</td>
                      <td>{{ cont?.end_date }}</td>
                      <td>{{ cont?.vendor?.name }}</td>
                      <td class="text-center pt-1 pb-1">
                        <div class="d-flex justify-content-between">
                          <mat-icon
                            aria-hidden="false"
                            class="cursor-pointer fs-2 mr-2"
                            (click)="editSowMaster(cont, newSOwMasterPopUp)"
                          >
                            edit_note
                          </mat-icon>
                          <img
                            (click)="deleteSowMaster(cont?.id, delete)"
                            class="cursor-pointer"
                            src="./assets/images/comment-delete.svg"
                            style="width: 16px"
                          />
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tr *ngIf="!sowMasterList?.total_count">
                  <td colspan="14" class="noRecords text-center mt-2">
                    <span class="mt-2"
                      >{{ staticText.common.no_records_found }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="sowMasterList?.total_count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getRecords()"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ sowMasterList?.total_count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="sowMasterList?.total_count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentPage"
                [boundaryLinks]="true"
                (pageChange)="getRecords()"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
      <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style="font-size: 13px">
                        Curated from Web
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-2">
                        <app-loading *ngIf="loading"></app-loading>
                        <app-gnews [display_header]="'false'"></app-gnews>
                    </div>
                </div>
            </div>    -->
    </div>
  </div>
</div>

<ng-template #newSOwMasterPopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="contentValue">
      </app-modal-header-common>
      <div
        class="modal-body p-0 p-3 sowMaster"
        style="overflow-y: auto; height: 50vh"
      >
        <form [formGroup]="sowMasterForm">
          <mat-form-field class="w-100 pr-1 pl-1" appearance="outline">
            <mat-label>Contract Name</mat-label>
            <input
              type="text"
              matInput
              status="basic"
              formControlName="contract_name"
            />
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Contract Type</mat-label>
            <mat-select formControlName="contract_type">
              <mat-option value="SOW">SOW</mat-option>
              <mat-option value="CR">CR</mat-option>
              <mat-option value="MSA">MSA</mat-option>
              <mat-option value="Amendment">Amendment</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="w-50 pr-1 pl-1"
            appearance="outline"
            *ngIf="
              sowMasterForm.value.contract_type == 'CR' ||
              sowMasterForm.value.contract_type == 'Amendment'
            "
          >
            <mat-label>Linked Contract</mat-label>
            <mat-select formControlName="linked_contract">
              <ng-container *ngFor="let contract of allContractList">
                <mat-option [value]="contract.id">{{
                  contract?.contract_name
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Invoice Type</mat-label>
            <mat-select formControlName="invoice_type">
              <mat-option value="Ru">Ru</mat-option>
              <mat-option value="Milestone">Milestone</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-icon matPrefix>attach_money</mat-icon>
            <mat-label>Contract Value</mat-label>
            <input
              type="number"
              matInput
              status="basic"
              formControlName="contract_value"
            />
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>Tenure</mat-label>
            <input
              type="number"
              matInput
              status="basic"
              formControlName="tenure"
            />
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              readonly
              [ngModelOptions]="{ standalone: true }"
              fullWidth
              status="basic"
              (click)="picker.open()"
              formControlName="start_date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker #picker> </mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              readonly
              [ngModelOptions]="{ standalone: true }"
              fullWidth
              status="basic"
              (click)="picker1.open()"
              formControlName="end_date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker1">
            </mat-datepicker-toggle>
            <mat-datepicker #picker1> </mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Owner</mat-label>
            <mat-select formControlName="owner_id">
              <ng-container *ngFor="let user of userList">
                <mat-option
                  *ngIf="user?.first_name || user?.last_name"
                  [value]="user.id"
                  >{{ user?.first_name }} {{ user?.last_name }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>SLT</mat-label>
            <mat-select formControlName="slt">
              <ng-container *ngFor="let user of userList">
                <mat-option
                  *ngIf="user?.first_name || user?.last_name"
                  [value]="getName(user)"
                  >{{ user?.first_name }} {{ user?.last_name }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Enterprise Contract Id</mat-label>
            <input
              type="number"
              matInput
              status="basic"
              formControlName="enterprise_contract_id"
            />
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>OU/GDP</mat-label>
            <mat-select formControlName="vendor_id">
              <mat-option
                *ngFor="let vendor of vendorList"
                [value]="vendor.id"
                style="font-size: 0.8rem; height: 30px"
              >
                {{ vendor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>Execution Type</mat-label>
            <mat-select formControlName="execution_type_id">
              <mat-option
                *ngFor="let execType of executionTypesList"
                [value]="execType.id"
                style="font-size: 0.8rem; height: 30px"
              >
                {{ execType.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status_id">
              <mat-option
                *ngFor="let status of statusList"
                [value]="status.id"
                style="font-size: 0.8rem; height: 30px"
              >
                {{ status.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pl-1 pr-1" appearance="outline">
            <mat-label>Contract Party</mat-label>
            <mat-select formControlName="contract_party_portfolio_id">
              <mat-option
                *ngFor="let portfolio of portfolioList"
                [value]="portfolio.id"
                style="font-size: 0.8rem; height: 30px"
              >
                {{ portfolio.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Sourcing Contact</mat-label>
            <input
              type="text"
              matInput
              status="basic"
              formControlName="sourcing_contract"
            />
          </mat-form-field>
          <mat-form-field class="w-50 pr-1 pl-1" appearance="outline">
            <mat-label>Legal Contact</mat-label>
            <input
              type="text"
              matInput
              status="basic"
              formControlName="legal_contract"
            />
          </mat-form-field>
          <mat-label class="pl-2 d-block">Upload Attachment</mat-label>
          <div class="pl-2" *ngIf="!templatedoc">
            <div class="row">
              <div class="col-lg-12">
                <div class="mt-1 upload">
                  <div
                    class="w-100 cursor-pointer d-flex justify-content-center"
                    (click)="fileInput.click()"
                  >
                    <span class="mt-2 ms-2 my-auto secondary">
                      {{ staticText?.common.upload_doc }}</span
                    >
                  </div>
                  <input
                    hidden
                    #fileInput
                    type="file"
                    id="file"
                    (change)="onFileSelected($event)"
                  />
                </div>
              </div>
              <p class="text-dark file-status fs-14 mb-0">
                {{ staticText?.common.doc_ext_maxsize }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="templatedoc">
            <div class="row">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="mr-2 mt-2">
                    <img
                      *ngIf="templatedoc == 'doc' || templatedoc == 'docx'"
                      src="./assets/images/word.svg"
                      height="40"
                    />
                    <img
                      *ngIf="templatedoc == 'pdf'"
                      src="./assets/images/pdf.svg"
                      height="40"
                    />
                    <img
                      *ngIf="templatedoc == 'xls' || templatedoc == 'xlsx'"
                      src="./assets/images/excel.svg"
                      height="40"
                    />
                    <img
                      *ngIf="templatedoc == 'csv'"
                      src="./assets/images/csv.png"
                      height="40"
                    />
                    <mat-icon
                      *ngIf="
                        templatedoc !== 'pdf' &&
                        templatedoc !== 'doc' &&
                        templatedoc !== 'docx' &&
                        templatedoc !== 'xls' &&
                        templatedoc !== 'xlsx' &&
                        templatedoc !== 'csv'
                      "
                      mat-list-icon
                      class="material-icons my-auto fs-1 text-danger"
                    >
                      description</mat-icon
                    >
                  </div>
                  <div class="m-2 ms-0">
                    {{ templateFile }}
                    <p class="text-muted doc-subhead mb-0">
                      {{ templateSize | number : "1.2-2" }}MB
                    </p>
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <img
                      (click)="deleteFile(i, delete)"
                      class="cursor-pointer px-3"
                      src="./assets/images/delete-icon-new.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="d-flex justify-content-between">
            <button
              (click)="cancel()"
              mat-stroked-button
              mat-button
              color="primary"
              class="mx-1"
            >
              Close
            </button>
            <button
              (click)="saveSOWMaster()"
              mat-flat-button
              mat-button
              class="bg-primary text-white mx-1"
              mat-flat-button
              color="primary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
