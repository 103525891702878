import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { TurnkeyCreateModalDialogComponent } from "../../turnkey-create-modal/turnkey-create-modal";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";

@Component({
  selector: "app-tunkey-create-template-modal",
  templateUrl: "./tunkey-create-template-modal.component.html",
  styleUrls: ["./tunkey-create-template-modal.component.scss"],
  providers: [DatePipe],
})
export class TunkeyCreateTemplateModalComponent implements OnInit {
  categories: any;
  assessmentTags: any;
  templateForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    category: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<TurnkeyCreateModalDialogComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService,
    private turnkeyTrackerService: TurnkeyTrackerService
  ) {}

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.turnkeyTrackerService
      .getPlanCategories()
      .subscribe((response: any) => {
        this.categories = response;
      });
  }

  getChildTags(data: any) {
    this.assessmentTags = data;
  }

  createPlanCentralTemplate() {
    let dialogData = this.templateForm.value;
    dialogData["selectedTags"] = this.assessmentTags;
    this.dialogRef.close(dialogData);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
