<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div class="d-flex justify-content-between">
        <div
          class="d-flex align-items-center mb-3"
          role="button"
          (click)="navigateToPlanCentral()"
        >
          <mat-icon>keyboard_backspace</mat-icon>
          <strong class="ml-2">Go back</strong>
        </div>
        <div>
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white"
            (click)="showInsightsFun()"
            *ngIf="!showInsights"
          >
            Insights
            <mat-icon>arrow_forward</mat-icon>
          </button>
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white"
            (click)="showInsightsFun()"
            *ngIf="showInsights"
          >
            <mat-icon>arrow_backward</mat-icon>

            Plan
          </button>
        </div>
      </div>
      <div *ngIf="!showInsights">
        <div *ngIf="plan">
          <div>
            <div class="row">
              <div class="col-sm-6">
                <h6 style="display: flex" class="m-0">
                  <app-label-input
                    [inputString]="plan.name"
                    [placeholder]="'Enter Plan name'"
                    (refreshData)="updateData($event, 'plan', 'name', plan)"
                  ></app-label-input>
                  <app-owner
                    (refereshParentData)="
                      updateData($event, 'plan', 'owner_ids', plan)
                    "
                    [ownerdata]="plan.owners"
                  ></app-owner>
                </h6>
              </div>
              <div class="col-sm-6" style="text-align: right">
                <button
                  mat-flat-button
                  mat-button
                  color="primary"
                  class="bg-primary text-white"
                  (click)="saveAsTemplate()"
                >
                  <mat-icon>assignment</mat-icon>
                  Save as template
                </button>

                <button mat-icon-button color="warn" (click)="deletePlan()">
                  <mat-icon style="font-size: 15px">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div>
            <p
              class="m-0 mb-2"
              style="
                display: flex;
                font-size: 12px;
                color: #7e7878;
                align-items: center;
              "
            >
              <span style="display: flex"
                >Start Date:
                <div style="width: 75px; position: relative">
                  <input
                    matInput
                    [matDatepicker]="mPick"
                    [attr.id]="'mPicker'"
                    [(ngModel)]="plan.start_date"
                    (ngModelChange)="
                      updateData(plan.start_date, 'plan', 'start_date', plan)
                    "
                    style="
                      text-align: center;
                      width: 75px;
                      font-weight: 700;
                      color: #7e7878;
                      border: none;
                      outline: none;
                      cursor: pointer;
                    "
                    (click)="mPick.open()"
                  />
                  <mat-datepicker #mPick></mat-datepicker>
                  <button
                    class="small-icon-button"
                    mat-button
                    (click)="mPick.open()"
                    *ngIf="!plan.start_date"
                    style="
                      position: absolute;
                      left: 38px;
                      top: -3px;
                      transform: translateX(-50%);
                      font-size: 10px;
                      padding: inherit;
                      font-weight: 600 !important;
                    "
                  >
                    DD/MM/YYYY
                  </button>
                </div>
              </span>
              <span style="display: flex; margin: 0 8px"
                >End Date:
                <div style="width: 75px; position: relative">
                  <input
                    matInput
                    [matDatepicker]="mPickEnd"
                    [attr.id]="'mPickerEnd'"
                    [(ngModel)]="plan.end_date"
                    (ngModelChange)="
                      updateData(plan.end_date, 'plan', 'end_date', plan)
                    "
                    style="
                      text-align: center;
                      width: 75px;
                      font-weight: 700;
                      color: #7e7878;
                      border: none;
                      outline: none;
                      cursor: pointer;
                    "
                    (click)="mPickEnd.open()"
                  />
                  <mat-datepicker #mPickEnd></mat-datepicker>
                  <button
                    class="small-icon-button"
                    mat-button
                    (click)="mPickEnd.open()"
                    *ngIf="!plan.end_date"
                    style="
                      position: absolute;
                      left: 38px;
                      top: -3px;
                      transform: translateX(-50%);
                      font-size: 10px;
                      padding: inherit;
                      font-weight: 600 !important;
                    "
                  >
                    DD/MM/YYYY
                  </button>
                </div>
              </span>
              <span
                ><app-workgroup
                  [showCount]="2"
                  [showLabel]="true"
                  (refereshParentData)="
                    updateData($event, 'plan', 'workgroup', plan)
                  "
                  [workgroupdata]="plan.workgroup"
                ></app-workgroup
              ></span>
            </p>
            <app-tag
              [dataObj]="getTagObject()"
              [tagsIds]="plan.tag_ids"
            ></app-tag>
          </div>
          <div
            style="
              margin-top: 15px;
              padding: 5px 20px;
              font-size: 10px;
              display: flex;
              text-align: center;
            "
            *ngIf="plan.phases.length > 0"
          >
            <div style="width: 4%"></div>
            <div style="width: 23%; text-align: left">Activity</div>
            <div style="width: 5%; text-align: left">Owner</div>
            <div style="width: 15%">Status</div>
            <div style="width: 10%; text-align: left">Workgroup</div>
            <div style="width: 10%">Start Date</div>
            <div style="width: 10%">End Date</div>
            <div style="width: 15%; text-align: left">Comments</div>
          </div>
          <div
            style="
              height: calc(100vh - 345px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              cdkDropList
              id="phasegroup"
              [cdkDropListData]="plan.phases"
              (cdkDropListDropped)="drop($event)"
            >
              <div *ngFor="let phase of plan.phases; let ix = index" cdkDrag>
                <div class="phase-container">
                  <div class="row">
                    <div class="col-sm-6">
                      <div style="margin-left: 5px">
                        <app-label-input
                          [inputString]="phase.name"
                          [placeholder]="'Enter Phase name'"
                          (refreshData)="
                            updateData($event, 'phase', 'name', phase, ix)
                          "
                        ></app-label-input>
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        padding-right: 17px;
                      "
                    >
                      <button
                        mat-mini-fab
                        color="primary"
                        class="my-fab"
                        (click)="addActivity(ix)"
                      >
                        <mat-icon>add</mat-icon>
                      </button>

                      <button
                        mat-icon-button
                        color="warn"
                        (click)="deletePhase(ix)"
                      >
                        <mat-icon style="font-size: 15px">delete</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div
                    *ngIf="phase.activities.length <= 0"
                    style="
                      background: white;
                      border-radius: 4px;
                      overflow: hidden;
                      display: block;
                      padding: 10px 0;
                      font-size: 10px;
                      text-align: center;
                    "
                  >
                    No Activity
                  </div>
                  <div
                    cdkDropList
                    id="dra{{ phase.id }}"
                    [cdkDropListData]="phase.activities"
                    [cdkDropListConnectedTo]="getConnectedTo(phase.id)"
                    class="item-list"
                    (cdkDropListDropped)="drop($event, phase.id)"
                  >
                    <div
                      class="item-box"
                      *ngFor="let item of phase.activities; let j = index"
                      cdkDrag
                    >
                      <div style="width: 100%; display: flex">
                        <div
                          style="width: 29%; font-size: 12px; font-weight: 500"
                        >
                          <app-label-input
                            [inputString]="item.name"
                            [placeholder]="'Enter activity name'"
                            (refreshData)="
                              updateData(
                                $event,
                                'activity',
                                'name',
                                item,
                                ix,
                                j
                              )
                            "
                          ></app-label-input>
                        </div>
                        <div
                          style="width: 7%; align-items: center; gap: 10px"
                          class="d-flex"
                        >
                          <app-owner
                            (refereshParentData)="
                              updateData(
                                $event,
                                'activity',
                                'owner_ids',
                                item,
                                ix,
                                j
                              )
                            "
                            [ownerdata]="item.owners"
                          ></app-owner>
                        </div>
                        <div style="width: 15%; text-align: center">
                          <select
                            [ngStyle]="{
                              'background-color':
                                item.status == 'Yet to Start'
                                  ? 'yellow'
                                  : item.status == 'Work in Progress'
                                  ? 'orange'
                                  : item.status == 'Done'
                                  ? '#23ff23'
                                  : ''
                            }"
                            [(ngModel)]="item.status"
                            style="
                              font-size: 10px;
                              padding: 3px;
                              border-radius: 15px;
                              text-align: center;
                            "
                            (ngModelChange)="
                              updateData(
                                item.status,
                                'activity',
                                'status',
                                item,
                                ix,
                                j
                              )
                            "
                          >
                            <option value="">Select</option>
                            <option value="Yet to Start">Yet to Start</option>
                            <option value="Work in Progress">
                              Work in Progress
                            </option>
                            <option value="Done">Done</option>
                          </select>
                        </div>
                        <div style="width: 10%; text-align: center">
                          <span style="font-size: 10px">
                            <app-workgroup
                              [showCount]="1"
                              [showLabel]="false"
                              (refereshParentData)="
                                updateData(
                                  $event,
                                  'activity',
                                  'workgroup',
                                  item,
                                  ix,
                                  j
                                )
                              "
                              [workgroupdata]="item.workgroup"
                            ></app-workgroup>
                          </span>
                        </div>
                        <div style="width: 10%; text-align: center">
                          <span style="font-size: 10px; position: relative">
                            <input
                              matInput
                              [matDatepicker]="i"
                              [attr.id]="dtPicker + i"
                              [(ngModel)]="item.start_date"
                              (ngModelChange)="
                                updateData(
                                  item.start_date,
                                  'activity',
                                  'start_date',
                                  item,
                                  ix,
                                  j
                                )
                              "
                              style="
                                width: 100%;
                                text-align: center;
                                border: none;
                                outline: none;
                                cursor: pointer;
                              "
                              (click)="i.open()"
                            />
                            <mat-datepicker #i></mat-datepicker>
                            <button
                              class="small-icon-button"
                              mat-button
                              (click)="i.open()"
                              *ngIf="!item.start_date"
                              style="
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                font-size: 9px;
                                padding: inherit;
                              "
                            >
                              DD/MM/YYYY
                            </button>
                          </span>
                        </div>
                        <div style="width: 10%; text-align: center">
                          <span style="font-size: 10px; position: relative">
                            <input
                              matInput
                              [matDatepicker]="picker"
                              [(ngModel)]="item.end_date"
                              (ngModelChange)="
                                updateData(
                                  item.end_date,
                                  'activity',
                                  'end_date',
                                  item,
                                  ix,
                                  j
                                )
                              "
                              style="
                                width: 100%;
                                text-align: center;
                                border: none;
                                outline: none;
                                cursor: pointer;
                              "
                              (click)="picker.open()"
                            />
                            <mat-datepicker #picker></mat-datepicker>
                            <button
                              class="small-icon-button"
                              mat-button
                              (click)="picker.open()"
                              *ngIf="!item.end_date"
                              style="
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                font-size: 9px;
                                padding: inherit;
                              "
                            >
                              DD/MM/YYYY
                            </button>
                          </span>
                        </div>
                        <div
                          style="width: 15%; display: flex; align-items: center"
                        >
                          <span
                            style="
                              font-size: 11px;
                              font-weight: 300;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              width: 100%;
                            "
                            (click)="viewMore(item, ix, j)"
                          >
                            {{
                              item.comments ? item.comments : "Enter Comments"
                            }}
                          </span>
                        </div>
                        <div style="width: 10%" class="d-flex">
                          <button
                            class="small-icon-button"
                            color="warn"
                            mat-icon-button
                            (click)="deleteActivity(item, ix, j)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                          <button
                            class="small-icon-button"
                            (click)="sendNotification(item.id)"
                            matTooltip="nudge"
                            mat-icon-button
                          >
                            <img
                              class="mb-1"
                              src="assets/Icons/pokeIcon.svg"
                              alt="poke"
                              height="16px"
                              width="16px"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          (click)="addPhase()"
          style="
            font-size: 12px;
            text-align: center;
            padding: 15px;
            background: #dee3e8;
            cursor: pointer;
            border-radius: 15px;
          "
        >
          Add Phase
        </div>
      </div>
      <div *ngIf="showInsights">
        <app-power-bi-module page="plan_central"></app-power-bi-module>
      </div>
    </div>
  </div>
  <div class="col-sm-3 p-0">
    <div class="single-right-pane">
      <div>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading1">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#insights-collapse"
                aria-expanded="true"
                aria-controls="insights-collapse"
                style="font-size: 13px"
              >
                Insights
              </button>
            </h2>
            <div
              id="insights-collapse"
              class="accordion-collapse collapse show"
              aria-labelledby="heading1"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-2">
                <div class="activities-due">
                  <h6>Activities Due -</h6>
                  <p>
                    Activities due in 15 days:
                    {{ planStats?.activities_due?.due_in_15_days?.length }}
                  </p>
                  <p>
                    Activities due in 30 days:
                    {{ planStats?.activities_due?.due_in_30_days?.length }}
                  </p>
                  <p>
                    Activities due in 45 days:
                    {{ planStats?.activities_due?.due_in_45_days?.length }}
                  </p>
                </div>
                <div class="status-count">
                  <h6>Status Count -</h6>
                  <div>
                    <highcharts-chart
                      style="display: flex"
                      [Highcharts]="Highcharts"
                      [options]="statusChartData"
                      [oneToOne]="true"
                      style="display: block; height: 300px"
                    ></highcharts-chart>
                  </div>
                </div>
                <h6>Status by Owner -</h6>
                <div *ngFor="let owner of planStats?.status_by_owner">
                  <p>{{ owner.first_name + " " + owner.last_name }}</p>
                  <div
                    class="custom-table table-responsive"
                    style="max-height: calc(100vh - 220px)"
                  >
                    <table class="table table-bordered">
                      <thead style="position: sticky; z-index: 1">
                        <tr>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-center">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Yet to start</td>
                          <td>{{ owner.yet_to_start_count }}</td>
                        </tr>
                        <tr>
                          <td>Work in progress</td>
                          <td>{{ owner.work_in_progress_count }}</td>
                        </tr>
                        <tr>
                          <td>Done</td>
                          <td>{{ owner.done_count }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
                style="font-size: 13px"
              >
                Curated from Web
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse show"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-2">
                <app-gnews
                  [pagename]="'Balanced Scorecard'"
                  [display_header]="'false'"
                ></app-gnews>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
