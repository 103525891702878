<div class="pb-3 px-0">
    <app-filter-card [pagetype]="'people_competency_map'" ></app-filter-card>
</div>
<div class="px-0 pb-2">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane">
            <div class="d-flex mb-2" style="align-items: center; justify-content: space-between;">
                <h5>People Competency Map</h5>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="custom-height">
                        <div class="custom-table table-responsive" style="max-height: calc(100vh - 220px);">
                            <table class="table table-bordered">
                                <thead style="position: sticky; z-index: 1">
                                    <tr>
                                        <th *ngFor="let field of headerList;" scope="col" class="text-center">{{field}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let res of competencyList?.results; let i = index">
                                        <tr>
                                            <td>{{res.external_id}}</td>
                                            <td>{{res.title}}</td>
                                            <td>{{res.job_category}}</td>
                                            <td>{{res.client_location}}</td>
                                            <td>{{res.qualification}}</td>
                                            <td>{{res.responsibilities}}</td>
                                            <td>{{res?.personas}}</td>    
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tr *ngIf="!competencyList?.count">
                                    <td colspan="14" class="noRecords text-center mt-2">
                                        <span class="mt-2">{{staticText.common.no_records_found}} </span>
                                    </td>
                                </tr>
                            </table>
                        </div>  
                    </div>
                    <div class="d-flex  align-items-center justify-content-between" *ngIf="competencyList?.count > 0">
                        <div class="p-1">
                            <div
                                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
                                <span class="p-1">Displaying</span>
                                <select class="form-select form-select-sm table-sort-filter"
                                    aria-label="Default select example" [(ngModel)]="sortRecords"
                                    (ngModelChange)="getRecords()">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                </select>
                                <span class="p-1">{{competencyList?.count}}</span>
                                <span class="p-1">records</span>
                            </div>
                        </div>
                        <div class="p-1" >
                            <ngb-pagination [pageSize]="sortRecords" [collectionSize]="competencyList?.count" [maxSize]="2"
                                [rotate]="true" [(page)]="CurrentPage" [boundaryLinks]="true"
                                (pageChange)="getRecords();">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <app-gnews></app-gnews>
        </div>
    </div>
</div>