import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-continuous-improvment',
  templateUrl: './continuous-improvment.component.html',
  styleUrls: ['./continuous-improvment.component.scss']
})
export class ContinuousImprovmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
