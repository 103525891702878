import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-subindex',
  templateUrl: './subindex.component.html',
  styleUrls: ['./subindex.component.scss']
})
export class SubIndexComponent implements OnInit {
  currentYear:any;
  loading:any = false;
  staticText: any = (textConfiguration as any).default;
  isEmbedded: any = false;
  constructor(private activatedRoute: ActivatedRoute) { }
  

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();
    this.activatedRoute.queryParams.subscribe(params => {
      // Access the query parameters here
      this.isEmbedded = params.embedded == 'true';
    });
  }

}
