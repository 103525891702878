<app-modal-header-common [title]="template?.name"> </app-modal-header-common>
<div style="padding: 20px; font-size: 13px;">
    <div *ngIf="template" style="height: 60vh; overflow: scroll;">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false" [expanded]="isFirst" *ngFor="let q of questions ; first as isFirst">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{q.group}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let data of q.questions; let i = index" class="q mb-2 m-2">
                    <span>{{i + 1}}</span>
                    <h6 class="m-0"><b>{{data.question}}</b><p class="mt-1 mb-0" *ngIf="data.context"><br>Measure of Success: {{data.context}}</p></h6>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

<!-- 
        <div *ngFor="let data of template.questions; let i = index" class="q mb-2">
            <span>{{i + 1}}</span>
            <h6>{{data.question}}</h6>
        </div> -->
    </div>
    <div style="padding: 10px; display: flex; justify-content: right;">
        <button mat-flat-button color="primary" (click)="closeModal(true)" style="width: auto;">
            Proceed
        </button>
    </div>
</div>