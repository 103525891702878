import { Component, OnInit } from "@angular/core";
import { PrismService } from "src/app/shared/services/prism.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { MessageService } from "src/app/shared/services/message.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";

@Component({
  selector: "app-sowmaster",
  templateUrl: "./sowmaster.component.html",
  providers: [DatePipe],
  styleUrls: ["./sowmaster.component.scss"],
})
export class SowmasterComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  headerList: any = [
    "#",
    "Contract Name",
    "Contract Type",
    "Invoice Type",
    "Contract Value",
    "Enterprise Contract Id",
    "Owner",
    "Start Date",
    "End Date",
    "OU/GDP",
    "Action",
  ];
  sowMasterList: any = [];
  sowMasterForm: FormGroup | any;
  vendorList: any;
  userList: any;
  templatedoc: any;
  templateSize: any;
  templateFile: any;
  monthList: any = this.staticText?.delivery?.month_list;
  editSowMasterId: any;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  loading: any = false;
  contentValue: any = "New Contract";
  firstTimeLoad: boolean = true;
  allContractList: any = [];
  filterName: any = null;
  filterInvoice: any = null;
  filterType: any = null;
  filterStatus: any = null;
  filterExecutionType: any = null;
  filterOwner: any = null;
  filterOu: any = null;
  filterContractParty: any = null;
  statusList: any = [];
  typesList: any = [];
  executionTypesList: any = [];
  portfolioList: any = [];
  ownersList: any = [];

  constructor(
    private prism: PrismService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private datepipe: DatePipe,
    private messageService: MessageService,
    private filterService: FilterService,
    private assessmentCentralService: AssessmentCentralService
  ) {
    this.sowMasterForm = this.fb.group({
      contract_name: ["", Validators.required],
      contract_type: ["", Validators.required],
      invoice_type: ["", Validators.required],
      contract_value: ["", Validators.required],
      tenure: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      owner_id: ["", Validators.required],
      slt: ["", Validators.required],
      enterprise_contract_id: [null],
      vendor_id: ["", Validators.required],
      file: [""],
      linked_contract: [""],
      contract_party_portfolio_id: ["", Validators.required],
      execution_type_id: ["", Validators.required],
      status_id: ["", Validators.required],
      sourcing_contract: ["", Validators.required],
      legal_contract: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.messageService.setGnews("IPDB - Contract Master");
    this.getSowMasterList();
    this.getVendor();
    this.getUser();
    this.getAllMasterList();
    this.getFilterOptions();
  }

  getStatusList() {
    this.prism.getStatus().subscribe((resp: any) => {
      this.statusList = resp;
    });
  }

  getTypesList() {
    this.prism.getTypesList().subscribe((resp: any) => {
      this.typesList = resp;
    });
  }

  getExecutionTypesList() {
    this.prism.getExecutionTypesList().subscribe((resp: any) => {
      this.executionTypesList = resp;
    });
  }

  getPortfolioList() {
    this.filterService.getPortfolios().subscribe((resp: any) => {
      this.portfolioList = resp.records;
    });
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getFilterOptions() {
    this.getStatusList();
    this.getTypesList();
    this.getExecutionTypesList();
    this.getPortfolioList();
    this.getOwners();
  }

  onClickNewSOWMaster(modal: any) {
    this.dialog.open(modal, {
      width: "30vw",
      height: "70vh",
    });
    this.editSowMasterId = "";
    this.contentValue = "New Contract";
    this.sowMasterForm.reset();
  }

  getSowMasterList() {
    this.sowMasterList = [];
    this.prism
      .getSoWMasterList(this.startValue, this.EndValue)
      .subscribe((res: any) => {
        this.sowMasterList = res;
      });
  }

  async getAllMasterList() {
    this.allContractList = [];
    await this.prism.getAllContractList().subscribe((res: any) => {
      this.allContractList = res.records;
    });
  }

  async getVendor() {
    await this.prism.getVendor().subscribe((res: any) => {
      this.vendorList = res;
    });
  }

  async getUser() {
    await this.prism.getUser().subscribe((res: any) => {
      this.userList = res;
    });
  }

  editSowMaster(cont: any, modal: any) {
    this.dialog.open(modal, {
      width: "30vw",
      height: "70vh",
    });
    this.editSowMasterId = cont?.id;
    this.contentValue = "SOW Details";
    this.sowMasterForm.patchValue({
      contract_name: cont?.contract_name,
      contract_type: cont?.contract_type,
      invoice_type: cont?.invoice_type,
      contract_value: cont?.contract_value,
      tenure: cont?.tenure,
      start_date: cont?.start_date,
      end_date: cont?.end_date,
      owner_id: cont?.owner?.id,
      slt: cont?.slt,
      enterprise_contract_id: cont?.enterprise_contract_id,
      vendor_id: cont?.vendor?.id,
      contract_party_portfolio_id: cont?.contract_party_portfolio?.id,
      execution_type_id: cont?.execution_type?.id,
      status_id: cont?.status?.id,
      sourcing_contract: cont?.sourcing_contract,
      legal_contract: cont?.legal_contract,
      file: "",
      linked_contract: cont?.linked_contract,
    });

    const ext = cont?.contract_attachment?.substring(
      cont?.contract_attachment?.lastIndexOf(".") + 1
    );
    this.templatedoc = ext?.toLowerCase();
    this.templateFile = cont?.contract_attachment;
  }

  deleteSowMaster(id: any) {
    this.spinner.show();
    this.prism.deleteSoWContract(id).subscribe((res: any) => {
      this.spinner.hide();
      this.getSowMasterList();
    });
  }

  cancel() {
    this.dialog.closeAll();
  }

  onFileSelected(e: any) {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error("Please upload less than 5MB");
        return;
      }
      this.sowMasterForm.get("file").setValue(file);
      this.templateFile = inputElement.files[0].name;
      this.templateSize = inputElement.files[0].size / 1000000;
      const ext = inputElement.files[0].name.substring(
        inputElement.files[0].name.lastIndexOf(".") + 1
      );
      this.templatedoc = ext?.toLowerCase();
    }
  }
  emptyFile() {
    this.templatedoc = "";
    this.templateSize = "";
    this.templateFile = "";
    this.sowMasterForm.get("file").setValue("");
  }
  deleteFile(i: any, content: any) {
    this.templatedoc = "";
    this.templateSize = "";
    this.templateFile = "";
    this.sowMasterForm.get("file").setValue("");
  }
  saveSOWMaster() {
    this.sowMasterForm.markAllAsTouched();
    if (this.sowMasterForm.invalid) {
      return;
    }
    if (!this.templateFile) {
      this.toastrService.error("Please attach a document.");
      return;
    }
    this.spinner.show();
    this.sowMasterForm.value.start_date = this.datepipe.transform(
      this.sowMasterForm.get("start_date").value,
      "yyyy-MM-dd"
    );
    this.sowMasterForm.value.end_date = this.datepipe.transform(
      this.sowMasterForm.get("end_date").value,
      "yyyy-MM-dd"
    );
    let formData: any = new FormData();

    for (const key of Object.keys(this.sowMasterForm.value)) {
      if (
        key === "linked_contract" &&
        this.sowMasterForm.value["contract_type"] !== "CR" &&
        this.sowMasterForm.value["contract_type"] !== "Amendment"
      ) {
        continue;
      }
      formData.append(key, this.sowMasterForm.value[key]);
    }

    if (!this.editSowMasterId) {
      this.prism.saveSowMaster(formData).subscribe((res: any) => {
        this.toastrService.success("Contract Created");
        this.dialog.closeAll();
        this.getSowMasterList();
        this.emptyFile();
        this.sowMasterForm.reset();
        this.spinner.hide();
      });
    } else {
      this.prism
        .patchSowMaster(formData, this.editSowMasterId)
        .subscribe((res: any) => {
          this.toastrService.success("Contract Updated");
          this.dialog.closeAll();
          this.getSowMasterList();
          this.emptyFile();
          this.sowMasterForm.reset();
          this.spinner.hide();
        });
    }
  }

  getName(user: any) {
    return user.first_name + " " + user.last_name;
  }

  downloadFile(id: any, fileName: any) {
    this.spinner.show();
    this.prism.downloadContractMasterFile(id).subscribe(
      (res: any) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }
  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if (!this.firstTimeLoad) {
      this.getSowMasterList();
    }
    this.firstTimeLoad = false;
  }

  search() {
    this.prism
      .getFilteredSowMasterList(
        this.filterName,
        this.filterInvoice,
        this.filterOu,
        this.filterType,
        this.filterStatus,
        this.filterExecutionType,
        this.filterOwner,
        this.filterContractParty,
        this.startValue,
        this.EndValue
      )
      .subscribe((res: any) => {
        this.sowMasterList = res;
      });
  }
}
