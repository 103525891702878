import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { error } from "console";
import { ToastrService } from "ngx-toastr";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import { MessageService } from "src/app/shared/services/message.service";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";
import { SharedModule } from "src/app/shared/shared.module";
@Component({
  selector: "plan-activity-modal",
  templateUrl: "plan-activity-modal.html",
  styleUrls: ["plan-activity-modal.scss"],
  providers: [DatePipe, SharedModule],
})
export class PlanActivityModalComponent implements OnInit {
  file: any;
  fileName: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<PlanActivityModalComponent>,
    private turnkeyTrackerService: TurnkeyTrackerService
  ) {}

  ngOnInit(): void {
    if (this.data.item.file_name) {
      this.fileName = this.data.item.file_name;
    }
  }

  updateData(value: any, level: any, field: any, current: any) {
    let phaseIndx = this.data.ix;
    let actIndx = this.data.j;
    let obj = {
      [field]:
        field == "start_date" || field == "end_date"
          ? this.datePipe.transform(value, "YYYY-MM-dd")
          : value,
    };
    if (current.id) {
      this.patchData(level, current.id, obj, phaseIndx, actIndx);
    }
  }

  patchData(
    level: any,
    id: any,
    obj: any,
    phaseIndx: any = null,
    actIndx: any = null
  ) {
    if (id) {
      this.turnkeyTrackerService
        .updateActivity(id, obj)
        .subscribe((res: any) => {});
    }
  }

  onFileChange(event: any, item: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    const formData = new FormData();
    formData.append("file", this.file);
    this.turnkeyTrackerService
      .uploadCommentFile(formData, item.id)
      .subscribe((response: any) => {
        console.log(response);
      });
    event.target.value = "";
  }

  close() {
    this.dialogRef.close(true);
  }
}

@Component({
  selector: "save-as-template",
  templateUrl: "save-as-template-modal.html",
})
export class SaveAsTemplateModalComponent {
  templateName: any;

  constructor(
    public dialogRef: MatDialogRef<SaveAsTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private turnkeyTrackerService: TurnkeyTrackerService,
    private toastrService: ToastrService
  ) {}

  saveTemplate() {
    let payload = {
      template_name: this.templateName,
      plan_id: this.data.planId,
    };
    this.turnkeyTrackerService.saveTemplate(payload).subscribe((res: any) => {
      this.toastrService.success("Template saved successfully...");
      this.dialogRef.close();
    });
  }
}

import * as Highcharts from "highcharts";
import noData from "highcharts/modules/no-data-to-display";
noData(Highcharts);
@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
  providers: [DatePipe],
})
export class PlanComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  planArray: any = [];
  connectedTo: any = [];
  plan: any;
  planId: any;
  breadcrumb: any;
  loading: any = false;
  planStats: any;
  statusChartData: any;
  showInsights:boolean = false;
 
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private turnkeyTrackerService: TurnkeyTrackerService
  ) {}

  ngOnInit(): void {
    this.messageService.setGnews("Turnkey tracker creation");
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    });
    let breadCrumbFirst: any = [this.breadcrumb[0]];
    let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
    let breadCrumb = [
      { label: "Plan Central", url: "/confluence/teamplantracker" },
    ];
    breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast);

    this.breadCrumService.setBreadcrumb(breadCrumbFirst);

    this.getPlanId();
  }

  getPlanId() {
    this.activatedRoute.params.subscribe((params) => {
      this.planId = atob(params.id);
      this.getPlan();
      this.getPlanStats();
    });
  }

  showInsightsFun(){
    if(!this.showInsights){
      this.showInsights = true;
      return
    }
    this.showInsights = false;
  }
  initializeStatusChartInfo() {
    const xAxis = this.planStats.status_counts.map(
      (element: any) => element.status
    );
    const yAxis = this.planStats.status_counts.map(
      (element: any) => element.count
    );
    this.statusChartData = {
      chart: { type: "category", heigth: 180 },
      credits: { enabled: false },
      dataLabels: { enabled: true },
      exporting: { enabled: false },
      legend: { enabled: true },
      series: [
        {
          data: yAxis,
          dataLabels: { enabled: true },
          name: "Responses",
          type: "column",
        },
      ],
      title: { text: "" },
      tooltip: { shared: true },
      xAxis: {
        categories: xAxis,
        scrollbar: { enabled: false },
      },
      yAxis: { title: false },
      display_text: "Respondent response summary",
      id: "statusCountChart",
      question_type: "matrix_grid",
    };
  }

  getPlanStats() {
    this.turnkeyTrackerService
      .getPlanStats(this.planId)
      .subscribe((resp: any) => {
        this.planStats = resp;
        this.initializeStatusChartInfo();
      });
  }

  getPlan() {
    this.turnkeyTrackerService.getPlanDetail(this.planId).subscribe(
      (res: any) => {
        this.plan = res;
      },
      (error) => {
        this.router.navigateByUrl("confluence/teamplantracker");
      }
    );
  }

  getConnectedTo(phase_id: any) {
    let id = this.plan.phases.filter((e: any) => e.id != phase_id)[0]?.id;
    if (id) {
      return "dra" + id;
    } else {
      return "";
    }
  }

  drop(event: CdkDragDrop<string[]>, phase: any = null) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (phase == null) {
        this.sortPhase();
      } else {
        this.sortActivity(event.container.data, phase);
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.sortActivity(event.container.data, phase);
    }
  }

  addActivity(i: any) {
    this.plan.phases[i].activities.push({
      is_checked: false,
      name: "",
      phase: this.plan.phases[i].id,
    });
  }

  addPhase() {
    this.plan.phases.push({
      name: "",
      activities: "",
      plan: this.planId,
    });
  }

  deletePhase(i: any) {
    this.turnkeyTrackerService
      .deletePhase(this.plan.phases[i].id)
      .subscribe((res: any) => {
        this.plan.phases.splice(i, 1);
      });
  }

  deleteActivity(phase: any, j: any) {
    let index = this.planArray.findIndex(
      (e: any) => e.phase_id == phase.phase_id
    );
    this.planArray[index].activity.splice(j, 1);
  }

  getTagObject() {
    return {
      entity_id: this.planId,
      entity_type: "turnkey",
    };
  }

  viewMore(item: any, ix: any, j: any) {
    console.log(item);
    this.matDialog
      .open(PlanActivityModalComponent, {
        width: "500px",
        maxHeight: "400px",
        data: {
          item: item,
          ix: ix,
          j: j,
        },
      })
      .afterClosed()
      .subscribe((val) => {
        if (val) this.getPlan();
      });
  }

  saveAsTemplate() {
    this.matDialog.open(SaveAsTemplateModalComponent, {
      width: "500px",
      maxHeight: "400px",
      data: { planId: this.planId },
    });
  }

  updateData(
    value: any,
    level: any,
    field: any,
    current: any,
    phaseIndx: any = null,
    actIndx: any = null
  ) {
    console.log(value);
    let obj = {
      [field]:
        field == "start_date" || field == "end_date"
          ? this.datePipe.transform(value, "YYYY-MM-dd")
          : value,
    };
    if (current.id) {
      this.patchData(level, current.id, obj, phaseIndx, actIndx);
    } else {
      if (level == "phase") {
        this.plan.phases[phaseIndx][field] = value;
      } else if (level == "activity") {
        this.plan.phases[phaseIndx].activities[actIndx][field] = value;
      }
      this.patchData(
        level,
        null,
        this.plan.phases[phaseIndx],
        phaseIndx,
        actIndx
      );
    }
  }

  patchData(
    level: any,
    id: any,
    obj: any,
    phaseIndx: any = null,
    actIndx: any = null
  ) {
    console.log(obj);
    if (level == "plan") {
      this.turnkeyTrackerService.updatePlan(id, obj).subscribe((res: any) => {
        this.plan = res;
      });
    } else if (level == "phase") {
      if (id) {
        this.turnkeyTrackerService
          .updatePhase(id, obj)
          .subscribe((res: any) => {
            this.plan.phases.filter((e: any) => e.id == id)[0] = res;
          });
      } else {
        this.turnkeyTrackerService.createPhase(obj).subscribe((res: any) => {
          this.plan.phases[phaseIndx] = res;
          this.sortPhase();
        });
      }
    } else if (level == "activity") {
      if (id) {
        this.turnkeyTrackerService
          .updateActivity(id, obj)
          .subscribe((res: any) => {
            this.plan.phases[phaseIndx].activities.filter(
              (e: any) => e.id == id
            )[0] = res;
          });
      } else {
        let act = obj.activities[actIndx];
        this.turnkeyTrackerService.createActivity(act).subscribe((res: any) => {
          this.plan.phases[phaseIndx].activities[actIndx] = res;
          this.sortActivity(
            this.plan.phases[phaseIndx].activities,
            this.plan.phases[phaseIndx].id
          );
        });
      }
    }
  }

  sortPhase() {
    let phaseArr: any = {
      phase_ids: [],
    };
    this.plan.phases.forEach((element: any) => {
      phaseArr.phase_ids.push(element.id);
    });
    this.turnkeyTrackerService.sortPhase(phaseArr).subscribe((res: any) => {
      let ordr = 1;
      phaseArr.phase_ids.forEach((element: any) => {
        this.plan.phases.filter((e: any) => e.id == element)[0].order = ordr;
        ordr++;
      });
    });
  }

  sortActivity(data: any, phase: any) {
    let activityArr: any = {
      activity_ids: [],
      phase_id: phase,
    };
    let phaseIndx = this.plan.phases.findIndex((e: any) => e.id == phase);
    this.plan.phases[phaseIndx].activities.forEach((element: any) => {
      activityArr.activity_ids.push(element.id);
    });
    this.turnkeyTrackerService
      .sortActivity(activityArr)
      .subscribe((res: any) => {});
  }

  deletePlan() {
    this.turnkeyTrackerService.deletePlan(this.planId).subscribe((res: any) => {
      this.toastrService.success("Plan deleted successfully");
      this.router.navigateByUrl("confluence/teamplantracker");
    });
  }

  sendNotification(activityId: number) {
    this.turnkeyTrackerService
      .sendNotification(activityId)
      .subscribe((res: any) => {
        this.toastrService.success("Notification sent");
      });
  }

  navigateToPlanCentral() {
    this.router.navigateByUrl("confluence/teamplantracker");
  }
}
