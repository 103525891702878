import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { IndexComponent } from "./index/index.component";
import { NotificationPageComponent } from "./notification-page/notification-page.component";
import { ProgramComponent } from "./program/program.component";
import { ProposalComponent } from "./proposal/proposal.component";
import { SearchPageComponent } from "./search-page/search-page.component";
import { TermsEngagementComponent } from "./terms-engagement/terms-engagement.component";
import { WebsiteComponent } from "./website/website.component";
import { HackathonComponent } from "./hackathon/hackathon.component";
import { ItNorthStarComponent } from "./it-north-star/it-north-star.component";
import { MavenComponent } from "./maven/maven.component";
import { ContinuousImprovmentComponent } from "./continuous-improvment/continuous-improvment.component";
import { InteractiveReviewsComponent } from "./interactive-reviews/interactive-reviews.component";
import { TeamPlanTrackerComponent } from "./team-plan-tracker/team-plan-tracker.component";
import { HubbleComponent } from "./hubble/hubble.component";
import { TurnkeyTemplatesComponent } from "./turnkey-tracker/turnkey-templates/turnkey-templates.component";
import { PlanComponent } from "./turnkey-tracker/plan/plan.component";
import { WhatIfComponent } from "./what-if/what-if.component";
import { CuratedInsightsComponent } from "./curated-insights/curated-insights.component";
import { WebCrawlComponent } from "./web-crawl/web-crawl.component";
import { AssessmentCentralComponent } from "./assessment-central/assessment-central/assessment-central.component";
import { AssessmentTemplatesComponent } from "./assessment-central/assessment-templates/assessment-templates.component";
import { AssessmentAttendComponent } from "./assessment-central/assessment-attend/assessment-attend.component";
import { AssessmentResultComponent } from "./assessment-central/assessment-result/assessment-result.component";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { IpdbComponent } from "./ipdb/ipdb.component";
import { SowmasterComponent } from "./sowmaster/sowmaster.component";
import { SubIndexComponent } from "./assessment-central/subindex/subindex.component";
import { MetricListingPageComponent } from "./metric-listing-page/metric-listing-page.component";
import { GovernanceAssessmentCentralComponent } from "./governence-ct/governance-assessment-central/governance-assessment-central.component";
import { GovernanceAssessmentTemplatesComponent } from "./governence-ct/governance-assessment-templates/governance-assessment-templates.component";
import { GovernanceAssessmentAttendComponent } from "./governence-ct/governance-assessment-attend/governance-assessment-attend.component";
import { GovernanceAssessmentResultComponent } from "./governence-ct/governance-assessment-result/governance-assessment-result.component";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";
import { MastersComponent } from "./masters/masters.component";
import { PeopleCompetencyMapComponent } from './people-competency-map/people-competency-map.component';
import { TechnologyBusinessManagementTaxonomyComponent } from './technology-business-management-taxonomy/technology-business-management-taxonomy.component';
import { OrganizationHierarchyComponent } from './organization-hierarchy/organization-hierarchy.component';
import { SurveyCentralComponent } from "./survey-central/survey-central.component";

const routes: Routes = [
  {
    path: "",
    component: IndexComponent,
    data: { breadcrumb: "CoSInE" },
    children: [
      {
        path: "program",
        data: { breadcrumb: "Opportunity Workbench" },
        component: ProgramComponent,
      },
      {
        path: "proposals",
        component: ProposalComponent,
      },
      {
        path: "deal-structure",
        data: { breadcrumb: "Deal Structure" },
        component: TermsEngagementComponent,
      },
      {
        path: "deal",
        data: { breadcrumb: null },
        children: [
          {
            path: ":id",
            data: { breadcrumb: "" },
            loadChildren: () =>
              import(
                "./terms-engagement/deal-structure/deal-structure.module"
              ).then((m) => m.DealStructureModule),
          },
        ],
      },

      {
        path: "website",
        component: WebsiteComponent,
      },
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "hackathon",
        data: { breadcrumb: "Challenge Workbench" },
        component: HackathonComponent,
      },
    ],
  },
  {
    path: "performanceInsights",
    component: IndexComponent,
    data: { breadcrumb: "Kaleidoscope" },
    children: [
      {
        path: "hubble",
        data: { breadcrumb: "Hubble" },
        component: HubbleComponent,
      },
      {
        path: "survey-central",
        data: { breadcrumb: "Survey Central" },
        component: SurveyCentralComponent,
      },
    ],
  },
  {
    path: "strategicRelevance",
    component: IndexComponent,
    data: { breadcrumb: "Polestar" },
    children: [
      {
        path: "governancect",
        data: { breadcrumb: "Governance Lighthouse" },
        component: SubIndexComponent,
        children: [
          {
            path: "",
            component: GovernanceAssessmentCentralComponent,
            data: { breadcrumb: "" },
          },
          {
            path: "assessment-templates",
            component: GovernanceAssessmentTemplatesComponent,
            data: { breadcrumb: "Templates" },
          },
          {
            path: "assessment-attend/:id",
            data: { breadcrumb: (data: any) => `${data?.assessment?.name}` },
            resolve: { assessment: GovernanceLighthouseService },
            component: GovernanceAssessmentAttendComponent,
          },
          {
            path: "assessment-result/:id",
            data: { breadcrumb: (data: any) => `${data?.assessment?.name}` },
            resolve: { assessment: GovernanceLighthouseService },
            component: GovernanceAssessmentResultComponent,
          },
        ],
      },
      {
        path: "maven",
        data: { breadcrumb: "Maven" },
        component: CuratedInsightsComponent,
      },
      {
        path: "assessment-central",
        data: { breadcrumb: "Assessment Central" },
        component: SubIndexComponent,
        children: [
          {
            path: "",
            component: AssessmentCentralComponent,
            data: { breadcrumb: "" },
          },
          {
            path: "assessment-templates",
            component: AssessmentTemplatesComponent,
            data: { breadcrumb: "Templates" },
          },
          {
            path: "assessment-attend/:id",
            data: { breadcrumb: (data: any) => `${data?.assessment?.name}` },
            resolve: { assessment: AssessmentCentralService },
            component: AssessmentAttendComponent,
          },
          {
            path: "assessment-result/:id",
            data: { breadcrumb: (data: any) => `${data?.assessment?.name}` },
            resolve: { assessment: AssessmentCentralService },
            component: AssessmentResultComponent,
          },
        ],
      },
    ],
  },
  {
    path: "confluence",
    component: IndexComponent,
    data: { breadcrumb: "Confluence" },
    children: [
      {
        path: "deliveryAccelerator",
        data: { breadcrumb: "Delivery Accelerator" },
        component: ContinuousImprovmentComponent,
      },
      {
        path: "interactivereviews",
        data: { breadcrumb: "Parade" },
        component: InteractiveReviewsComponent,
      },
      {
        path: "teamplantracker",
        data: { breadcrumb: "Plan Central" },
        component: TeamPlanTrackerComponent,
      },
      {
        path: "teamplantemplates",
        data: { breadcrumb: "Plan Central Templates" },
        component: TurnkeyTemplatesComponent,
      },
      {
        path: "plan/:id",
        data: { breadcrumb: "Plan" },
        component: PlanComponent,
      },
    ],
  },
  {
    path: "search",
    component: SearchPageComponent,
  },
  {
    path: "notification",
    component: NotificationPageComponent,
  },
  {
    path: "",
    component: IndexComponent,
    data: { breadcrumb: "Prism" },
    children: [
      {
        path: "prism/ipdb",
        data: { breadcrumb: "IPDB" },
        children: [
          {
              path: "",
              component: IpdbComponent,
              data: { breadcrumb: "" },
          },
          {
            path: "survey-central",
            data: { breadcrumb: "Survey Central" },
            component: SurveyCentralComponent,
          }
        ]
      },
      {
        path: "prism/masters",
        data: { breadcrumb: "Masters" },
        children: [
          {
            path: "",
            component: MastersComponent,
            data: { breadcrumb: "" },
          },
          {
            path: "organization-hierarchy",
            component: OrganizationHierarchyComponent,
            data: { breadcrumb: "Organization Hierarchy" },
          }]
      },
      
      {
        path: "prism/what-if",
        data: { breadcrumb: "What-if Analysis" },
        component: WhatIfComponent,
      },
      {
        path: "prism/web-crawl",
        data: { breadcrumb: "Web Crawl" },
        component: WebCrawlComponent,
      },
      {
        path: "prism/curated-insights",
        data: { breadcrumb: "Curated Insights" },
        component: CuratedInsightsComponent,
      },
      {
        path: "sowmaster",
        component: SowmasterComponent,
        data: { breadcrumb: "Contract Central" },
      },
      {
        path: "metrics-list",
        component: MetricListingPageComponent,
        data: { breadcrumb: "Metrics" },
      },
      {
        path: "prism/people-competency-matrics",
        component: PeopleCompetencyMapComponent,
        data: { breadcrumb: "People Competency Map" },
      },
      {
        path: "prism/technology-business-management-taxonomy",
        component: TechnologyBusinessManagementTaxonomyComponent,
        data: { breadcrumb: "Technology Business Management Taxonomy" },
      },
      
      {
        path: "master",
        data: { breadcrumb: "Masters" },
        
      },
        
      

      // {
      //   path: 'prism/scrapeai',
      //   data: { breadcrumb: 'Scrape.AI' },
      //   component: MavenComponent
      // }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KeplerRoutingModule {}
