<app-modal-header-common [title]="'Create Plan'"> </app-modal-header-common>
<div class="plan-form">
  <form [formGroup]="planForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Plan Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Plan Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <div class="form-group">
      <label>Owner</label>
      <app-owner (refereshParentData)="ownerdata($event)"></app-owner>
    </div>
    <div class="form-group">
      <app-workgroup
        [showLabel]="true"
        [showCount]="3"
        (refereshParentData)="workgroupdata($event)"
      ></app-workgroup>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="start_date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker1">
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="end_date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-flat-button
        mat-button
        color="primary"
        class="bg-primary text-white"
        (click)="createPlan()"
      >
        Create
      </button>
    </div>
  </form>
</div>
