import { Component, OnInit } from '@angular/core';
import {  NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/shared/services/message.service';
import { WorkForceService } from 'src/app/shared/services/work-force.service';

@Component({
  selector: 'app-metric-listing-page',
  templateUrl: './metric-listing-page.component.html',
  styleUrls: ['./metric-listing-page.component.scss']
})
export class MetricListingPageComponent implements OnInit {
  headerList:any = ['#','Name','Quadrant'];
  metricsList:any = [];
  loading:any = false;
  constructor(
    private spinner: NgxSpinnerService,
    private workForceService: WorkForceService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getBrowseAIData()
    this.messageService.setGnews('IPDB - BSC Metrics')
  }


  async getBrowseAIData(){
    this.spinner.show();
    await this.workForceService.getmetric().subscribe((res:any)=>{
      this.metricsList = res      
      this.spinner.hide();              
    });
  }
}
