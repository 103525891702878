import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-crawl',
  templateUrl: './web-crawl.component.html',
  styleUrls: ['./web-crawl.component.scss']
})
export class WebCrawlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
