import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ElasticSearchService } from 'src/app/shared/services/elastic-search.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SearchPageComponent implements OnInit {
  // Elastic search Index
  private static readonly INDEX = 'project_mapping';
  currentYear!: number;

  searchPage: any;
  searchResults:any=[];
  searchedList: any = [];
  searchStatus: any = "search to find pages";
  searchedResults:any=[]
  EndValue: any = 10;
  startValue: any = 0;
  CurrentPage: any = 1;
  connectedStatus: boolean = false;
  staticText: any = (textConfiguration as any).default;
  constructor(private searchService: ElasticSearchService,
    private toastrService: ToastrService, private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.connectElasticServer();
    this.spinner.hide();
  }

  connectElasticServer() {
    this.searchService.CheckAvailability().then((status: any) => {
      this.connectedStatus = true;
      //this.toastrService.success('Elastic Server is Up');
    }, (error: any) => {
      this.connectedStatus = false;
      this.toastrService.error('Elastic Server is Down. please search it Might Up server in a minute');
    })
  }

  getSearchResult() {
    this.searchStatus = "Searching....."
    this.searchService.getAllSearchResult(SearchPageComponent.INDEX, this.searchPage, this.startValue, this.EndValue).then(
      (searchResults: any) => {
        if (searchResults && searchResults?.hits?.hits instanceof Array) {
          this.searchStatus = '';
          this.searchedList = searchResults['hits']?.hits;          
          this.searchedResults = searchResults['hits']?.hits;
          let userPortFolio: any = localStorage.getItem("permission");
          if(JSON.parse(userPortFolio)?.extra_detail?.is_all_of_it){
            this.searchedList = this.searchedList.filter((filterFalseList: any) => filterFalseList?._source?.hide !== true);
          }else{
            JSON.parse(userPortFolio)?.extra_detail?.portfolio.forEach((userPortFolios: any) => {
              this.searchedList = this.searchedList.filter((filterFalseList: any) => filterFalseList?._source?.hide !== true && filterFalseList?._source?.portfolio === userPortFolios?.display_name);
            })
          }
          this.searchedList.map((mapPageAsset: any) => {
            return mapPageAsset._source['page_asset'] = this.switchModule(mapPageAsset?._source?.type, mapPageAsset?._source?.real_id);
          })
          if (!this.searchedList || this.searchedList?.length == 0) {
            this.searchStatus = true;
          }
        } else {
          this.toastrService.error('Your Search - ' + this.searchPage + ' - did not Match with our Records')
          this.searchStatus = true;
          this.searchedList = [];
        }
      }, (error: any) => {
        this.searchStatus = true;
        this.toastrService.error('Problem Fetching Data. Please Try Again Later', 'Failed');
      });
  }

  // search() {
  //   this.searchStatus = "Searching....."
  //   this.searchService.getSearchResult(this.searchPage,).subscribe((searchResults: any) => {
  //     if (!!searchResults.count || searchResults?.data?.hits?.hits?.length > 0) {
  //       this.searchStatus = null;
  //       this.searchedList = searchResults['data']['hits']?.hits;
  //       this.searchedList.map((mapPageAsset: any) => {
  //         return mapPageAsset._source['page_asset'] = this.switchModule(mapPageAsset?._source?.type, mapPageAsset?._source?.id);
  //       })
  //     } else {
  //       this.searchStatus = 'not Found';
  //     }
  //   }, error => {
  //     this.searchStatus = 'not Found';
  //     this.toastrService.error('Problem Fetching Data.Please Try Again Later', 'Failed');
  //   })
  // }

  switchModule(Page: any, routeId: any) {
    let value: any;
    switch (Page) {
      case 'proposal':
        value = { backgroundcolor: '#ffd5eb', routepath: `/getproposal/`+btoa(routeId)+`/proposal-evaluation`, ico: 'Shape.svg' }
        break;
      case 'opportunity':
        value = { backgroundcolor: '#1a85d62b', routepath: `/rfx/`+btoa(routeId)+`/opportunity/preview`, ico: 'Trophy.svg' }
        break;
      case 'delivery':
        value = { backgroundcolor: '#c7ede5', routepath: `/view/`+btoa(routeId)+`/overview`, ico: 'delivery.svg' }
        break;
      case 'innovation':
        value = { backgroundcolor: '#dccef4', routepath: `/innovation/detail-ideas/`+btoa(routeId), ico: 'dashboard.svg' }
        break;
      case 'action_tracker':
        value = { backgroundcolor: null, routepath: `/actiontracker/detail-tracker/`+btoa(routeId), ico: 'action-tracker.svg' }
        break;
      case 'risk':
        value = { backgroundcolor: '#ed00002b', routepath: '/riskconvergence', ico: 'caution.svg' }
        break;
      case 'okr':
        value = { backgroundcolor: '#d8d5ff', routepath: '/okrconvergence', ico: 'target.svg' }
        break;
      case 'work_force_dashboard':
        value = { backgroundcolor: '#d1d1ff', routepath: '/workforce/dashboard', ico: 'labourer.svg' }
        break;
      case 'terms_of_engagement':
        value = { backgroundcolor: 'rgba(149, 108, 0, 0.17)', routepath: `/toe/`+btoa(routeId)+`/delivery-approval`, ico: 'uptrend.svg' }
        break;
      default:
        value = { backgroundcolor: '#ffd5eb', routepath: '/search', ico: 'link.svg' }
        break;
    }
    return value;
  }

  clearSearch() {
    this.searchPage = null;
    this.searchedList = [];
    this.searchStatus = 'search to find pages'
  }

  backSpaceClear() {
    if (!this.searchPage) {
      this.searchedList = [];
      this.searchStatus = 'search to find pages';
    }
  }

  loadMore() {
    let start: any;
    this.CurrentPage = ++this.CurrentPage;
    if (this.CurrentPage > 0) {
      start = (parseInt(this.CurrentPage) - 1) * (10);
    } else {
      start = 0
    }
    this.startValue = 0
    this.EndValue = parseInt(start) + 10;
    this.getSearchResult();
  }

  navigateTo(path: any) {
    this.spinner.show();
    this.router.navigate([path]).catch((error: any) => {
      this.spinner.hide();
      this.toastrService.error('Detail ' + error?.error?.detail, 'Failed')
    });
  }

}
