import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AssessmentCreateModalDialogComponent } from "./assessment-create-modal/assessment-create-modal";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { AssessmentCreateTemplateModalDialogComponent } from "../assessment-template-create-modal/assessment-template-create-modal";
import { MessageService } from "src/app/shared/services/message.service";
import { TagsService } from "src/app/shared/services/tag.service";
import { DeletePopupComponent } from "src/app/shared/components/delete-popup/delete-popup.component";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
  selector: "assessment-preview-modal",
  templateUrl: "assessment-preview-modal.html",
  styleUrls: ["assessment-preview-modal.scss"],
})
export class AssessmentPreviewModalDialogComponent implements AfterViewInit {
  template: any;
  questions: any = [];

  constructor(
    public dialogRef: MatDialogRef<AssessmentPreviewModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {
    this.template = this.data.dataKey;
    this.template.questions.forEach((element: any) => {
      let i = this.questions.findIndex(
        (e: any) => e.group == element.group_name
      );
      if (i > -1) {
        this.questions[i].questions.push(element);
      } else {
        this.questions.push({
          group: element.group_name,
          questions: [element],
        });
      }
    });
  }

  closeModal(bool: boolean = false): void {
    this.dialogRef.close(bool);
  }
}

@Component({
  selector: "app-assessment-templates",
  templateUrl: "./assessment-templates.component.html",
  styleUrls: ["./assessment-templates.component.scss"],
})
export class AssessmentTemplatesComponent implements OnInit, OnDestroy {
  templates: any = [];
  filteredTemplate: any = [];
  file: any;
  searchText: string = "";
  ownersList: any = [];
  filterCategory: any = null;
  filterName: any = null;
  filterApplicability: any = null;
  filterOwner: any = null;
  readMode: any = false;
  subscription: any;
  filterObj: any;
  tagFilterId: any;
  templatesLoaded: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private assessmentCentralService: AssessmentCentralService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.getTemplates();
    this.getOwners();
    this.messageService.setGnews("Assessment Central - Templates");
    this.route.queryParamMap.subscribe((params) => {
      this.readMode = params.get("readMode") === "1";
    });
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getTemplates() {
    this.templatesLoaded = false;
    this.assessmentCentralService.getTemplates().subscribe((res: any) => {
      res.forEach((category: any) => {
        category.templates.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });
      });
      this.templates = res;
      this.filteredTemplate = res;
      this.templatesLoaded = true;
    });
  }

  templateDownload() {
    window.open(
      "/assets/template/assessment_questionaire_template.xlsx",
      "_blank"
    );
  }

  previewAssessment(template: any) {
    const dialog = this.dialog.open(AssessmentPreviewModalDialogComponent, {
      width: "50vw",
      data: {
        dataKey: template,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
        this.proceed(template);
      }
    });
  }

  proceed(template: any) {
    const dialog = this.dialog.open(AssessmentCreateModalDialogComponent, {
      width: "500px",
      data: { template_id: template.id, template_name: template.name },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
      }
    });
  }

  async addTags(id: any, selectedTags: any) {
    if (selectedTags?.length > 0) {
      await selectedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "assessment_template",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  onFilechange(event: any) {
    this.file = event.target.files[0];
    const dialog = this.dialog.open(
      AssessmentCreateTemplateModalDialogComponent,
      {
        width: "500px",
        data: {
          uploadedFile: this.file,
        },
      }
    );
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("applicability", data.applicability);
        formData.append("scoring_methodology", data.scoring_methodology);
        formData.append(
          "assessment_scoring_scale",
          data.assessment_scoring_scale
        );
        formData.append("category", data.category);
        this.assessmentCentralService
          .uploadFile(formData)
          .subscribe((response: any) => {
            this.spinner.hide();
            if (response) {
              this.file = [];
              this.addTags(response.id, data.selectedTags);
              this.previewAssessment(response);
              this.getTemplates();
            }
          });
      }
      event.target.value = "";
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  search() {
    const tags = this.tagFilterId.join("|");
    this.assessmentCentralService
      .getFilteredAssessmentTemplates(
        this.filterCategory,
        this.filterName,
        this.filterApplicability,
        tags,
        this.filterOwner
      )
      .subscribe((res: any) => {
        res.forEach((category: any) => {
          category.templates.forEach((element: any) => {
            element.color = this.getGMapRandomColor();
          });
        });
        this.templates = res;
        this.filteredTemplate = res;
      });
  }

  deleteTemplate(id: any, name: string) {
    let dialogRef = this.dialog.open(DeletePopupComponent, {
      data: name,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.assessmentCentralService
          .deleteTemplate(id)
          .subscribe((res: any) => {
            this.toastrService.success("Templated deleted successfully !");
            this.getTemplates();
          });
      }
    });
  }

  blankPlan() {
    this.proceed(null);
  }

  navigateToAssessmentCentral() {
    this.router.navigateByUrl("strategicRelevance/assessment-central");
  }
}
