
<div class="px-0 pb-2">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane">
            <div class="d-flex mb-2" style="align-items: center; justify-content: space-between;">
                <h5>Metric List</h5>                
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="custom-height">
                        <div class="custom-table table-responsive" style="max-height: calc(100vh - 220px);">
                            <table class="table table-bordered">
                                <thead style="position: sticky; z-index: 1">
                                    <tr>
                                        <th *ngFor="let field of headerList;" scope="col" class="text-left" style="background: #01356d;
                                        color: white;">{{field}}</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cont of metricsList; let i = index">
                                        <tr>
                                            <td>{{i+1}}</td>                                         
                                            <td>{{cont.display_name}}</td>                                                                      
                                            <td>{{cont.phase__display_name}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>                                
                            </table>
                        </div>
                    </div> 
                    
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <app-gnews></app-gnews>
            <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style="font-size: 13px">
                        Curated from Web
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-2">
                        <app-loading *ngIf="loading"></app-loading>
                        <app-gnews [display_header]="'false'"></app-gnews>
                    </div>
                </div>
            </div>     -->
        </div>

        
    </div>
</div>