<app-modal-header-common title="Create plan central template">
</app-modal-header-common>
<div class="plan-form">
  <p class="uploaded-file">Template file - {{ this.data.uploadedFile.name }}</p>
  <form [formGroup]="templateForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category">
        <mat-option *ngFor="let option of categories" [value]="option.id">
          {{ option.display_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-tag
      [noIds]="true"
      [tagsIds]="assessmentTags"
      (childTags)="getChildTags($event)"
    ></app-tag>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="createPlanCentralTemplate()"
        [disabled]="templateForm.invalid"
      >
        Create
      </button>
    </div>
  </form>
</div>
