<div class="d-flex flex-column">
    <div class="bg-cont-overlay">
        <div class="container-fluid container-xxl">
            <div class="d-flex flex-row justify-content-between mx-auto">
                <div class="p-1">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill pe-1 ps-1">
                            <h5 class="text-white text-center mb-0 fw-normal fs-30">
                                <!-- <span class="d-inline-flex">
                                    <img src="assets/images/home/home.svg" class="mr-2" width="24" />
                                </span>{{staticText.menu.home}} -->
                                {{userDomain?.includes('localhost')?'Alpha':userDomain |titlecase}}
                            </h5>
                        </div>
                        <div class="flex-fill pe-1 ps-2">
                            <button type="button" class="btn text-white actionTracker"
                                (click)="navigateTo('actiontracker')">
                                <span class="d-inline-flex">
                                    <img src="assets/images/home/action.svg" class="mr-2" width="20" />
                                </span>
                                {{staticText.home.actionTrackerBtn}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-1">
                    <h5 class="text-right color-torquise fw-normal fs-24">Welcome {{user.full_name}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-xxl mb-5">
        <div class="row margin-minus-50 mx-auto">
            <div class="col pe-2 ps-2">
                <div class="similarCard">
                    <div class="bubble-top" style="background-color: #07B797;"></div>
                    <div class="home-menu-content">
                        <div class="menu-Head p-1">
                            <h5>
                                <span class="home-menu-ico-cont mr-2 home-color-lgreen text-dark">
                                    <img src="assets/images/home/job.svg" />
                                </span>
                                {{staticText.home.homeMainTitle1}}
                            </h5>
                        </div>
                        <div class="Para-twoline fs-14 p-1">
                            <p>{{staticText.home.homeMainDesc1}}</p>
                        </div>
                        <div class="p-1">
                            <div class="opportunity-innerPage-1 border-radius-8 pt-3">
                                <span class="bubble-notification" *ngIf="notificationList?.bid?.length">
                                    <span class="bubble-notify-bell" container="body"
                                        [ngbPopover]="opportunityNotificationBid" popoverClass="lightTheme">
                                        <span
                                            class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                            style="transform: translate(-151%,-57%)!important;top:12px">
                                        </span><img src="assets/images/home/bell.svg" />
                                    </span>
                                    <ng-template #opportunityNotificationBid>
                                        <div class="d-flex flex-column">
                                            <div
                                                class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                <div class="notification-count">
                                                    <label class="fw-500">{{notificationList?.bid?.length}}</label>
                                                </div>
                                            </div>
                                            <div class="inner-scroll">
                                                <div class="p-1 border-bottom cursor-pointer"
                                                    *ngFor="let notification of notificationList?.bid; index as notificationIndex">
                                                    <div class="d-flex align-items-center hover-box border-15"
                                                        (click)="MarkAsReadNotification(notification?.id,'bid-awards',notification?.routepath)">
                                                        <div class="flex-shrink-0 p-2 pb-0">
                                                            <div class="inner-Notification-bell"
                                                                [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                <img src="assets/images/home/inner-bell.svg" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 p-2 pb-0">
                                                            <div>
                                                                <h5 class="fw-500 fs-14">
                                                                    {{notification?.purpose}}
                                                                </h5>
                                                                <p class="text-muted">
                                                                    {{notification?.posted_at | dateAgo}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                                <span class="bubble-notification" *ngIf="!notificationList?.bid?.length">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head" (click)="navigateTo('program')">
                                    <span class="home-menu-icon home-color-blue home-border-blue">
                                        <img src="assets/images/home/Trophy.svg" />
                                    </span>
                                    <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle1"></h6>
                                </div>
                                <div class="inner-Content Para-threeline">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc1}}
                                    </p>
                                    <!--   <ul class="mb-0">
                                        <h5 class="fw-500 fs-16 mb-0">
                                            <li class="p-1 link-menu" (click)="navigateTo('program')">Opportunities
                                            </li>
                                            <li class="p-1 link-menu" (click)="navigateTo('proposals')">Proposals
                                            </li>
                                        </h5>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="p-1">
                            <div class="opportunity-innerPage-2 border-radius-8 pt-3">
                                <span class="bubble-notification" *ngIf="notificationList?.deal?.length">
                                    <span class="bubble-notify-bell" container="body" 
                                        [ngbPopover]="opportunityNotifications" popoverClass="lightTheme">
                                        <span
                                            class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                            style="transform: translate(-151%,-57%)!important;top:12px">
                                        </span><img src="assets/images/home/bell.svg" />
                                    </span>
                                    <ng-template #opportunityNotifications>
                                        <div class="d-flex flex-column">
                                            <div
                                                class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                <div class="notification-count">
                                                    <label class="fw-500">{{notificationList?.deal?.length}}</label>
                                                </div>
                                            </div>
                                            <div class="inner-scroll">
                                                <div class="p-1 border-bottom cursor-pointer"
                                                    *ngFor="let notification of notificationList?.deal; index as notificationIndex">
                                                    <div class="d-flex align-items-center hover-box border-15"
                                                        (click)="MarkAsReadNotification(notification?.id,'deal-structuring',notification?.routepath)">
                                                        <div class="flex-shrink-0 p-2 pb-0">
                                                            <div class="inner-Notification-bell"
                                                                [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                <img src="assets/images/home/inner-bell.svg" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 p-2 pb-0">
                                                            <div>
                                                                <h5 class="fw-500 fs-14">
                                                                    {{notification?.purpose}}
                                                                </h5>
                                                                <p class="text-muted">
                                                                    {{notification?.posted_at | dateAgo}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                                <span class="bubble-notification" *ngIf="!notificationList?.deal?.length">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head" (click)="navigateTo('deal-structure')">
                                    <span class="home-menu-icon home-color-brown home-border-brown">
                                        <img src="assets/images/home/uptrend.svg" />
                                    </span>
                                    <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle2"></h6>
                                </div>
                                <div class="inner-Content Para-threeline" (click)="navigateTo('deal-structure')">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc2}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col pe-2 ps-2">
                <div class="customCard">
                    <div class="bubble-top" style="background-color: #D40F78;"></div>
                    <div class="ipdb-btn float-right px-2">
                        <a href="/prism/ipdb" target="_blank">
                            <button type="button" class="btn text-white ipdbButton fs-14">
                                <span class="d-inline-flex">
                                    <img src="assets/images/home/ipdb.svg" class="mr-1" width="16" />
                                </span>
                                {{staticText.home.ipdbBtn}}
                            </button>
                        </a>
                    </div>
                    <div class="home-menu-content">
                        <div class="menu-Head p-1">
                            <h5>
                                <span class="home-menu-ico-cont mr-2 home-color-pRose text-dark">
                                    <img src="assets/images/home/insight.svg" />
                                </span>
                                {{staticText.home.homeMainTitle2}}
                            </h5>
                        </div>
                        <div class="Para-twoline fs-14 p-1">
                            <p>{{staticText.home.homeMainDesc2}}</p>
                        </div>
                        <div class="custom-inner-row">
                            <div class="p-1">
                                <div class="insight-innerPage-1 border-radius-8 pt-3">
                                    <span class="bubble-insight-notification"
                                        *ngIf="notificationList?.delivery?.length">
                                        <span class="bubble-notify-bell" container="body" 
                                            [ngbPopover]="insightNotificationDelivery" popoverClass="lightTheme">
                                            <span
                                                class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                                style="transform: translate(-151%,-57%)!important;top:12px">
                                            </span><img src="assets/images/home/bell.svg" />
                                        </span>
                                        <ng-template #insightNotificationDelivery>
                                            <div class="d-flex flex-column">
                                                <div
                                                    class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                    <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                    <div class="notification-count">
                                                        <label
                                                            class="fw-500">{{notificationList?.delivery?.length}}</label>
                                                    </div>
                                                </div>
                                                <div class="inner-scroll">
                                                    <div class="p-1 border-bottom cursor-pointer"
                                                        *ngFor="let notification of notificationList?.delivery; index as notificationIndex">
                                                        <div class="d-flex align-items-center hover-box border-15"
                                                            (click)="MarkAsReadNotification(notification?.id,'delivery-performance',notification?.routepath)">
                                                            <div class="flex-shrink-0 p-2 pb-0">
                                                                <div class="inner-Notification-bell"
                                                                    [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                    <img src="assets/images/home/inner-bell.svg" />
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 p-2 pb-0">
                                                                <div>
                                                                    <h5 class="fw-500 fs-14">
                                                                        {{notification?.purpose}}
                                                                    </h5>
                                                                    <p class="text-muted">
                                                                        {{notification?.posted_at | dateAgo}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </span>
                                    <span class="bubble-insight-notification "
                                        *ngIf="!notificationList?.delivery?.length">
                                        <span class="bubble-notify-bell-disable">
                                            <img src="assets/images/home/bell-disable.svg" />
                                        </span>
                                    </span>
                                    <div class="inner-page-head" (click)="navigateTo('balancedscorecard/dashboard')">
                                        <span class="home-menu-icon home-color-green home-border-green">
                                            <img src="assets/images/home/delivery.svg" />
                                        </span>
                                        <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle3"></h6>
                                    </div>
                                    <div class="inner-Content Para-threeline"
                                        (click)="navigateTo('balancedscorecard/dashboard')">
                                        <p class="fs-14 px-3">
                                            {{staticText.home.homeSubMenuDesc3}}
                                        </p>
                                        <!--   <ul class="mb-0">
                                            <h5 class="fw-500 fs-16 mb-0">
                                                <li class="p-1 link-menu"
                                                    (click)="navigateTo('balancedscorecard/dashboard')">
                                                    Balance Scorecard</li>
                                                <li class="p-1 link-menu" (click)="navigateTo('delivery/projects')">
                                                    Project Zone</li>
                                            </h5>
                                        </ul> -->
                                    </div>
                                </div>
                            </div>
                            <div class="p-1">
                                <div class="insight-innerPage-2 border-radius-8 pt-3">
                                    <span class="bubble-insight-notification">
                                        <span class="bubble-notify-bell-disable">
                                            <img src="assets/images/home/bell-disable.svg" />
                                        </span>
                                    </span>
                                    <div class="inner-page-head" (click)="navigateTo('riskconvergence')">
                                        <span class="home-menu-icon home-color-red home-border-red">
                                            <img src="assets/images/home/caution.svg" />
                                        </span>
                                        <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle4"></h6>
                                    </div>
                                    <div class="inner-Content Para-threeline" (click)="navigateTo('riskconvergence')">
                                        <p class="fs-14 px-3">
                                            {{staticText.home.homeSubMenuDesc4}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-1">
                                <div class="insight-innerPage-3 border-radius-8 pt-3">
                                    <span class="bubble-insight-notification" *ngIf="notificationList?.bvt?.length">
                                        <span class="bubble-notify-bell" container="body" 
                                            [ngbPopover]="insightNotificationBVT" popoverClass="lightTheme">
                                            <span
                                                class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                                style="transform: translate(-151%,-57%)!important;top:12px">
                                            </span><img src="assets/images/home/bell.svg" />
                                        </span>
                                        <ng-template #insightNotificationBVT>
                                            <div class="d-flex flex-column">
                                                <div
                                                    class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                    <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                    <div class="notification-count">
                                                        <label class="fw-500">{{notificationList?.bvt?.length}}</label>
                                                    </div>
                                                </div>
                                                <div class="inner-scroll">
                                                    <div class="p-1 border-bottom cursor-pointer"
                                                        *ngFor="let notification of notificationList?.bvt; index as notificationIndex">
                                                        <div class="d-flex align-items-center hover-box border-15"
                                                            (click)="MarkAsReadNotification(notification?.id,'bvt',notification?.routepath)">
                                                            <div class="flex-shrink-0 p-2 pb-0">
                                                                <div class="inner-Notification-bell"
                                                                    [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                    <img src="assets/images/home/inner-bell.svg" />
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 p-2 pb-0">
                                                                <div>
                                                                    <h5 class="fw-500 fs-14">
                                                                        {{notification?.purpose}}
                                                                    </h5>
                                                                    <p class="text-muted">
                                                                        {{notification?.posted_at | dateAgo}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </span>
                                    <span class="bubble-insight-notification " *ngIf="!notificationList?.bvt?.length">
                                        <span class="bubble-notify-bell-disable">
                                            <img src="assets/images/home/bell-disable.svg" />
                                        </span>
                                    </span>
                                    <div class="inner-page-head" (click)="navigateTo('BVT/list')">
                                        <span
                                            class="home-menu-icon home-color-red home-border-red home-color-orange home-border-orange">
                                            <img src="assets/images/home/survey.svg" />
                                        </span>
                                        <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle5"></h6>
                                    </div>
                                    <div class="inner-Content Para-threeline">
                                        <p class="fs-14 px-3">
                                            {{staticText.home.homeSubMenuDesc5}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-1">
                                <div class="insight-innerPage-4 border-radius-8 pt-3">
                                    <span class="bubble-insight-notification" *ngIf="notificationList?.work?.length">
                                        <span class="bubble-notify-bell" container="body" 
                                            [ngbPopover]="insightNotificationWorkForce" popoverClass="lightTheme">
                                            <span
                                                class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                                style="transform: translate(-151%,-57%)!important;top:12px">
                                            </span><img src="assets/images/home/bell.svg" />
                                        </span>
                                        <ng-template #insightNotificationWorkForce>
                                            <div class="d-flex flex-column">
                                                <div
                                                    class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                    <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                    <div class="notification-count">
                                                        <label class="fw-500">{{notificationList?.work?.length}}</label>
                                                    </div>
                                                </div>
                                                <div class="inner-scroll">
                                                    <div class="p-1 border-bottom cursor-pointer"
                                                        *ngFor="let notification of notificationList?.work; index as notificationIndex">
                                                        <div class="d-flex align-items-center hover-box border-15"
                                                            (click)="MarkAsReadNotification(notification?.id,'work-force-dashboard',notification?.routepath)">
                                                            <div class="flex-shrink-0 p-2 pb-0">
                                                                <div class="inner-Notification-bell"
                                                                    [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                    <img src="assets/images/home/inner-bell.svg" />
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 p-2 pb-0">
                                                                <div>
                                                                    <h5 class="fw-500 fs-14">
                                                                        {{notification?.purpose}}
                                                                    </h5>
                                                                    <p class="text-muted">
                                                                        {{notification?.posted_at | dateAgo}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </span>
                                    <span class="bubble-insight-notification " *ngIf="!notificationList?.work?.length">
                                        <span class="bubble-notify-bell-disable">
                                            <img src="assets/images/home/bell-disable.svg" />
                                        </span>
                                    </span>
                                    <div class="inner-page-head" (click)="navigateTo('workforce')">
                                        <span class="home-menu-icon home-color-PortGore home-border-PortGore">
                                            <img src="assets/images/home/labourer.svg" />
                                        </span>
                                        <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle6"></h6>
                                    </div>
                                    <div class="inner-Content Para-threeline" (click)="navigateTo('workforce')">
                                        <p class="fs-14 px-3">
                                            {{staticText.home.homeSubMenuDesc6}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col pe-2 ps-2">
                <div class="similarCard">
                    <div class="bubble-top" style="background-color: #706FD3;"></div>
                    <div class="home-menu-content">
                        <div class="menu-Head p-1">
                            <h5>
                                <span class="home-menu-ico-cont mr-2 home-color-twhite text-dark">
                                    <img src="assets/images/home/strategy.svg" />
                                </span>
                                {{staticText.home.homeMainTitle3}}
                            </h5>
                        </div>
                        <div class="Para-twoline fs-14 p-1">
                            <p>{{staticText.home.homeMainDesc3}}</p>
                        </div>
                        <div class="p-1">
                            <div class="strategic-innerPage-1 border-radius-8 pt-3">
                                <span class="bubble-notification" *ngIf="notificationList?.okr?.length">
                                    <span class="bubble-notify-bell" container="body" 
                                        [ngbPopover]="strategicNotificationInsights" popoverClass="lightTheme">
                                        <span
                                            class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                            style="transform: translate(-151%,-57%)!important;top:12px">
                                        </span><img src="assets/images/home/bell.svg" />
                                    </span>
                                    <ng-template #strategicNotificationInsights>
                                        <div class="d-flex flex-column">
                                            <div
                                                class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                <div class="notification-count">
                                                    <label class="fw-500">{{notificationList?.okr?.length}}</label>
                                                </div>
                                            </div>
                                            <div class="inner-scroll">
                                                <div class="p-1 border-bottom cursor-pointer"
                                                    *ngFor="let notification of notificationList?.okr; index as notificationIndex">
                                                    <div class="d-flex align-items-center hover-box border-15"
                                                        (click)="MarkAsReadNotification(notification?.id,'okr',notification?.routepath)">
                                                        <div class="flex-shrink-0 p-2 pb-0">
                                                            <div class="inner-Notification-bell"
                                                                [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                <img src="assets/images/home/inner-bell.svg" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 p-2 pb-0">
                                                            <div>
                                                                <h5 class="fw-500 fs-14">
                                                                    {{notification?.purpose}}
                                                                </h5>
                                                                <p class="text-muted">
                                                                    {{notification?.posted_at | dateAgo}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                                <span class="bubble-notification" *ngIf="!notificationList?.okr?.length">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head" (click)="navigateTo('/okrconvergence')">
                                    <span class="home-menu-icon home-color-Dblue home-border-Dblue">
                                        <img src="assets/images/home/target.svg" />
                                    </span>
                                    <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle7"></h6>
                                </div>
                                <div class="inner-Content Para-threeline" (click)="navigateTo('/okrconvergence')">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc7}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="p-1" >
                            <div class="strategic-innerPage-2 border-radius-8 pt-3">
                                <span class="bubble-notification">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head" (click)="navigateTo('power-bi/dashboard')">
                                    <span class="home-menu-icon home-color-Madang home-border-Madang">
                                        <img src="assets/images/home/improve.svg" />
                                    </span>
                                    <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle8"></h6>
                                </div>
                                <div class="inner-Content Para-threeline">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc8}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col pe-2 ps-2">
                <div class="similarCard">
                    <div class="bubble-top" style="background-color: #1A85D6;"></div>
                    <div class="home-menu-content">
                        <div class="menu-Head p-1">
                            <h5>
                                <span class="home-menu-ico-cont mr-2 home-color-solitude text-dark">
                                    <img src="assets/images/home/collaborate.svg" />
                                </span>
                                {{staticText.home.homeMainTitle4}}
                            </h5>
                        </div>
                        <div class="Para-twoline fs-14 p-1">
                            <p>{{staticText.home.homeMainDesc4}}</p>
                        </div>
                        <div class="p-1">
                            <div class="collaborate-innerPage-1 border-radius-8 pt-3">
                                <span class="bubble-notification">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head">
                                    <span class="home-menu-icon home-color-pinkLace home-border-pinkLace">
                                        <img src="assets/images/home/Shape.svg" />
                                    </span>
                                    <h6 class="fs-16 cursor-pointer" (click)="navigateToSP()"
                                        [innerHTML]="staticText.home.homeSubMenuTitle9"></h6>
                                </div>
                                <div class="inner-Content Para-threeline">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc9}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="p-1">
                            <div class="collaborate-innerPage-2 border-radius-8 pt-3">
                                <span class="bubble-notification" *ngIf="notificationList?.continuous?.length">
                                    <span class="bubble-notify-bell" container="body" 
                                        [ngbPopover]="strategicNotificationContinous" popoverClass="lightTheme">
                                        <span
                                            class="position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                                            style="transform: translate(-151%,-57%)!important;top:12px">
                                        </span><img src="assets/images/home/bell.svg" />
                                    </span>
                                    <ng-template #strategicNotificationContinous>
                                        <div class="d-flex flex-column">
                                            <div
                                                class="p-1 d-flex justify-content-between align-items-center border-bottom">
                                                <h5 class="fw-500 fs-16">{{staticText?.home?.notifications}}</h5>
                                                <div class="notification-count">
                                                    <label
                                                        class="fw-500">{{notificationList?.continuous?.length}}</label>
                                                </div>
                                            </div>
                                            <div class="inner-scroll">
                                                <div class="p-1 border-bottom cursor-pointer"
                                                    *ngFor="let notification of notificationList?.continuous; index as notificationIndex">
                                                    <div class="d-flex align-items-center hover-box border-15"
                                                        (click)="MarkAsReadNotification(notification?.id,'continuous-improvement',notification?.routepath)">
                                                        <div class="flex-shrink-0 p-2 pb-0">
                                                            <div class="inner-Notification-bell"
                                                                [ngStyle]="{'background-color': (notificationIndex | getRandomColor)}">
                                                                <img src="assets/images/home/inner-bell.svg" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 p-2 pb-0">
                                                            <div>
                                                                <h5 class="fw-500 fs-14">
                                                                    {{notification?.purpose}}
                                                                </h5>
                                                                <p class="text-muted">
                                                                    {{notification?.posted_at | dateAgo}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                                <span class="bubble-notification" *ngIf="!notificationList?.continuous?.length">
                                    <span class="bubble-notify-bell-disable">
                                        <img src="assets/images/home/bell-disable.svg" />
                                    </span>
                                </span>
                                <div class="inner-page-head" (click)="navigateTo('innovation/idea-page')">
                                    <span class="home-menu-icon  home-color-moonraker home-border-moonraker">
                                        <img src="assets/images/home/dashboard.svg" />
                                    </span>
                                    <h6 class="fs-16" [innerHTML]="staticText.home.homeSubMenuTitle10"></h6>
                                </div>
                                <div class="inner-Content Para-threeline" (click)="navigateTo('innovation/idea-page')">
                                    <p class="fs-14 px-3">
                                        {{staticText.home.homeSubMenuDesc10}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>