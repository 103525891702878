import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";

@Component({
  selector: "governance-assessment-template-create-modal",
  templateUrl: "./governance-assessment-template-create-modal.html",
  styleUrls: ["./governance-assessment-template-create-modal.scss"],
  providers: [DatePipe],
})
export class GovernanceAssessmentCreateTemplateModalDialogComponent {
  categories: any;
  assessmentTags: any;
  templateForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    applicability: new FormControl(""),
    scoring_methodology: new FormControl(""),
    assessment_scoring_scale: new FormControl(""),
    category: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<GovernanceAssessmentCreateTemplateModalDialogComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService,
    private governanceLighthouseService: GovernanceLighthouseService
  ) {}

  ngOnInit() {
    console.log(this.data.uploadedFile.name);
    this.getCategories();
  }

  getCategories() {
    this.governanceLighthouseService
      .getAssessmentTemplateCategory()
      .subscribe((response) => {
        this.categories = response;
      });
  }

  getChildTags(data: any) {
    this.assessmentTags = data;
  }

  createAssessment() {
    let dialogData = this.templateForm.value;
    dialogData["selectedTags"] = this.assessmentTags;
    this.dialogRef.close(dialogData);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
