<div>
  <app-filter-card [pagetype]="pagetype" [queryParam]="params"></app-filter-card>
</div>
<div class="px-0 py-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
      <div class="single-left-pane">
          <app-side-menu></app-side-menu>
      </div>
  </div>
  <div class="col-sm-7">
      <div class="single-center-pane">
          <div class="d-flex mb-3" style="align-items: center; justify-content: space-between;">
              <div>
                  <h6 *ngIf="DealCount" class="m-0">Total Deals - <span class="number-count">{{DealCount}}</span></h6>
              </div>
              <!-- <div class="justify-content-end">
                <button mat-button (click)="add()"
                    class="bg-primary text-white" mat-button
                    mat-flat-button *ngxPermissionsOnly="['opportunity.add_opportunity']">
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                        class="cursor-pointer fs-5">add
                    </mat-icon>
                    New Deal
                </button>
            </div> -->
          </div>

          <app-loading *ngIf="!portFolioList"></app-loading>

          <div class="content-tree-holder mt-2" *ngIf="portFolioList">
              <app-tree-listing [items]="portFolioList" [tree]="program" [context]="context"></app-tree-listing>
          </div>
      </div>
  </div>
  <div class="col-sm-3">
      <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
          <app-gnews></app-gnews>
      </div>
  </div>
</div>