import { Component, OnInit } from '@angular/core';
import { MessageService } from "src/app/shared/services/message.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { PrismService } from 'src/app/shared/services/prism.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FilterService } from 'src/app/shared/services/filter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-technology-business-management-taxonomy',
  templateUrl: './technology-business-management-taxonomy.component.html',
  styleUrls: ['./technology-business-management-taxonomy.component.scss']
})
export class TechnologyBusinessManagementTaxonomyComponent implements OnInit {
  headerList:any =[{"name":'Cost Pool',"key":"cost_pool"},{"name":'Cost Sub-Pool',"key":"cost_sub_pool"},{"name":'Expense Type',"key":"expense_type"},{"name":'Description',"key":"description"}]
  costPool:any =[];
  itTower:any=[];
  serviceTaxonomy:any = [];
  solutionTaxonomy:any = [];
  allList:any = [];
  staticText: any = (textConfiguration as any).default;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  firstTimeLoad:boolean = true;
  tabIndex:number = 0;
  filterObj:any;
  pageType:any = 'taxonomy_costpool';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private prism : PrismService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
  ) {

   }

  ngOnInit(): void {
    this.messageService.setGnews("IPDB - technology-business-management-taxonomy");
    this.getCostPool();
    this.getItTower();
    this.getServiceTaxonomy();
    this.getSolutionTaxonomy();
    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        if(this.tabIndex == 0){
          this.getCostPool();
        }
        if(this.tabIndex == 1){
          this.getItTower();
        }
        if(this.tabIndex == 2){
          this.getServiceTaxonomy();
        }
        if(this.tabIndex == 3){
          this.getSolutionTaxonomy();
        }
      }
    })
  }

  async getCostPool(){
    this.costPool = [];
    let queryparam:string = '';
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "tcpCostPool":
              queryparam = queryparam+ '&cost_pool='+element;
              break;
            case "tcpCostSubPool":
              queryparam = queryparam+ '&cost_sub_pool='+element;
              break;
            case "tcpExpenseType":
              queryparam = queryparam+ '&expense_type='+element;
              break;
          }}
        )}
      )}
      await this.prism.getCostPool(this.startValue, this.EndValue, queryparam).subscribe((res:any)=>{
        this.costPool = res;
        this.allList = this.costPool;
      });
  }
  async getItTower(){
    this.itTower = [];
    let queryparam:string = '';
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "titTowerName":
              queryparam = queryparam+ '&it_resource_tower_name='+element;
              break;
            case "titSubTowerName":
              queryparam = queryparam+ '&it_resource_sub_tower_name='+element;
              break;
            case "titSubTowerElement":
              queryparam = queryparam+ '&it_sub_tower_element='+element;
              break;
            case "titDelivery":
              queryparam = queryparam+ '&delivery='+element;
              break;
            case "titUnitMeasure":
              queryparam = queryparam+ '&unit_of_measure='+element;
              break;
          }}
        )}
      )}
    await this.prism.getItTower(this.startValue, this.EndValue, queryparam).subscribe((res:any)=>{
      this.itTower = res;
      if(this.tabIndex == 1){
        this.allList = this.itTower;
      }
    });
  }
  async getServiceTaxonomy(){
    this.serviceTaxonomy = [];
    let queryparam:string = '';
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "tscServiceName":
              queryparam = queryparam+ '&service_name='+element;
              break;
            case "tscServiceType":
              queryparam = queryparam+ '&service_type='+element;
              break;
            case "tscServiceCategory":
              queryparam = queryparam+ '&service_category='+element;
              break;
          }}
        )}
      )}
    await this.prism.getServiceTaxonomy(this.startValue, this.EndValue,queryparam).subscribe((res:any)=>{
      this.serviceTaxonomy = res;
      if(this.tabIndex == 2){
        this.allList = this.serviceTaxonomy;
      }
    });
  }

 async getSolutionTaxonomy(){
    this.solutionTaxonomy = [];
    let queryparam:string = '';
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "tsnName":
              queryparam = queryparam+ '&name='+element;
              break;
            case "tsnSolutionType":
              queryparam = queryparam+ '&solution_type='+element;
              break;
            case "tsnCategory":
              queryparam = queryparam+ '&category='+element;
              break;
            case "tsnOffering":
              queryparam = queryparam+ '&offering='+element;
              break;
          }}
        )}
      )}
    await this.prism.getSolutionTaxonomy(this.startValue, this.EndValue,queryparam).subscribe((res:any)=>{
      this.solutionTaxonomy = res;
      if(this.tabIndex == 3){
        this.allList = this.solutionTaxonomy;
      }
    });
  }

  onTabClick(event:any){
    this.tabIndex = event.index;
    this.CurrentPage = 0;
    this.startValue = 0;
    this.EndValue =20;
    this.firstTimeLoad = true;
    if(this.tabIndex == 0){
      this.pageType = "taxonomy_costpool"
      this.allList = this.costPool;
      this.headerList=[{"name":'Cost Pool',"key":"cost_pool"},{"name":'Cost Sub-Pool',"key":"cost_sub_pool"},{"name":'Expense Type',"key":"expense_type"},{"name":'Description',"key":"description"}]
    }
    if(this.tabIndex == 1){
      this.pageType = "taxonomy_itTower"
      this.allList = this.itTower;
      this.headerList=[{"name":'Tower Name',"key":"it_resource_tower_name"},{"name":'Sub Tower Name',"key":"it_resource_sub_tower_name"},{"name":'Sub Tower Element',"key":"it_sub_tower_element"},{"name":'Description',"key":"description"},{"name":'Delivery',"key":"delivery"},{"name":'Unit of Mesure',"key":"unit_of_measure"},{"name":'Purpose',"key":"purpose"}]
    }
    if(this.tabIndex == 2){
      this.pageType = "taxonomy_serviceTaxonomy"
      this.allList = this.serviceTaxonomy;
      this.headerList=[{"name":'Service Name',"key":"service_name"},{"name":'Service Type',"key":"service_type"},{"name":'Service Category',"key":"service_category"},{"name":'Service Definition',"key":"service_definition"},{"name":'Sample Service Offerings',"key":"sample_service_offerings"}]
    }
    if(this.tabIndex == 3){
      this.pageType = "taxonomy_solutionTaxonomy"
      this.allList = this.solutionTaxonomy;
      this.headerList=[{"name":'Name',"key":"name"},{"name":'Solution Type',"key":"solution_type"},{"name":'Category',"key":"category"},{"name":'Offering',"key":"offering"}]
    }
  }

  getRecords(a:any) {
    
    if (this.CurrentPage > 0) {
      this.startValue = (parseInt(this.CurrentPage) - 1) * (parseInt(this.sortRecords));
    } else {
      this.startValue = 0
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if(a > 1){
      this.firstTimeLoad = false;
    }
      this.getData();
      
  }

  getData(){
    if(!this.firstTimeLoad){
      if(this.tabIndex == 0){
        this.getCostPool();
      }
      if(this.tabIndex == 1){
        this.getItTower();
      }
      if(this.tabIndex == 2){
        this.getServiceTaxonomy();
      }
      if(this.tabIndex == 3){
        this.getSolutionTaxonomy();
      }
    }
  }
  ngAfterViewInit():void{
    //this.firstTimeLoad = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
