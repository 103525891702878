import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-ipdb",
  templateUrl: "./ipdb.component.html",
  styleUrls: ["./ipdb.component.scss"],
})
export class IpdbComponent implements OnInit {
  ipdbList: any = [
    { name: "Delivery Obligations", url: "/toemasterlist" },
    { name: "Metrics Master", url: "/metrics-master" },
    // { name: "Linked Metrics Map", url: "" },
    { name: "Risk Register", url: "/riskconvergence/risk-register" },
    { name: "Plan Central Templates", url: "/confluence/teamplantemplates" },
    {
      name: "Assessment Central Templates",
      url: "strategicRelevance/assessment-central/assessment-templates",
    },
    {
      name: "Governance Lighhouse Templates",
      url: "strategicRelevance/governancect/assessment-templates",
    },
    { name: "People Competency Map", url: "prism/people-competency-matrics" },
    {
      name: "Technology Business Management Taxonomy",
      url: "prism/technology-business-management-taxonomy",
    },
    { name: "Maven", url: "/strategicRelevance/maven" },
    { name: "Survey Central", url: "/prism/ipdb/survey-central?type=ipdb" },
  ];
  loading: any = false;
  constructor(private router: Router, private messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.setGnews("IPDB - OKR");
  }

  navigateTo(url: any) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
