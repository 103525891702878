
<div class="px-0 pb-2">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane">
            <h6>IPDB</h6>
            <div class="list-items" *ngFor="let list of ipdbList" (click)="navigateTo(list?.url)">
                <span [class.text-primary] = list?.url>{{list?.name}}</span>
            </div>
            <div style="display:flex;position: absolute;bottom:0px;border-bottom-right-radius:8px;right:25px;font-size:10px;background-color: #fff;padding:0px 8px;">
                
                <span><mat-icon style="font-size:12px;width: 14px;">info</mat-icon> Contact your Kairhos expert for more information.</span>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <app-gnews></app-gnews>
            <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style="font-size: 13px">
                        Curated from Web
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-2">
                        <app-loading *ngIf="loading"></app-loading>
                        <app-gnews [display_header]="'false'"></app-gnews>
                    </div>
                </div>
            </div>     -->
        </div>
    </div>
</div>