import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { KeplerRoutingModule } from "./kepler-routing.module";
import { IndexComponent } from "./index/index.component";
import { ProgramComponent } from "./program/program.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { NgxPermissionsModule } from "ngx-permissions";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProposalComponent } from "./proposal/proposal.component";
import { NgxPaginationModule } from "ngx-pagination";
import { TermsEngagementComponent } from "./terms-engagement/terms-engagement.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { WebsiteComponent } from "./website/website.component";
import { HomeComponent } from "./home/home.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NotificationPageComponent } from "./notification-page/notification-page.component";
import { SearchPageComponent } from "./search-page/search-page.component";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { HackathonComponent } from "./hackathon/hackathon.component";
import { ItNorthStarComponent } from "./it-north-star/it-north-star.component";
import { MavenComponent } from "./maven/maven.component";
import { ContinuousImprovmentComponent } from "./continuous-improvment/continuous-improvment.component";
import { InteractiveReviewsComponent } from "./interactive-reviews/interactive-reviews.component";
import { TeamPlanTrackerComponent } from "./team-plan-tracker/team-plan-tracker.component";
import { HubbleComponent } from "./hubble/hubble.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  TurnkeyPreviewModalDialogComponent,
  TurnkeyTemplatesComponent,
} from "./turnkey-tracker/turnkey-templates/turnkey-templates.component";
import {
  PlanActivityModalComponent,
  PlanComponent,
  SaveAsTemplateModalComponent,
} from "./turnkey-tracker/plan/plan.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TurnkeyCreateModalDialogComponent } from "./turnkey-tracker/turnkey-templates/turnkey-create-modal/turnkey-create-modal";
import { NgPipesModule } from "ngx-pipes";
import { WhatIfComponent } from "./what-if/what-if.component";
import { CuratedInsightsComponent } from "./curated-insights/curated-insights.component";
import { WebCrawlComponent } from "./web-crawl/web-crawl.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AssessmentCentralComponent } from "./assessment-central/assessment-central/assessment-central.component";
import {
  AssessmentPreviewModalDialogComponent,
  AssessmentTemplatesComponent,
} from "./assessment-central/assessment-templates/assessment-templates.component";
import { AssessmentCreateModalDialogComponent } from "./assessment-central/assessment-templates/assessment-create-modal/assessment-create-modal";
import { AssessmentCreateTemplateModalDialogComponent } from "./assessment-central/assessment-template-create-modal/assessment-template-create-modal";
import { AssessmentAttendComponent } from "./assessment-central/assessment-attend/assessment-attend.component";
import { MatRadioModule } from "@angular/material/radio";
import { AssessmentResultComponent } from "./assessment-central/assessment-result/assessment-result.component";
import { IpdbComponent } from "./ipdb/ipdb.component";
import { SowmasterComponent } from "./sowmaster/sowmaster.component";
import { SubIndexComponent } from "./assessment-central/subindex/subindex.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatStepperModule } from "@angular/material/stepper";
import { PowerBIEmbedModule } from "powerbi-client-angular";
import { ChartModule } from "angular-highcharts";
import { HighchartsChartModule } from "highcharts-angular";
import { MetricListingPageComponent } from "./metric-listing-page/metric-listing-page.component";
import { MatTabsModule } from "@angular/material/tabs";
import { GovernanceSubIndexComponent } from "./governence-ct/governance-subindex/governance-subindex.component";
import {
  GovernanceAssessmentCentralComponent,
  GovernanceLighthouseImageDialog,
} from "./governence-ct/governance-assessment-central/governance-assessment-central.component";
import {
  GovernanceAssessmentPreviewModalDialogComponent,
  GovernanceAssessmentTemplatesComponent,
} from "./governence-ct/governance-assessment-templates/governance-assessment-templates.component";
import { GovernanceAssessmentCreateModalDialogComponent } from "./governence-ct/governance-assessment-templates/governance-assessment-create-modal/governance-assessment-create-modal";
import { GovernanceAssessmentCreateTemplateModalDialogComponent } from "./governence-ct/governance-assessment-template-create-modal/governance-assessment-template-create-modal";
import { GovernanceAssessmentAttendComponent } from "./governence-ct/governance-assessment-attend/governance-assessment-attend.component";
import { GovernanceAssessmentResultComponent } from "./governence-ct/governance-assessment-result/governance-assessment-result.component";
import { TunkeyCreateTemplateModalComponent } from "./turnkey-tracker/turnkey-templates/turnkey-create-template-modal/tunkey-create-template-modal/tunkey-create-template-modal.component";
import { MastersComponent } from "./masters/masters.component";
import { SurveyCentralComponent } from "./survey-central/survey-central.component";
import { CreateSurveyDialogComponent } from "./hubble/create-survey-dialog/create-survey-dialog.component";
import { SurveyEmbedDialogComponent } from "./hubble/survey-embed-dialog/survey-embed-dialog.component";
import { PeopleCompetencyMapComponent } from "./people-competency-map/people-competency-map.component";
import { TechnologyBusinessManagementTaxonomyComponent } from "./technology-business-management-taxonomy/technology-business-management-taxonomy.component";
import { OrganizationHierarchyComponent } from "./organization-hierarchy/organization-hierarchy.component";
import { CreatePulseDialogComponent } from "./hubble/create-pulse-dialog/create-pulse-dialog.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    IndexComponent,
    ProgramComponent,
    ProposalComponent,
    TermsEngagementComponent,
    WebsiteComponent,
    HomeComponent,
    NotificationPageComponent,
    SearchPageComponent,
    HackathonComponent,
    ItNorthStarComponent,
    MavenComponent,
    ContinuousImprovmentComponent,
    InteractiveReviewsComponent,
    TeamPlanTrackerComponent,
    HubbleComponent,
    TurnkeyTemplatesComponent,
    PlanComponent,
    PlanActivityModalComponent,
    SaveAsTemplateModalComponent,
    TurnkeyCreateModalDialogComponent,
    TurnkeyPreviewModalDialogComponent,
    WhatIfComponent,
    CuratedInsightsComponent,
    WebCrawlComponent,
    SubIndexComponent,
    AssessmentCentralComponent,
    AssessmentTemplatesComponent,
    AssessmentCreateModalDialogComponent,
    AssessmentPreviewModalDialogComponent,
    AssessmentCreateTemplateModalDialogComponent,
    AssessmentAttendComponent,
    AssessmentResultComponent,
    GovernanceSubIndexComponent,
    GovernanceAssessmentCentralComponent,
    GovernanceAssessmentTemplatesComponent,
    GovernanceAssessmentCreateModalDialogComponent,
    GovernanceAssessmentPreviewModalDialogComponent,
    GovernanceLighthouseImageDialog,
    GovernanceAssessmentCreateTemplateModalDialogComponent,
    GovernanceAssessmentAttendComponent,
    GovernanceAssessmentResultComponent,
    IpdbComponent,
    SowmasterComponent,
    MetricListingPageComponent,
    TunkeyCreateTemplateModalComponent,
    MastersComponent,
    PeopleCompetencyMapComponent,
    TechnologyBusinessManagementTaxonomyComponent,
    SurveyCentralComponent,
    CreateSurveyDialogComponent,
    SurveyEmbedDialogComponent,
    OrganizationHierarchyComponent,
    CreatePulseDialogComponent,
  ],
  imports: [
    CommonModule,
    KeplerRoutingModule,
    SharedModule,
    NgxDatatableModule,
    MatButtonModule,
    MatCardModule,
    NgxPermissionsModule.forChild(),
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbModule,
    PipeModule,
    MatChipsModule,
    MatProgressBarModule,
    DragDropModule,
    MatCheckboxModule,
    NgPipesModule,
    MatTooltipModule,
    MatRadioModule,
    MatExpansionModule,
    MatStepperModule,
    PowerBIEmbedModule,
    ChartModule,
    HighchartsChartModule,
    MatTabsModule,
    MatSlideToggleModule,
  ],
  exports: [ProgramComponent],
})
export class KeplerModule {}
