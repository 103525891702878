<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div
        class="d-flex align-items-center"
        role="button"
        (click)="navigateToPlanCentral()"
      >
        <mat-icon>keyboard_backspace</mat-icon>
        <strong class="ml-2">Go back</strong>
      </div>
      <div class="row">
        <div class="col-sm-4" style="display: flex; align-items: center">
          <h6 class="m-0">Plan Library</h6>
        </div>
        <div class="col-sm-8" style="display: flex; align-items: center">
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white mr-1"
            style="width: 100%"
            (click)="templateDownload()"
          >
            <mat-icon>cloud_download</mat-icon> &nbsp;
            <span>Download Template</span>
          </button>
          <input
            (change)="this.onFilechange($event)"
            class="form-control"
            type="file"
            #formFile
            hidden
          />
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white mr-1"
            style="width: 100%"
            (click)="formFile.click()"
          >
            <mat-icon>cloud_upload</mat-icon> &nbsp;
            <span>Upload Template</span>
          </button>
        </div>
      </div>

      <div class="mt-4">
        <div class="filter-container mb-2">
          <div class="filter-icon">
            <img
              height="25px"
              width="25px"
              src="./assets/images/filter-cp.png"
            />
          </div>
          <input
            type="text"
            placeholder="Search Category..."
            class="form-control text-input"
            [(ngModel)]="filterCategory"
            (ngModelChange)="search()"
            style="
              min-width: 13rem;
              border-radius: 8px;
              font-size: 12px;
              height: 40px;
            "
          />
          <input
            type="text"
            placeholder="Search Name..."
            class="form-control text-input"
            [(ngModel)]="filterName"
            (ngModelChange)="search()"
            style="
              min-width: 13rem;
              border-radius: 8px;
              font-size: 12px;
              height: 40px;
            "
          />
          <app-filter-card [pagetype]="'plan-central'"></app-filter-card>
        </div>
        <div class="accordion" id="accordionPanel">
          <div
            *ngFor="let category of filteredTemplate; let i = index"
            class="accordion-item"
          >
            <h2 class="accordion-header" id="heading{{ i }}">
              <button
                class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                type="button"
                data-bs-toggle="collapse"
                attr.data-bs-target="#collapse{{ i }}"
                aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                aria-controls="collapse{{ i }}"
                style="font-size: 13px; font-weight: 700"
              >
                {{ category.category }}
              </button>
            </h2>
            <div
              id="collapse{{ i }}"
              class="accordion-collapse collapse {{ i === 0 ? 'show' : '' }}"
              aria-labelledby="heading{{ i }}"
              data-bs-parent="#accordionPanel"
            >
              <div class="accordion-body p-2">
                <div class="flex-container-1">
                  <div
                    class="flex-item p-1"
                    *ngFor="let x of category.templates"
                    (click)="previewPlan(x)"
                    style="position: relative"
                  >
                    <div
                      style="
                        position: relative;
                        cursor: pointer;
                        padding: 10px;
                        height: 150px;
                        border-radius: 10px;
                        background-size: cover;
                        background-position: center;
                        overflow: hidden;
                        border: 0.5px solid grey;
                      "
                      [ngStyle]="{ background: x.color }"
                    >
                      <div>
                        <div *ngIf="x.phases.length > 0">
                          <span *ngFor="let y of x.phases" class="y-dqws">
                            <span class="y-name">{{ y.name }}</span>
                            <span *ngFor="let z of y.activities" class="z-sqw">
                              <span>{{ z.name }}</span>
                              <span></span>
                              <span></span>
                            </span>
                          </span>
                        </div>
                        <div *ngIf="x.phases.length == 0">
                          <span
                            *ngFor="let y of [].constructor(3)"
                            class="y-dqws"
                          >
                            <span class="y-name">&nbsp;</span>
                            <span
                              *ngFor="let z of [].constructor(2)"
                              class="z-sqw"
                            >
                              <span>&nbsp;</span>
                              <span></span>
                              <span></span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="overlayer"></div>
                      <div
                        style="
                          position: absolute;
                          width: 100%;
                          background: #303775;
                          padding: 10px;
                          border-bottom-left-radius: 10px;
                          border-bottom-right-radius: 10px;
                          bottom: 0;
                          left: 0;
                          height: 50px;
                          font-size: 10px;
                          align-items: center;
                          display: flex;
                          justify-content: center;
                        "
                      >
                        <h6
                          class="mb-0"
                          style="
                            font-weight: 800;
                            font-size: 12px;
                            color: white;
                            text-align: center;
                            height: 43px;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                          "
                        >
                          {{ x.name }}
                        </h6>
                      </div>
                    </div>

                    <button
                      mat-icon-button
                      class="action-vert"
                      color="warn"
                      (click)="deleteTemplate(x.id); $event.stopPropagation()"
                    >
                      <mat-icon style="font-size: 15px; color: red"
                        >delete</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
      <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style="font-size: 13px">
                        Curated from Web
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-2">
                        <app-loading *ngIf="loading"></app-loading>
                        <app-gnews [display_header]="'false'"></app-gnews>
                    </div>
                </div>
            </div>    -->
    </div>
  </div>
</div>
