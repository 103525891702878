<app-modal-header-common title="Governance Lighthouse">
</app-modal-header-common>
<div style="padding: 20px; font-size: 13px">
  <div style="height: 60vh; overflow: scroll">
    <img
      src="assets/images/governance-lighthouse.png"
      height="100%"
      width="100%"
      alt="governance-lighthouse"
    />
  </div>
  <div style="padding: 10px; display: flex; justify-content: right">
    <button
      mat-flat-button
      color="primary"
      (click)="closeModal(true)"
      style="width: auto"
    >
      Close
    </button>
  </div>
</div>
