import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as moment from 'moment';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxPermissionsService } from 'ngx-permissions';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe, Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { FilterService } from 'src/app/shared/services/filter.service';
import { isArray } from 'lodash';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-terms-engagement',
  templateUrl: './terms-engagement.component.html',
  styleUrls: ['./terms-engagement.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class TermsEngagementComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  toeList: any[] = [];
  toeListTotal: any[] = [];
  limit: any;
  offset: any;
  totalCount: any;
  searchText = '';
  search = false;
  filterForm: FormGroup | any;
  filterOptionPortfolio1: Observable<any[]> | any;
  filterOptionSubPortfolio1: Observable<any[]> | any;
  portList: any[] = [];
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  subPortId: any;
  page: number = 1;
  endVal: number = 10;
  startVal: number = 0;
  itemsPerPage: number = 10;
  subPortChildOptions: any[] = [];
  OpportunityFieldList: any;
  initiatedDate: any;
  selectedId: any;
  statusList: any[] = [];
  toeStatus: any[] = [];
  toeStatusValue: any;
  statusId: any;
  statusFilter: any;
  scree_name = "toe";
  disableShowMore: boolean = false;

  public context;

  DealCount: number = 0;
  portFolioList: any = [];

  pagetype = 'opportunity';
  filterObj: any;
  params: any;
  portFolioListUnFiltered: any;
  archiveListData: any;

  constructor(
    private proposalService: GetProposalService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private csvService: DataToCsvService,
    private permissionService: NgxPermissionsService,private datePipe:DatePipe,
    private filterService: FilterService,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private ds : DealStructureService,
    private messageService: MessageService
  ) { 
    this.route.queryParams.subscribe(async params => {
      this.params = params;
      this.location.replaceState(this.location.path().split('?')[0], '');
    });

    this.context = {
      componentParent: this,
    }; 
  }

  ngOnInit(): void {
    this.messageService.setGnews('Deals listing')
    const perm = JSON.parse(localStorage.getItem('permission') || '{}');
    this.permissionService.loadPermissions(perm.permissions);
    this.limit = 10;
    this.offset = 0;
     this.getOpportunity();
    // this.getDataSource();
    this.createForm(); 
    this.filterService.filterSubject.subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element] != ""){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        awl ? this.getFilteredList() : this.getPortfolioList();
      }else{
        this.getPortfolioList();
      }
    })
  }

  getDataSource(isTotal:boolean=false) {
    this.spinner.show();
    let endVal:any = isTotal ? this.totalCount : this.endVal;
    this.ps.getToelist(this.startVal, endVal, this.subPortId, this.searchText, this.portFolioId, this.statusId).subscribe((res: any) => {
      this.spinner.hide();
      let list = res.records;
      this.totalCount = res.totalRecords;
      list = list.map((i: any) => {
        this.toeStatus.forEach((element: any) => {
          if (element.action_complete_label == i.status.action_complete_label) {
            i.updatedStatus = element;
          }
        });
        return i;
      })
      if(isTotal){
        this.toeListTotal = list;
        this.saveAsCSV();
      }else{
        this.toeList = list;
      }
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  getOpportunity() {
    this.spinner.show();
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })
      this.createForm();
      this.spinner.hide();
    }, error => {
      throw error;
    })
  }
  onDateChange(event: any, i: any) {
    this.selectedId = i.id;
    this.initiatedDate = event.value ? moment(event.value).format('yyyy-MM-DD') : '';
    this.updateInnitiatedate();

  }

  updateInnitiatedate() {
    const payload = {
      initiation_date: this.initiatedDate,
      opportunity: this.selectedId
    }
    this.ps.updateInnitiatedate(payload).subscribe((res: any) => {
    }, (error: any) => {
      throw error;
    })

  }
  selectToeStatus(e: any, i: any) {
    this.selectedId = i.id;
    this.toeStatusValue = e.value.slug;
    this.updateToeStatus();
  }


  updateToeStatus() {
    const payload = {
      opportunity: this.selectedId,
      status: this.toeStatusValue
    }
    this.ps.updateToeStatus(payload).subscribe((res: any) => {
    }, (error: any) => {
      throw error;
    })
  }


  clearFilter() {
    this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    this.statusId = 0;
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
      status: ''
    })
    this.getDataSource();
  }

  deleteProposal(id: any) {
    this.proposalService.deleteProposal(id).subscribe((res: any) => {
      this.getDataSource();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.toastrService.error('Error');
      throw error;
    })
  }
  editProposal(id: any) {
    this.router.navigate([`rfx/`+btoa(id)+ `/opportunity/setup`]);
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
      status: ['']
    });

    this.filterOptionPortfolio1 = this.filterForm.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
      );
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }
  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortfilteredOptions = [];
    this.subPortId = "";
    
    this.filterForm.patchValue({
      subPortfolio: ''
    })
    let selectedPortfolio = this.portList.find((e:any)=> e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
     if (this.portFolioId ==="") {
      this.subPortfilteredOptions = [];
      this.filterOptionSubPortfolio1 = this.filterForm.get('subPortfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((display_name: any) => display_name ? this.subPortfolioFilter(display_name) : this.subPortfilteredOptions.slice())
        );
     }
     this.getDataSource();
  }

  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    //  this.showAllProposals();
    this.getDataSource();
  }


  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  changeSubPortfolio(e: any) {
    if (e.isUserInput) {
      this.subPortId = e.source.value.id;
      this.getDataSource();
    }
  }

  viewProposal(id: any) {
    this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`])
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getDataSource();
  }

  searchName() {
    this.getDataSource();
  }

  onInputChange(e: any) {
    if (this.searchText == '') {
      this.getDataSource();
    }
  }

  statusChange(e: any) {

    this.statusId = e;
    this.getDataSource();
    this.showAllProposals();
  }

  goToTerms(i: any) {
    this.router.navigate([`toe/${btoa(i.id)}/delivery-approval`]);
  }

  showAllProposals() {
    this.startVal = 0;
    this.endVal = this.toeList?.length + (this.itemsPerPage);
    this.getDataSource();
    if (this.totalCount < this.endVal) {
      this.disableShowMore = true;
    }
  }

  callRefereshParentData(e: any) {
    this.getDataSource()
  }
  
  saveAsCSV() {
    if(this.toeListTotal && this.toeListTotal.length > 0){
      const items:any = [];
      this.toeListTotal.forEach((item:any) => {
        let csvData = {
          "Opportunity Number": item.opportunity_number,
          "Opportunity Name": item.opportunity_name,
          "Portfolio Name": item?.portfolio_details?.[0]?.display_name,
          "Sub Portfolio Name": item?.portfolio_details?.[1]?.display_name,
          "Deal Initiated by": item?.initiated_by?.full_name,  
          "Deal Initiated on": this.datePipe.transform(item?.initiation_date,'dd MMM yyyy'),
          "Updated on" :this.datePipe.transform(item?.last_updated_on,'dd MMM yyyy'),
          "Deal Status":item.status?.action_complete_label
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Deals_List.CSV', items);
    }else{
      this.toastrService.error('No Records to download');
    }
  }

  async getFilteredList(){
    if(this.filterObj.portfolio.array.length > 0){
      this.ps.getDealPortfolioList().then((res: any) => {
        this.portFolioList = res.records.length > 0 ? res.records : [];
        this.portFolioListUnFiltered = [...this.portFolioList];
        this.DealCount = res.total_projects_count;

        if(this.filterObj.portfolio.array.length > 0){
          this.portFolioList = this.portFolioList.filter((el: any) => {
            return this.filterObj.portfolio.array.some((f: any) => {
              return f.id === el.id;
            });
          });
        }
      })
    }else if(this.filterObj.subportfolio.array.length > 0){
      delete this.portFolioList;
      let bodyParam: any = {
        portfolio: []
      }
      this.filterObj.subportfolio.array.forEach((element: any) => {
        bodyParam.portfolio.push(element.id)
      });
      let queryParam = `page=deal`;
      await this.ps.getFilteredList(queryParam, bodyParam).then((res: any) => {
        this.portFolioList = [];
        res.forEach((ea: any) => {
          this.portFolioList.push(ea.portfolio)
        });
        this.portFolioList.forEach((port: any) => {
          port.children = port.subportfolio;
          port.children.forEach((sub: any) => {
            sub.children = sub.program;
            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
            sub.children.forEach((program: any) => {
              program.level = 'program';
                program.parent = {
                  id: sub.id,
                  level: 'sub_portfolio',
                  parent: {
                    id: port.id,
                    level: 'portfolio'
                  }
                }
            });
          });
        });
      })
    }else if(this.filterObj.program.array.length > 0){
      delete this.portFolioList;
      let bodyParam:any = {
        program: []
      }
      this.filterObj.program.array.forEach((element: any) => {
        bodyParam.program.push(element.id)
      });
      let queryParam = `page=deal`;
      await this.ps.getFilteredList(queryParam, bodyParam).then((res: any) => {
        this.portFolioList = [];
        res.forEach((es: any) => {
          this.portFolioList.push(es.portfolio);
        });

        this.portFolioList.forEach((port: any) => {
          port.children = port.subportfolio;
          port.children.forEach((sub: any) => {
            sub.children = sub.program;
            sub.children.forEach((program: any) => {
              program.level = 'program';
              program.parent = {
                id: sub.id,
                level: 'sub_portfolio',
                parent: {
                  id: port.id,
                  level: 'portfolio'
                }
              }
            });

            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
          });
        });
      })
    }else if(this.filterObj.opportunity.array.length > 0){
      delete this.portFolioList;
      let bodyParam: any = {
        opportunity: []
      }
      this.filterObj.opportunity.array.forEach((element: any) => {
        bodyParam.opportunity.push(element.id)
      });
      let queryParam = `page=deal`;
      await this.ps.getFilteredList(queryParam, bodyParam).then((res: any) => {
        this.portFolioList = [];
        res.forEach((es: any) => {
          this.portFolioList.push(es.portfolio);
        });

        this.portFolioList.forEach((port: any) => {
          port.children = port.subportfolio;
          port.children.forEach((sub: any) => {
            sub.children = sub.program;
            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
            sub.children.forEach((program: any) => {
              program.level = 'program';
              program.children = program.opportunity
              program.parent = {
                id: sub.id,
                level: 'sub_portfolio',
                parent: {
                  id: port.id,
                  level: 'portfolio'
                }
              }

              program.children.forEach((opp: any) => {
                opp.level = 'last';
              });
            });
          });
        });
      })
    }else if(this.filterObj.vendor.array.length > 0){
      delete this.portFolioList;
      let bodyParam: any = {
        vendor: []
      }
      this.filterObj.vendor.array.forEach((element: any) => {
        bodyParam.vendor.push(element.id)
      });
      let queryParam = `page=deal`;
      await this.ps.getFilteredList(queryParam,bodyParam).then((res: any) => {
        this.portFolioList = [];
        res.forEach((es: any) => {
          this.portFolioList.push(es.portfolio);
        });

        this.portFolioList.forEach((port: any) => {
          port.children = port.subportfolio;
          port.children.forEach((sub: any) => {
            sub.children = sub.program;
            sub.children.forEach((program: any) => {
              program.level = 'program';
              program.parent = {
                id: sub.id,
                level: 'sub_portfolio',
                parent: {
                  id: port.id,
                  level: 'portfolio'
                }
              }
            });

            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
          });
        });
      })
    }else if(this.filterObj.tag.array.length > 0){
      delete this.portFolioList;
      let bodyParam: any = {
        'tags': []
      }
      this.filterObj.tag.array.forEach((element: any) => {
        bodyParam.tags.push(element.id)
      });
      let queryParam = `page=deal`;
      await this.ps.getFilteredList(queryParam, bodyParam).then((res: any) => {
        this.portFolioList = [];
        res.forEach((es: any) => {
          this.portFolioList.push(es.portfolio);
        });

        this.portFolioList.forEach((port: any) => {
          port.children = port.subportfolio;
          port.children.forEach((sub: any) => {
            sub.children = sub.program;
            sub.children.forEach((program: any) => {
              program.level = 'program';
              program.parent = {
                id: sub.id,
                level: 'sub_portfolio',
                parent: {
                  id: port.id,
                  level: 'portfolio'
                }
              }
            });

            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
          });
        });
      })
    }else{
      this.portFolioList = [...this.portFolioListUnFiltered];

      if(this.filterObj.portfolio.length > 0){
        this.filterObj.portfolio.forEach((element: any) => {
          this.portFolioList = this.portFolioList.filter((e: any) => e.id == element.id);
        });
      }
    }
  }

  getPortfolioList(){
    delete this.portFolioList;
    if(!this.portFolioListUnFiltered){
      let portFolioListData:any = [];
      this.ps.getDealPortfolioList().then((res: any) => {
        res.records.forEach((element:any) => {
          element.opportunity_count = element.opportunity_count
          portFolioListData.push(element)
        });
         this.portFolioList = portFolioListData;
        this.portFolioListUnFiltered = [...this.portFolioList];
        this.DealCount = res.total_opportunity_count;
        return res.records;
      })
    }else{
      this.portFolioList = [...this.portFolioListUnFiltered];
    }
    
  }

  async getLevel(item: any = null){
    if(item.children){
      return
    }
    let response: any;
    let queryParams = '&page=deal';
    if(item.level == 'portfolio'){
      response = await this.ps.getSubPortfolioList(item, queryParams).then((res: any) => {
        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.opportunity_count = element.opportunity_count;
            element.parent = res.portfolio_details[0]
          });
        }
        this.portFolioList[this.portFolioList.indexOf(item)].children = res.records;
        return res;
      })
    }else if(item.level == 'sub_portfolio'){
      response = await this.ds.getPortfolioPrograms(item, queryParams).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.id);
        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.opportunity_count = element.opportunity_count;
            element.level = 'program';
            element.noArchiveFlag = true;
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex].id,
              level: 'sub_portfolio',
              parent: {
                id: this.portFolioList[portfolioIndex].id,
                level: 'portfolio'
              }
            }
          });
        }

        this.portFolioList[portfolioIndex].children[subportfolioIndex].children = res.records;
        return res;
      })
    }else if(item.level == 'program'){
      response = await this.ds.getDealStructureList(item, queryParams).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
        let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.level = 'last';
            element.noArchiveFlag = true;
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].id,
              level: "program",
              parent : {
                id: this.portFolioList[portfolioIndex].children[subportfolioIndex].id,
                level: 'sub_portfolio',
                parent: {
                  id: this.portFolioList[portfolioIndex].id,
                  level: 'portfolio'
                }
              }
            }
            
          });
        }

        this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children = res.records;

        return res;
      })
    }
    return response;
  }

  collapse(item: any){
    if(item.level == 'portfolio'){
      this.portFolioList.forEach((element: any) => {
        if(element.id != item.id) element.expanded = false;
      });
    }else if(item.level == 'sub_portfolio'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }else if(item.level == 'program'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children[subportfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children[subportfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }
  }


  deleteItem(item: any) {
    this.spinner.show();
    this.ds.deleteDealStructureById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let opportunityIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(opportunityIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveItem(item: any) {
    this.spinner.show();
    this.ds.archiveDealStructureById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let opportunityIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(opportunityIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Archived Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveList(item: any){
    this.spinner.show();
    this.archiveListData = [];
    this.ds.getArchiveList(item.parent.parent.id, item.parent.id, item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

      this.spinner.hide();
      this.archiveListData = res;
      this.archiveListData.forEach((element: any) => {
        element.selected = false;
      });
      
      if(this.archiveListData.length > 0){
        const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
        dialog.componentInstance.type = 'ArchiveList';
        dialog.componentInstance.typeName = 'Deal';
        dialog.componentInstance.archiveList = this.archiveListData;
  
        dialog.afterClosed().subscribe(val => {
          let payload: any = {
            opportunity: []
          };
          val.forEach((element: any) => {
            if(element.selected){
              payload.opportunity.push(element.id);
            }
          });
          this.spinner.show();
          if(payload.opportunity.length > 0){
            this.ps.unarchiveOppo(payload).then((res: any) => {
              this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].expanded = false;
                delete this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children;
                this.toastrService.success('Unarchived successfully');
                this.spinner.hide();
            })
          }
        })
      }else{
        this.toastrService.warning('No data to archive');
      }
      
      
      
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  navigateTo(item: any){
    // this.router.navigate([`deal/`+btoa(item.id)+`/setup`]);
    if(item.status.label =='Published' || item?.status?.label == 'SoW Signed'){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/proposal-evaluation`]);
    }
    else if(item.status.label =='Evaluating' ){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/summary`]);
    }else{
      this.router.navigate([`rfx/${btoa(item.id)}/opportunity/setup`]);
    }
  }

  onSelectOpportunity(evt:any){
    this.searchText = evt;
    this.getDataSource();
  }

//========================================================Arun 20/11/2023======================================================
  add() {
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px' });
    dialog.componentInstance.type = 'AddDeal';
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
  }

}

