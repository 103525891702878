import { Component, OnInit } from "@angular/core";
import * as textConfiguration from "src/assets/static-text-configuration.json";

@Component({
  selector: "app-governance-subindex",
  templateUrl: "./governance-subindex.component.html",
  styleUrls: ["./governance-subindex.component.scss"],
})
export class GovernanceSubIndexComponent implements OnInit {
  currentYear: any;
  loading: any = false;
  staticText: any = (textConfiguration as any).default;
  constructor() {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
