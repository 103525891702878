import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { PrismService } from "src/app/shared/services/prism.service";
import { TagsService } from "src/app/shared/services/tag.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Subject } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { fileNameValidator } from "src/app/shared/helpers/validator";

@Component({
  selector: "app-curated-insights",
  templateUrl: "./curated-insights.component.html",
  styleUrls: ["./curated-insights.component.scss"],
})
export class CuratedInsightsComponent implements OnInit {
  fileAttached: any = [];
  curatedInsightsForm: FormGroup | any;
  aiPoweredInsights: any = {
    createdByYou: [],
    requestedByYou: [],
    requestedFromYou: [],
  };
  curatedInsights: any = {
    createdByYou: [],
    requestedByYou: [],
    requestedFromYou: [],
  };
  cardDetails: any;
  updateInsightId: any;
  teamList: any;
  insightType: any;
  curatedTags: any;
  subscription: any;
  filterObj: any;
  tagFilterId: any = [];
  titleFilterText: any = "";
  linkURL: any;
  loading: any = false;
  activeTab: any = 0;
  mavenCategory: any;
  mavenCategoryMap: any = {};
  insightDocument: any;
  insightDocumentError: any = false;
  pdfSrc: any;
  mlcaseList: any;
  @ViewChild("NewCuratedInsights") NewCuratedInsights: ElementRef;
  editInsightFlag: any = true;

  constructor(
    private spinner: NgxSpinnerService,
    private prism: PrismService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private tagService: TagsService,
    private filterService: FilterService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.getMetadata();
    this.messageService.setGnews("Maven");
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        this.filterObj?.tag?.array?.forEach((tag: any) => {
          this.tagFilterId.push(tag?.id);
        });
        this.titleFilterText = this.filterObj?.mavenTitle?.input || "";
        if (this.titleFilterText || this.tagFilterId?.length > 0) {
          this.getCuratedInsights();
        }
      }
    );
    this.getCuratedInsights();
    this.getMLCase();
    this.curatedInsightsForm = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      category_id: ["", Validators.required],
      assignees: [""],
      type: [""],
      links: this.fb.array([]),
    });
  }
  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getMLCase() {
    this.prism.getMLCase().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });
      this.mlcaseList = res;
    });
  }
  getCuratedInsights() {
    if (!this.titleFilterText && this.tagFilterId?.length == 0) {
      this.spinner.show();
    }
    this.prism
      .getCuratedInsightsList(this.tagFilterId, this.titleFilterText)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.aiPoweredInsights = {
          createdByYou: [],
          requestedByYou: [],
          requestedFromYou: [],
        };
        this.curatedInsights = {
          createdByYou: [],
          requestedByYou: [],
          requestedFromYou: [],
        };
        res.forEach((element: any) => {
          if (element.display_name == "AI Powered Insights") {
            element.curated_insights.forEach((insight: any) => {
              insight.color = this.getGMapRandomColor();
              if (insight.type == "insight") {
                this.aiPoweredInsights.createdByYou.push(insight);
              } else {
                this.aiPoweredInsights.requestedByYou.push(insight);
              }
            });
          } else if (element.display_name == "Curated Perspectives") {
            element.curated_insights.forEach((insight: any) => {
              insight.color = this.getGMapRandomColor();
              if (insight.type == "insight") {
                this.curatedInsights.createdByYou.push(insight);
              } else {
                this.curatedInsights.requestedByYou.push(insight);
              }
            });
          }
        });
      });
  }

  getMetadata() {
    this.prism.getInsightCategory().subscribe((res: any) => {
      this.mavenCategory = res;
      res.reduce((acc: any, curr: any) => {
        this.mavenCategoryMap[curr.id] = curr.name;
        return acc;
      });
    });
  }

  onClickAddCuratedInsights(modal: any, type: any) {
    this.editInsightFlag = false;
    if (type == "insight") {
      this.dialog.open(modal, {
        minWidth: "500px",
        data: { mode: "add" },
      });
    } else {
      this.dialog.open(modal, {
        minWidth: "500px",
        data: { mode: "add" },
      });
    }
    this.insightType = type;
    this.updateInsightId = "";
    this.curatedInsightsForm.reset();
    this.fileAttached = [];
  }

  onFileSelected(e: any) {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error("Please upload less than 5MB");
        return;
      }
      if (fileNameValidator.test(file.name)) {
        this.toastrService.error(
          "File name contains special characters. Please remove them and try again."
        );
        return;
      }
      if (file) {
        this.fileAttached.push(file);
      }
    }
  }

  uploadDocument(file: any, id: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("insight_id", id);
    this.prism.uploadCuratedInsightDocument(formData).subscribe((res: any) => {
      this.getCuratedInsights();
    });
  }

  async getUserList() {
    return await this.prism.getUser().subscribe((res: any) => {
      this.teamList = res;
    });
  }

  DeleteFile(i: any, fileId: any) {
    if (fileId) {
      this.spinner.show();
      this.prism.deleteInsightFile(fileId).subscribe((res: any) => {
        this.spinner.hide();
      });
    }
    this.fileAttached.splice(i, 1);
  }
  getType(fileName: any) {
    const ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    return ext?.toLowerCase();
  }
  cancel() {
    this.dialog.closeAll();
  }
  saveCuratedInsight() {
    if (this.insightType != "insight") {
      this.dialog.closeAll();
      this.onClickAddCuratedInsights(this.NewCuratedInsights, "insight");
      return;
    }
    this.curatedInsightsForm.markAllAsTouched();
    if (this.curatedInsightsForm.invalid) {
      return;
    }
    this.spinner.show();
    this.curatedInsightsForm.value.type = this.insightType;
    if (this.insightType == "request") {
      delete this.curatedInsightsForm.value.assignees;
    }
    if (this.updateInsightId) {
      this.prism
        .editCuratedInsights(
          this.curatedInsightsForm.value,
          this.updateInsightId
        )
        .subscribe((res: any) => {
          this.spinner.hide();
          this.fileAttached.forEach((element: any) => {
            this.uploadDocument(element, res.id);
          });
          this.toastrService.success("Curated Insight updated successfully.");
          this.addTags(this.updateInsightId);
          if (!this.fileAttached) {
            this.getCuratedInsights();
          }
          this.dialog.closeAll();
        });
    } else {
      this.prism
        .saveCuratedInsights(this.curatedInsightsForm.value)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.fileAttached.forEach((element: any) => {
            this.uploadDocument(element, res.id);
          });
          this.addTags(res.id);
          this.toastrService.success("Curated Insight created successfully.");
          this.dialog.closeAll();
          if (!this.fileAttached) {
            this.getCuratedInsights();
          }
        });
    }
  }

  requestInsight() {
    let payload = { document_name: this.curatedInsightsForm.value.title };
    this.spinner.show();
    this.prism.requestInsight(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success(res?.message);
    });
  }

  editInsight(modal: any, insight: any, type: any) {
    this.editInsightFlag = true;
    console.log(insight.title);
    this.curatedInsightsForm = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      assignees: [""],
      type: [""],
      category_id: ["", Validators.required],
      links: this.fb.array([]),
    });
    if (type == "insight") {
      this.getDocument(insight?.documents?.[0]?.document, insight.id);
      // this.insightDocument = this.sanitizer.bypassSecurityTrustResourceUrl('');
      const dr = this.dialog.open(modal, {
        minWidth: "1100px",
        data: { mode: "edit" },
      });
      dr.afterClosed().subscribe((res: any) => {
        this.insightDocument = res;
        this.insightDocumentError = false;
      });
    } else {
      const dr = this.dialog.open(modal, {
        width: "auto",
        minWidth: "500px",
        data: { mode: "request" },
      });
      dr.afterClosed().subscribe((res: any) => {
        this.insightDocument = res;
        this.insightDocumentError = false;
      });
    }
    this.insightType = type;
    this.updateInsightId = insight.id;
    this.curatedInsightsForm.setValue({
      title: insight.title,
      description: insight.description,
      assignees: insight.assignees,
      type: insight.type,
      category_id: insight.category,
      links: [],
    });

    insight?.links.forEach((link: any) => {
      this.linksControls.push(this.createLinksControl(link));
    });

    this.curatedTags = insight?.tag_ids;
    this.fileAttached = insight.documents;
  }

  get linksControls() {
    return this.curatedInsightsForm.get("links") as FormArray;
  }

  createLinksControl(link: any): FormControl {
    return this.fb.control(link);
  }

  removeLink(i: any) {
    this.curatedInsightsForm.value.links.splice(i, 1);
  }

  cardDetailsShow(insight: any) {
    this.cardDetails = insight;
  }
  downloadDocument(x: any) {
    this.spinner.show();
    this.prism.downloadInsightFile(x.id).subscribe(
      (res: any) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", x.document);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  deleteInsight(x: any, event: any) {
    event.stopPropagation();
    this.spinner.show();
    this.prism.deleteInsight(x.id).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success("Insight deleted");
      this.getCuratedInsights();
    });
  }

  getChildTags(data: any) {
    this.curatedTags = data;
  }
  async addTags(id: any) {
    if (this.curatedTags?.length > 0) {
      await this.curatedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "curated_insight",
          tag_id: element.id ? element.id : element,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  addLink() {
    this.curatedInsightsForm.value.links.push(this.linkURL);
    this.linkURL = "";
  }

  getTrucatedName(name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > 45) {
        return name.substring(0, 45 - 3) + "...";
      } else {
        return name;
      }
    }
  }

  changeTab(event: any) {
    this.activeTab = event.index;
  }

  getDocument(docName: any, insightId: any) {
    this.prism.getInsightDocument(docName, insightId).subscribe(
      (res: Blob) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.insightDocument = new Uint8Array(
            fileReader.result as ArrayBuffer
          );
        };
        fileReader.readAsArrayBuffer(res);
      },
      (error: any) => {
        this.insightDocumentError = true;
        this.toastrService.error("Unable to load the document");
      }
    );
  }
  selectedMl: any;
  mlCasePopUp(item: any, modal: any) {
    this.selectedMl = item;
    this.dialog.open(modal, {
      width: "50vw",
    });
  }
}
