<div class="notification">
    <header class="notification-Header">
        <div class="w-100 bg-lavendar mt-80">
            <app-header></app-header>
        </div>
    </header>

    <div class="notification-body container-fluid container-xxl">
        <div class="notificationContainer container p-4">
            <div class="notification-card">
                <div class="d-flex flex-column">
                    <div class="notification-head d-flex flex-column border-bottom text-capitalize ">
                        <div class="d-flex flex-row justify-content-between align-items-center p-2">
                            <div class="p-1">
                                <!-- <p class="text-muted fs-14 mb-2">innovation</p> -->
                                <h5 class="fs-24 fw-500">{{staticText?.home?.notifications}}</h5>
                            </div>
                            <div class="p-1">
                                <button type="button"
                                    class="btn btn-outline-danger notificationListStatus text-capitalize cursor-pointer fs-16 h-100"
                                    (click)="getAllNotificaitonMessage(notificationListStatus)">
                                    {{'show&nbsp;'+ notificationListStatus + '&nbsp;notifications'}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="notification-List" *ngIf="notficationsList?.length">
                        <div class="d-flex flex-column pt-2 pb-2"
                            *ngFor="let notification of notficationsList;index as notificationIndex"
                            [routerLink]="[notification?.routepath]">
                            <div class="notification-day p-2">
                                <h6 class="fs-14 fw-medium text-capitalize text-muted">{{notification?.posted_at |
                                    date:'EEEE'}}</h6>
                            </div>
                            <div class="p-2">
                                <div class="received-notification-card p-2 cursor-pointer"
                                    (click)="MarkAsReadNotification(notification?.id)">
                                    <div class="d-flex flex-row justify-content-start align-items-start">
                                        <div class="pe-2">
                                            <ngx-avatar [name]="notification?.posted_by_details?.full_name || notification?.posted_by_details?.username"
                                                [bgColor]="(notificationIndex|getRandomColor)" size="38" [round]="true">
                                            </ngx-avatar>
                                        </div>
                                        <div class="ps-2 d-flex flex-column">
                                            <p class="fs-14 fw-500 text-capitalize mb-1">
                                                <!-- <span class="color-gray">Richa Vyas</span> -->
                                                <span>{{notification?.purpose}}</span>
                                            </p>
                                            <p class="text-muted fs-12 mb-0">{{notification?.posted_at | dateAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- no data found -->
                    <ng-container *ngIf="!notficationsList?.length">
                        <div class="row">
                            <div class="col-12 mx-auto">
                                <img src="./assets/images/no-record.png" width="100%" class="noRecords">
                            </div>
                            <div class="col-8 mx-auto">
                                <p class="text-center fw-bold text-muted">
                                    {{staticText.common.no_records_found}}
                                </p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <footer class="notification-Footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-muted">
                        <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>