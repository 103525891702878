<app-modal-header-common [title]="'Preview Template'">
</app-modal-header-common>
<div style="padding: 20px; font-size: 13px">
  <div *ngIf="template">
    <div>
      <div class="row">
        <div class="col-sm-6">
          <h6 style="display: flex" class="mb-2">
            {{template.name}}
            <app-owner [ownerdata]="" [editable]="false"></app-owner>
          </h6>
        </div>
      </div>
    </div>
    <div>
      <p
        class="m-0 mb-2"
        style="
          display: flex;
          font-size: 12px;
          color: #7e7878;
          align-items: center;
        "
      >
        <span style="display: flex"
          >Start Date:
          <div style="width: 75px; position: relative">
            <input
              matInput
              [(ngModel)]="template.start_date"
              style="
                text-align: center;
                width: 75px;
                font-weight: 700;
                color: #7e7878;
                border: none;
                outline: none;
                cursor: pointer;
              "
            />
            <button
              class="small-icon-button"
              mat-icon-button
              style="
                position: absolute;
                left: 15px;
                top: -5px;
                transform: translateX(-50%);
              "
            >
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </span>
        <span style="display: flex; margin: 0 8px"
          >End Date:
          <div style="width: 75px; position: relative">
            <input
              matInput
              [(ngModel)]="template.end_date"
              style="
                text-align: center;
                width: 75px;
                font-weight: 700;
                color: #7e7878;
                border: none;
                outline: none;
                cursor: pointer;
              "
            />
            <button
              class="small-icon-button"
              mat-icon-button
              style="
                position: absolute;
                left: 15px;
                top: -5px;
                transform: translateX(-50%);
              "
            >
              <mat-icon>calendar_month</mat-icon>
            </button>
          </div>
        </span>
        <span
          ><app-workgroup
            [showCount]="2"
            [showLabel]="true"
            [editable]="false"
          ></app-workgroup
        ></span>
      </p>
      <app-tag [dataObj]="getTagObject()"></app-tag>
    </div>
    <div
      style="
        margin-top: 15px;
        padding: 5px 20px;
        font-size: 10px;
        display: flex;
        text-align: center;
      "
      *ngIf="template.phases.length > 0"
    >
      <div style="width: 29%; text-align: left">Activity</div>
      <div style="width: 7%; text-align: left">Owner</div>
      <div style="width: 15%">Status</div>
      <div style="width: 10%; text-align: left">Workgroup</div>
      <div style="width: 10%">Start Date</div>
      <div style="width: 10%">End Date</div>
      <div style="width: 15%; text-align: left">Comments</div>
    </div>
    <div
      style="
        height: calc(100vh - 345px);
        overflow-y: scroll;
        overflow-x: hidden;
      "
    >
      <div id="phasegroup">
        <div *ngFor="let phase of template.phases; let ix = index;">
          <div class="phase-container">
            <div class="row">
              <div class="col-sm-6">
                <div style="margin-left: 5px">
                  <app-label-input
                    [inputString]="phase.name"
                    [placeholder]="'Enter Phase name'"
                    (refreshData)="updateData($event, 'phase', 'name', phase, ix)"
                  ></app-label-input>
                </div>
              </div>
              <div
                class="col-sm-6"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  padding-right: 17px;
                "
              >
                <button
                  mat-mini-fab
                  color="primary"
                  class="my-fab"
                  (click)="addActivity(ix)"
                  disabled
                >
                  <mat-icon>add</mat-icon>
                </button>
                <button class="small-icon-button" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>

            <div
              *ngIf="phase.activities.length <= 0"
              style="
                background: white;
                border-radius: 4px;
                overflow: hidden;
                display: block;
                padding: 10px 0;
                font-size: 10px;
                text-align: center;
              "
            >
              No Activity
            </div>
            <div class="item-list">
              <div
                class="item-box"
                *ngFor="let item of phase.activities; let j = index"
              >
                <div style="width: 100%; display: flex">
                  <div style="width: 29%; font-size: 12px; font-weight: 500">
                    <app-label-input
                      [inputString]="item.name"
                      [placeholder]="'Enter activity name'"
                      (refreshData)="updateData($event, 'activity', 'name', item, ix, j)"
                    ></app-label-input>
                  </div>
                  <div style="width: 7%">
                    <app-owner [ownerdata]="" [editable]="false"></app-owner>
                  </div>
                  <div style="width: 15%; text-align: center">
                    <select
                      [ngStyle]="{'background-color': item.status == 'Yet to Start' ? 'yellow' : item.status == 'Work in Progress' ? 'orange' : item.status == 'Done' ? '#23ff23' : ''}"
                      [(ngModel)]="item.status"
                      disabled
                      style="
                        font-size: 10px;
                        padding: 3px;
                        border-radius: 15px;
                        text-align: center;
                      "
                      (ngModelChange)="updateData(item.status, 'activity', 'status', item, ix, j)"
                    >
                      <option value>Select</option>
                      <option value="Yet to Start">Yet to Start</option>
                      <option value="Work in Progress">Work in Progress</option>
                      <option value="Done">Done</option>
                    </select>
                  </div>
                  <div style="width: 10%; text-align: center">
                    <span style="font-size: 10px">
                      <app-workgroup
                        [showCount]="1"
                        [showLabel]="false"
                        [workgroupdata]="item.workgroup"
                        [editable]="false"
                      ></app-workgroup>
                    </span>
                  </div>
                  <div style="width: 10%; text-align: center">
                    <span style="font-size: 10px; position: relative">
                      <button
                        class="small-icon-button"
                        mat-icon-button
                        style="
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                        "
                      >
                        <mat-icon>calendar_month</mat-icon>
                      </button>
                    </span>
                  </div>
                  <div style="width: 10%; text-align: center">
                    <span style="font-size: 10px; position: relative">
                      <button
                        class="small-icon-button"
                        mat-icon-button
                        style="
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                        "
                      >
                        <mat-icon>calendar_month</mat-icon>
                      </button>
                    </span>
                  </div>
                  <div style="width: 15%; display: flex">
                    <span
                      style="
                        font-size: 12px;
                        font-weight: 300;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <app-label-input
                        [inputString]="item.comments"
                        [placeholder]="'Enter Comments'"
                      ></app-label-input>
                    </span>
                  </div>
                  <div style="width: 3%">
                    <button class="small-icon-button" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px 20px;
  "
>
  <button
    mat-flat-button
    mat-button
    color="primary"
    class="bg-primary text-white"
    style="width: auto !important"
    (click)="closeModal(true)"
  >
    Next
  </button>
</div>
