<div class="px-0 pb-4">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane hubble">
      <div
        class="d-flex mb-3"
        style="align-items: center; justify-content: space-between"
      >
        <h5>Hubble</h5>
        <div
          class="d-flex justify-content-end gap-2"
          *ngxPermissionsOnly="['nps.add_survey']"
        >
          <button
            mat-button
            (click)="navigateToSurveyCentral()"
            class="bg-primary text-white"
            mat-button
            mat-flat-button
          >
            Survey Central
          </button>
          <button
            mat-button
            (click)="showCreateSurveyDialog()"
            class="bg-primary text-white"
            mat-button
            mat-flat-button
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5"
              >add
            </mat-icon>
            {{ staticText.survey.create_survey }}
          </button>
          <button
            mat-button
            (click)="showCreatePulseDialog()"
            class="bg-primary text-white"
            mat-button
            mat-flat-button
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5"
              >add
            </mat-icon>
            Add Pulse
          </button>
        </div>
      </div>
      <app-loading *ngIf="!dataLoaded"></app-loading>
      <div *ngIf="dataLoaded">
        <div class="filter-container my-2">
          <div class="filter-icon">
            <img
              height="25px"
              width="25px"
              src="./assets/images/filter-cp.png"
            />
          </div>

          <input
            *ngIf="activeTab !== 2"
            type="text"
            placeholder="Search Name..."
            class="form-control text-input"
            [(ngModel)]="filterName"
            (ngModelChange)="search()"
            style="
              min-width: 13rem;
              border-radius: 8px;
              font-size: 12px;
              height: 40px;
            "
          />
          <input
            *ngIf="activeTab !== 2"
            type="text"
            placeholder="Search Applicability..."
            class="form-control text-input"
            [(ngModel)]="filterApplicability"
            (ngModelChange)="search()"
            style="
              min-width: 13rem;
              border-radius: 8px;
              font-size: 12px;
              height: 40px;
            "
          />
          <mat-form-field
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterOwner"
              (ngModelChange)="search()"
              placeholder="Search Owner..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let owner of ownersList"
                value="{{ owner.id }}"
                >{{ owner.first_name + " " + owner.last_name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab !== 2"
            appearance="outline"
            class="filter-dropdown"
            style="font-size: 12px"
          >
            <input
              matInput
              [matDatepicker]="picker1"
              autocomplete="off"
              (click)="picker1.open()"
              [(ngModel)]="filterDueDate"
              (ngModelChange)="search()"
              placeholder="Search Due Date..."
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <input
            *ngIf="activeTab === 2"
            type="text"
            placeholder="Search Performance Impact..."
            class="form-control text-input"
            [(ngModel)]="filterPerformance"
            (ngModelChange)="search()"
            style="
              min-width: 13rem;
              border-radius: 8px;
              font-size: 12px;
              height: 40px;
            "
          />
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterPortfolio"
              (ngModelChange)="search()"
              placeholder="Search Portfolio..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let portfolio of portfolioList"
                value="{{ portfolio.id }}"
                >{{ portfolio.display_name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterSubPortfolio"
              (ngModelChange)="search()"
              placeholder="Search Subportfolio..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let subportfolio of subportfolioList"
                value="{{ subportfolio.id }}"
                >{{ subportfolio.display_name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterProgram"
              (ngModelChange)="search()"
              placeholder="Search Program..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let program of programList"
                value="{{ program.id }}"
                >{{ program.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterProject"
              (ngModelChange)="search()"
              placeholder="Search Project..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let project of projectList"
                value="{{ project.id }}"
                >{{ project.project_name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterVendor"
              (ngModelChange)="search()"
              placeholder="Search Vendor..."
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let vendor of vendorList"
                value="{{ vendor.id }}"
                >{{ vendor.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="activeTab === 2"
            class="w-100 filter-dropdown"
            appearance="outline"
            style="font-size: 12px"
          >
            <mat-select
              [(ngModel)]="filterType"
              (ngModelChange)="search()"
              placeholder="Search Type..."
            >
              <mat-option value=""></mat-option>
              <mat-option value="true">Positive</mat-option>
              <mat-option value="false">Negative</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-tab-group
          mat-align-tabs="start"
          (selectedTabChange)="changeTab($event)"
        >
          <mat-tab label="Surveys created by you">
            <div style="overflow-x: hidden">
              <div class="tab-holder mt-3">
                <div *ngIf="teamCreateForm.controls.length">
                  <h6>Open Surveys</h6>
                  <div class="flex-container-1 mb-3">
                    <div
                      class="flex-item p-1 hubble-card cursor-pointer"
                      *ngFor="let x of teamCreateForm.controls; let i = index"
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: backgroundColors[i] }"
                        style="height: 250px"
                      >
                        <div
                          class="d-flex flex-row justify-content-end"
                          *ngIf="x.value.backend_survey_id.value"
                        >
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              emitSurveyEvent(
                                x.value.backend_survey_id.value,
                                x.value.name.value,
                                x.value.survey_id.value,
                                'open'
                              );
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon
                              aria-hidden="false"
                              matTooltip="Check Responses"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >exit_to_app</mat-icon
                            >
                          </a>
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              refresh(x.value.backend_survey_id.value);
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon
                              matTooltip="Refresh"
                              aria-hidden="false"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >replay</mat-icon
                            >
                          </a>
                        </div>
                        <div (click)="showEditSurveyDialog(x)">
                          <h6
                            [matTooltip]="x?.value?.name?.value"
                            class="mb-0 trn-nm"
                          >
                            {{ x?.value?.name?.value }}
                          </h6>
                          <div
                            class="my-2 trn-ph"
                            *ngxPermissionsOnly="['nps.add_survey']"
                          >
                            <div class="d-flex align-items-center">
                              <span class="font">Respondants</span>
                            </div>
                            <ng-container
                              *ngIf="
                                x.value.respondents?.value;
                                else noRespondents
                              "
                            >
                              <div
                                class="d-flex flex-row my-1"
                                *ngxPermissionsOnly="['nps.add_survey']"
                              >
                                <div
                                  *ngFor="
                                    let members of x.value.respondents.value ||
                                      [] | slice : 0 : 3;
                                    let memIndx = index
                                  "
                                >
                                  <span
                                    class="font"
                                    style="
                                      display: flex;
                                      font-weight: 700;
                                      margin-left: -4px;
                                    "
                                    *ngIf="memIndx == 0"
                                    ><ngx-avatar
                                      [matTooltipPosition]="'above'"
                                      matTooltip="{{ members.first_name }} {{
                                        members.last_name
                                      }}"
                                      size="20"
                                      name="{{ members.first_name }} {{
                                        members.last_name
                                      }}"
                                    ></ngx-avatar
                                  ></span>
                                  <span
                                    class="font"
                                    style="
                                      display: flex;
                                      font-weight: 700;
                                      margin-left: -4px;
                                    "
                                    *ngIf="memIndx != 0"
                                    ><ngx-avatar
                                      [matTooltipPosition]="'above'"
                                      matTooltip="{{ members.first_name }} {{
                                        members.last_name
                                      }}"
                                      size="20"
                                      name="{{ members.first_name }} {{
                                        members.last_name
                                      }}"
                                    ></ngx-avatar
                                  ></span>
                                </div>
                                <div class="member-btn">
                                  <span
                                    class="font"
                                    *ngIf="x.value.respondents.value.length > 3"
                                    style="
                                      font-style: italic;
                                      cursor: pointer;
                                      font-size: 9px;
                                    "
                                    (click)="
                                      openUser(i); $event.stopPropagation()
                                    "
                                    >+
                                    {{ x.value.respondents.value.length - 3 }}
                                    more</span
                                  >
                                  <span
                                    class="font"
                                    *ngIf="
                                      x.value.respondents.value.length === 0
                                    "
                                    style="display: flex; font-weight: 700"
                                    >{{ "-" }}</span
                                  >
                                  <span
                                    class="add-bn"
                                    (click)="
                                      openUser(i); $event.stopPropagation()
                                    "
                                    >+</span
                                  >
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #noRespondents>
                              <span>-</span>
                            </ng-template>
                          </div>
                          <div class="mb-0 trn-ph">
                            <div class="d-flex align-items-center">
                              <span class="font">Created On</span>
                            </div>
                            <span
                              class="font"
                              style="display: flex; font-weight: 700"
                            >
                              {{
                                x.value.start_date.value
                                  ? x.value.start_date.value
                                  : "-"
                              }}
                            </span>
                          </div>
                          <div class="my-2 trn-ph">
                            <div class="d-flex align-items-center">
                              <span class="font">Due by</span>
                            </div>
                            <span
                              class="font"
                              style="display: flex; font-weight: 700"
                            >
                              {{
                                x.value.end_date.value
                                  ? x.value.end_date.value
                                  : "-"
                              }}
                            </span>
                          </div>
                          <div class="mb-0 mt-2 trn-ph">
                            <div class="d-flex align-items-center">
                              <span class="font">Response Count</span>
                            </div>
                            <span
                              class="font"
                              style="display: flex; font-weight: 700"
                            >
                              {{
                                x.value.response_count.value
                                  ? x.value.response_count.value
                                  : "-"
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="surveyName && surveyType == 'open'">
                    <ng-container
                      *ngTemplateOutlet="loadSurveyDetails"
                    ></ng-container>
                  </div>
                </div>
                <div *ngIf="totalSurveyClosedList.length">
                  <h6 *ngxPermissionsOnly="['nps.add_survey']">
                    Closed Surveys
                  </h6>
                  <div class="flex-container-1">
                    <div
                      class="flex-item p-1 hubble-card"
                      *ngFor="let x of totalSurveyClosedList; let i = index"
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: x.color }"
                        style="height: 250px"
                      >
                        <div class="d-flex flex-row justify-content-end">
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              emitSurveyEvent(
                                x.id,
                                x.name,
                                x.survey_id,
                                'close'
                              );
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon
                              aria-hidden="false"
                              matTooltip="Check Responses"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >exit_to_app</mat-icon
                            >
                          </a>
                        </div>

                        <div
                          [matTooltip]="x.name"
                          class="mb-0 trn-nm w-75 text-truncate"
                        >
                          {{ x.name }}
                        </div>
                        <div
                          class="my-2 trn-ph"
                          *ngxPermissionsOnly="['nps.add_survey']"
                        >
                          <div class="d-flex align-items-center">
                            <span class="font">Respondants</span>
                          </div>
                          <div
                            class="d-flex flex-row my-1"
                            *ngxPermissionsOnly="['nps.add_survey']"
                          >
                            <div
                              *ngFor="
                                let members of x.respondents | slice : 0 : 3;
                                let memIndx = index
                              "
                            >
                              <span
                                class="font"
                                style="
                                  display: flex;
                                  font-weight: 700;
                                  margin-left: -4px;
                                "
                                *ngIf="memIndx == 0"
                                ><ngx-avatar
                                  [matTooltipPosition]="'above'"
                                  matTooltip="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                  size="20"
                                  name="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                ></ngx-avatar
                              ></span>
                              <span
                                class="font"
                                style="
                                  display: flex;
                                  font-weight: 700;
                                  margin-left: -4px;
                                "
                                *ngIf="memIndx != 0"
                                ><ngx-avatar
                                  [matTooltipPosition]="'above'"
                                  matTooltip="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                  size="20"
                                  name="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                ></ngx-avatar
                              ></span>
                            </div>
                            <div class="member-btn">
                              <span
                                class="font"
                                *ngIf="x.respondents.length > 3"
                                style="
                                  color: #4c78ce;
                                  font-style: italic;
                                  cursor: pointer;
                                "
                                (click)="
                                  openUser(i, false); $event.stopPropagation()
                                "
                                >+ {{ x.respondents.length - 3 }} more</span
                              >
                              <span
                                class="font"
                                *ngIf="x.respondents.length === 0"
                                style="display: flex; font-weight: 700"
                                >{{ "-" }}</span
                              >
                              <span
                                class="add-bn"
                                (click)="
                                  openUser(i, false); $event.stopPropagation()
                                "
                                >+</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="mb-0 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Created On</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.start_date ? x.start_date : "-" }}
                          </span>
                        </div>
                        <div class="my-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Due by</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.end_date ? x.end_date : "-" }}
                          </span>
                        </div>

                        <div class="mb-0 mt-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Response Count</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.response_count ? x.response_count : "-" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="surveyName && surveyType == 'close'">
                    <ng-container
                      *ngTemplateOutlet="loadSurveyDetails"
                    ></ng-container>
                  </div>
                </div>
                <h6
                  *ngIf="
                    !teamCreateForm.controls.length &&
                    !totalSurveyClosedList.length
                  "
                  class="no-records opacity-50 text-center"
                >
                  You have created no surveys yet
                </h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Surveys you are a respondent to">
            <div style="overflow-x: hidden">
              <div class="tab-holder mt-3">
                <div *ngIf="teamCreateForm.controls.length">
                  <h6>Open Surveys</h6>
                  <div class="flex-container-1 mb-3">
                    <div
                      class="flex-item p-1 hubble-card"
                      *ngFor="let x of teamCreateForm.controls; let i = index"
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: backgroundColors[i] }"
                        style="height: 250px"
                        (click)="showSurveyEmbedDialog(x.value.id.value)"
                      >
                        <div
                          class="d-flex flex-row justify-content-end"
                          *ngIf="x.value.backend_survey_id.value"
                        >
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              emitSurveyEvent(
                                x.value.backend_survey_id.value,
                                x.value.name.value,
                                x.value.survey_id.value,
                                'open'
                              );
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon
                              aria-hidden="false"
                              matTooltip="Check Responses"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >exit_to_app</mat-icon
                            >
                          </a>
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              refresh(x.value.backend_survey_id.value);
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon
                              matTooltip="Refresh"
                              aria-hidden="false"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >replay</mat-icon
                            >
                          </a>
                        </div>
                        <h6
                          [matTooltip]="x?.value?.name?.value"
                          class="mb-0 trn-nm"
                        >
                          {{ x?.value?.name?.value }}
                        </h6>
                        <div
                          class="my-2 trn-ph"
                          *ngxPermissionsOnly="['nps.add_survey']"
                        >
                          <div class="d-flex align-items-center">
                            <span class="font">Respondants</span>
                          </div>
                          <ng-container
                            *ngIf="
                              x.value.respondents?.value;
                              else noRespondents
                            "
                          >
                            <div
                              class="d-flex flex-row my-1"
                              *ngxPermissionsOnly="['nps.add_survey']"
                            >
                              <div
                                *ngFor="
                                  let members of x.value.respondents.value || []
                                    | slice : 0 : 3;
                                  let memIndx = index
                                "
                              >
                                <span
                                  class="font"
                                  style="
                                    display: flex;
                                    font-weight: 700;
                                    margin-left: -4px;
                                  "
                                  *ngIf="memIndx == 0"
                                  ><ngx-avatar
                                    [matTooltipPosition]="'above'"
                                    matTooltip="{{ members.first_name }} {{
                                      members.last_name
                                    }}"
                                    size="20"
                                    name="{{ members.first_name }} {{
                                      members.last_name
                                    }}"
                                  ></ngx-avatar
                                ></span>
                                <span
                                  class="font"
                                  style="
                                    display: flex;
                                    font-weight: 700;
                                    margin-left: -4px;
                                  "
                                  *ngIf="memIndx != 0"
                                  ><ngx-avatar
                                    [matTooltipPosition]="'above'"
                                    matTooltip="{{ members.first_name }} {{
                                      members.last_name
                                    }}"
                                    size="20"
                                    name="{{ members.first_name }} {{
                                      members.last_name
                                    }}"
                                  ></ngx-avatar
                                ></span>
                              </div>
                              <div class="member-btn">
                                <span
                                  class="font"
                                  *ngIf="x.value.respondents.value.length > 3"
                                  style="
                                    font-style: italic;
                                    cursor: pointer;
                                    font-size: 9px;
                                  "
                                  (click)="
                                    openUser(i); $event.stopPropagation()
                                  "
                                  >+
                                  {{ x.value.respondents.value.length - 3 }}
                                  more</span
                                >
                                <span
                                  class="font"
                                  *ngIf="x.value.respondents.value.length === 0"
                                  style="display: flex; font-weight: 700"
                                  >{{ "-" }}</span
                                >
                                <span
                                  class="add-bn"
                                  (click)="
                                    openUser(i); $event.stopPropagation()
                                  "
                                  >+</span
                                >
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #noRespondents>
                            <span>-</span>
                          </ng-template>
                        </div>
                        <div class="mb-0 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Created On</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{
                              x.value.start_date.value
                                ? x.value.start_date.value
                                : "-"
                            }}
                          </span>
                        </div>
                        <div class="my-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Due by</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{
                              x.value.end_date.value
                                ? x.value.end_date.value
                                : "-"
                            }}
                          </span>
                        </div>
                        <div class="mb-0 mt-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Response Count</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{
                              x.value.response_count.value
                                ? x.value.response_count.value
                                : "-"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="surveyName && surveyType == 'open'">
                    <ng-container
                      *ngTemplateOutlet="loadSurveyDetails"
                    ></ng-container>
                  </div>
                </div>
                <div *ngIf="totalSurveyClosedList.length">
                  <h6 *ngxPermissionsOnly="['nps.add_survey']">
                    Closed Surveys
                  </h6>
                  <div class="flex-container-1">
                    <div
                      class="flex-item p-1 hubble-card"
                      *ngFor="let x of totalSurveyClosedList; let i = index"
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: x.color }"
                        style="height: 250px"
                      >
                        <div class="d-flex flex-row justify-content-end">
                          <a
                            class="add-btton"
                            style="justify-content: center"
                            (click)="
                              emitSurveyEvent(
                                x.id,
                                x.name,
                                x.survey_id,
                                'close'
                              )
                            "
                          >
                            <mat-icon
                              aria-hidden="false"
                              matTooltip="Check Responses"
                              [matTooltipPosition]="'above'"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-6 w-75"
                              >exit_to_app</mat-icon
                            >
                          </a>
                        </div>

                        <div
                          [matTooltip]="x.name"
                          class="mb-0 trn-nm w-75 text-truncate"
                        >
                          {{ x.name }}
                        </div>
                        <div
                          class="my-2 trn-ph"
                          *ngxPermissionsOnly="['nps.add_survey']"
                        >
                          <div class="d-flex align-items-center">
                            <span class="font">Respondants</span>
                          </div>
                          <div
                            class="d-flex flex-row my-1"
                            *ngxPermissionsOnly="['nps.add_survey']"
                          >
                            <div
                              *ngFor="
                                let members of x.respondents | slice : 0 : 3;
                                let memIndx = index
                              "
                            >
                              <span
                                class="font"
                                style="
                                  display: flex;
                                  font-weight: 700;
                                  margin-left: -4px;
                                "
                                *ngIf="memIndx == 0"
                                ><ngx-avatar
                                  [matTooltipPosition]="'above'"
                                  matTooltip="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                  size="20"
                                  name="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                ></ngx-avatar
                              ></span>
                              <span
                                class="font"
                                style="
                                  display: flex;
                                  font-weight: 700;
                                  margin-left: -4px;
                                "
                                *ngIf="memIndx != 0"
                                ><ngx-avatar
                                  [matTooltipPosition]="'above'"
                                  matTooltip="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                  size="20"
                                  name="{{ members.first_name }} {{
                                    members.last_name
                                  }}"
                                ></ngx-avatar
                              ></span>
                            </div>
                            <div class="member-btn">
                              <span
                                class="font"
                                *ngIf="x.respondents.length > 3"
                                style="
                                  color: #4c78ce;
                                  font-style: italic;
                                  cursor: pointer;
                                "
                                (click)="openUser(i, false)"
                                >+ {{ x.respondents.length - 3 }} more</span
                              >
                              <span
                                class="font"
                                *ngIf="x.respondents.length === 0"
                                style="display: flex; font-weight: 700"
                                >{{ "-" }}</span
                              >
                              <span class="add-bn" (click)="openUser(i, false)"
                                >+</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="mb-0 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Created On</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.start_date ? x.start_date : "-" }}
                          </span>
                        </div>
                        <div class="my-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Due by</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.end_date ? x.end_date : "-" }}
                          </span>
                        </div>

                        <div class="mb-0 mt-2 trn-ph">
                          <div class="d-flex align-items-center">
                            <span class="font">Response Count</span>
                          </div>
                          <span
                            class="font"
                            style="display: flex; font-weight: 700"
                          >
                            {{ x.response_count ? x.response_count : "-" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="surveyName && surveyType == 'close'">
                    <ng-container
                      *ngTemplateOutlet="loadSurveyDetails"
                    ></ng-container>
                  </div>
                </div>
                <h6
                  *ngIf="
                    !teamCreateForm.controls.length &&
                    !totalSurveyClosedList.length
                  "
                  class="no-records opacity-50 text-center"
                >
                  You are not assigned to any survey as a respondent currently
                </h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Pulse">
            <div style="overflow-x: hidden">
              <div class="tab-holder mt-3">
                <div *ngIf="pulseLoaded">
                  <app-pulse [pulsesList]="pulsesList"></app-pulse>
                  <h6
                    *ngIf="!pulsesList.length"
                    class="no-records opacity-50 text-center"
                  >
                    No records found
                  </h6>
                </div>
                <app-loading *ngIf="!pulseLoaded"></app-loading>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <h6>Insights & Engagement Zone</h6>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              id="collapseOneButton"
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              style="font-size: 13px"
            >
              Executive Summary
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <mat-card-subtitle class="mb-2 card-title-pi">{{
                surveyName
              }}</mat-card-subtitle>
              <ng-container *ngFor="let res of totalSurveyResponse">
                <mat-card class="p-0 mb-2">
                  <mat-card-content class="p-1 card-color mb-0">
                    <mat-card-subtitle class="mb-1 okr-card-title"
                      >{{ res?.display_text }}
                      <mat-icon
                        *ngIf="res.question_type != 'single_choice'"
                        (click)="getSurveyCommentsByEachQuestion(res.id)"
                        style="
                          padding-top: 4px;
                          font-size: 14px;
                          width: 16px;
                          height: 16px;
                        "
                        >open_in_new</mat-icon
                      ></mat-card-subtitle
                    >
                  </mat-card-content>
                  <mat-card-content class="pt-1 text-center">
                    <div>
                      <highcharts-chart
                        style="display: flex"
                        [Highcharts]="Highcharts"
                        [options]="res.chart"
                        [callbackFunction]="chartCallback"
                        [oneToOne]="true"
                      ></highcharts-chart>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card *ngIf="res?.maxAvrageVendor">
                  <mat-card-subtitle
                    *ngIf="res?.maxAvrageVendor"
                    class="okr-card-title text-center"
                    style="color: #000; font-weight: 500; font-size: 12px"
                  >
                    <span *ngIf="surveyName == 'GDP-BVT Survey'">
                      {{ getHighestField(res?.maxAvrageVendor)?.option }} has
                      the highest score for
                      {{ getHighestField(res?.maxAvrageVendor)?.year }}.</span
                    >
                    <span *ngIf="surveyName == 'IT BVT Survey'">
                      For {{ getHighestField(res?.maxAvrageVendor)?.year }}, the
                      average score is
                      {{ getHighestField(res?.maxAvrageVendor)?.avg }}.
                      <span
                        *ngIf="getHighestField(res?.maxAvrageVendor)?.avg > 3"
                        >The OUs objectives are being enabled by the IT
                        department.</span
                      >
                      <span
                        *ngIf="getHighestField(res?.maxAvrageVendor)?.avg <= 3"
                        >More work required by the IT department to enable
                        OUs.</span
                      >
                    </span>
                  </mat-card-subtitle> </mat-card
                ><br />
              </ng-container>
              <div *ngIf="!totalSurveyResponse?.length" class="text-center">
                <span class="fs-12">{{
                  staticText.common.no_records_found
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
              style="font-size: 13px"
            >
              Curated from Web
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-2">
              <app-loading *ngIf="loading"></app-loading>
              <app-gnews [display_header]="'false'"></app-gnews>
            </div>
          </div>
        </div>
        <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button id="collapseTwoButton" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="font-size: 13px">
                        Scoring By Question
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <mat-card-subtitle class="mb-2 card-title-pi text-center">{{qstSurveyName}} <span *ngIf="userName">-</span> {{userName}}
                        </mat-card-subtitle>
                        <ng-container *ngFor="let responds of questionAnswerSurvey;">
                            <mat-card class="p-0 mb-2"  *ngFor="let que of responds.questions;">
                                <mat-card-content class="p-1 card-color mb-0">
                                    <mat-card-subtitle class="mb-1 okr-card-title">{{que?.display_text}}</mat-card-subtitle>
                                </mat-card-content>
                                <mat-card-content class="pb-2 pr-1 pl-1">
                                    <ng-container *ngFor="let ans of que.answers;">
                                        <mat-card class="pt-0 mt-2 pb-0 pr-2 pl-2"  style="border-left: 6px solid #5ABAFA;">
                                            <mat-card-content>
                                                    <div class="d-flex justify-content-between w-100" > 
                                                        <span class="mt-1">{{ans?.option_text}}</span>
                                                        <b class="fs-12 mt-1 pt-1 pb-1">{{ans?.answer_text}}</b>
                                                    </div>
                                            </mat-card-content>
                                        </mat-card> 
                                        <div *ngIf="!que?.answers?.length" class="text-center">
                                            <span class="fs-12">{{staticText.common.no_records_found}}</span>
                                        </div>
                                    </ng-container>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>    
    
                        <div *ngIf="!questionAnswerSurvey?.length" class="text-center">
                            <span class="fs-12">{{staticText.common.no_records_found}}</span>
                        </div>
                    </div>
                  </div>
                </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #loadSurveyDetails>
  <div *ngIf="surveyName" class="text-center pt-2 pb-2">
    <div>
      <div class="float-right">
        <mat-icon
          style="background-color: rgb(232, 193, 142); color: red"
          (click)="closeSurveyDetails()"
          >close</mat-icon
        >
      </div>
      <h4 class="fw-bold">{{ surveyName }}</h4>
    </div>
    <h6>
      {{ surveySummary?.response_count }} of
      {{ surveySummary?.respondent_count }} Survey Recipients Responded,
      Constituting a
      {{
        (surveySummary?.response_count / surveySummary?.respondent_count) * 100
          | number : "1.0-0"
      }}% Response Rate.
    </h6>
    <div *ngIf="surveyName == 'GDP-BVT Survey'">
      <div *ngIf="surveySummary?.vendor_average?.length > 0">
        <!-- Top 3 GDPs Based on Average Score are -<br> -->
        <!-- <ol start="1" style="display: inline-block;"> -->
        <ng-container
          *ngFor="let vendor of surveySummary?.vendor_average; index as i"
        >
          <span style="font-size: 14px; font-weight: bold" *ngIf="i < 3"
            >{{ vendor?.vendor }} ({{
              vendor?.average | number : "1.2-2"
            }})<span *ngIf="i < 2">, </span>
          </span>
        </ng-container>
        are the top three GDPs based on performance.<br />
        <!-- </ol><br> -->
        <!-- Bottom 3 GDPs Based on Average Score are -<br> -->
        <!-- <ol start="1" style="display: inline-block;"> -->
        <ng-container
          *ngFor="let vendor of surveySummary?.vendor_average; index as i"
        >
          <span
            style="font-size: 14px; font-weight: bold"
            *ngIf="i > surveySummary?.vendor_average?.length - 4"
            >{{ vendor?.vendor }} ({{
              vendor?.average | number : "1.2-2"
            }})<span
              *ngIf="
                i > surveySummary?.vendor_average?.length - 4 &&
                i != surveySummary?.vendor_average?.length - 1
              "
              >,
            </span>
          </span>
        </ng-container>
        are the bottom three GDPs based on performance.
        <!-- </ol> -->
      </div>
      <div *ngIf="surveySummary?.page_average?.length > 0">
        <!-- Sections with GDPs Average Scores are - <br> -->
        <div>
          <div
            *ngFor="let page of surveySummary?.page_average; index as i"
            class="mt-2"
          >
            Within section
            <span style="font-size: 14px; font-weight: bold"
              >{{ page?.page?.title }},
            </span>
            <!-- <ol style="display: inline-block;"> -->
            <ng-container
              *ngFor="let vendor of page?.vendor_average; index as i"
            >
              <span style="font-size: 14px" *ngIf="i < 3"
                >{{ vendor?.vendor }} ({{
                  vendor?.average | number : "1.2-2"
                }})<span *ngIf="i < 2">, </span>
              </span>
            </ng-container>
            are the best performers.<br />

            Within section
            <span style="font-size: 14px; font-weight: bold"
              >{{ page?.page?.title }},
            </span>
            <ng-container
              *ngFor="let vendor of page?.vendor_average; index as i"
            >
              <span
                style="font-size: 14px"
                *ngIf="i > surveySummary?.vendor_average?.length - 4"
                >{{ vendor?.vendor }} ({{
                  vendor?.average | number : "1.2-2"
                }})<span
                  *ngIf="
                    i > surveySummary?.vendor_average?.length - 4 &&
                    i != surveySummary?.vendor_average?.length - 1
                  "
                  >,
                </span>
              </span>
            </ng-container>
            are the worst performers.<br />
            <!-- </ol> -->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="surveyName == 'IT BVT Survey'" style="font-size: 12px">
      <b>
        Delta delivers technology solutions with appropriate value for the
        financial cost?, IT embodies “Keep Climbing” - I see measurable progress
        in IT's performance from this time last year?, IT enables my business
        strategies and goals?</b
      >
      were the questions with the best performance having avg scores
      <b>5, 5, 4</b> respectively.<br />
      <b
        >IT is a “partner of choice” for you (attributes including partnership,
        transparency, responsiveness and core Delta values)?, IT enables my
        business to manage IT-dependent operational risk effectively (PCI, SOX,
        Cyber, etc.)?, IT delivers a reliable production environment that
        enables me to run my business effectively?
      </b>
      were the questions with the weak performance having avg scores
      <b>3, 3, 4</b> respectively.
    </div>
  </div>
  <div *ngIf="biReportFlag.reportConfig">
    <div class="row pb-2">
      <div style="background-color: #e5e4e2">
        <h5 class="fw-bold pt-4 pl-4 pb-0">
          Comparison of Avg. Scores for Each Question
        </h5>
        <powerbi-report
          [embedConfig]="reportConfig"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
    </div>
  </div>

  <div *ngIf="surveyName">
    <div class="row pb-2 pt-2" *ngIf="surveyData?.length > 0">
      <div class="container p-4" style="background-color: #e5e4e2">
        <div class="p-4">
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="fw-bold">
                % of Respondents for Each Question Spilt for Each Score
              </h5>
              <div class="d-flex justify-content-end">
                <div *ngFor="let option of surveyOptions">
                  <div
                    class="mr-2 cursor-pointer"
                    (click)="clickOptions(option)"
                    style="font-size: 12px"
                    [class.optinSelected]="option === optionType"
                  >
                    <mat-icon style="font-size: 6px; width: 10px">lens</mat-icon
                    >{{ option }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="fs-8">Sentiment Analysis</span>
              <div class="toggle-container">
                <input
                  type="checkbox"
                  id="toggle-button-checkbox"
                  (change)="enableEachSubOption = $event.target.checked"
                />
                <label
                  class="toggle-button-switch"
                  for="toggle-button-checkbox"
                ></label>
                <div class="toggle-button-text">
                  <div class="toggle-button-text-on">ON</div>
                  <div class="toggle-button-text-off">OFF</div>
                </div>
              </div>
            </div>
          </div>
          <div class="fixed-table-header mt-4">
            <table *ngIf="!enableEachSubOption">
              <tr class="custom-tr">
                <th class="custom-th"></th>
                <th class="custom-th">Exceptional</th>
                <th class="custom-th">Good</th>
                <th class="custom-th">As Expected</th>
                <th class="custom-th">Below Average</th>
                <th class="custom-th">Under Performing</th>
                <th class="custom-th">Regressing</th>
              </tr>
              <ng-container *ngFor="let survey of surveyDetailsByEachSubOption">
                <tr class="cutom-tr">
                  <td class="w-75">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="pr-2">
                        <mat-icon class="receipt">receipt</mat-icon>
                      </div>
                      <div class="w-75 text-display">
                        {{ survey?.display_text }}
                      </div>
                    </div>
                  </td>
                  <ng-container *ngFor="let option of survey.eachSubOption">
                    <ng-container *ngFor="let sub_option of option.sub_options">
                      <td class="custom-td">
                        <div class="flex-wrapper">
                          <div class="single-chart">
                            <svg
                              viewBox="0 0 36 36"
                              class="circular-chart orange"
                            >
                              <path
                                class="circle-bg"
                                d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                class="circle"
                                attr.stroke-dasharray="{{
                                  sub_option.sub_option_percentage
                                    ? sub_option.sub_option_percentage
                                    : 0
                                }}, 100"
                                d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <text x="18" y="20.35" class="percentage">
                                {{
                                  sub_option.sub_option_percentage
                                    ? sub_option.sub_option_percentage
                                    : 0
                                }}%
                              </text>
                            </svg>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
              <ng-container
                *ngFor="let survey of singleChoiceQuestionEachOption"
              >
                <tr class="cutom-tr">
                  <td class="w-75">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="pr-2">
                        <mat-icon class="receipt">receipt</mat-icon>
                      </div>
                      <div class="w-75 text-display">
                        {{ survey?.display_text }}
                      </div>
                    </div>
                  </td>
                  <ng-container
                    *ngFor="let option of survey.singleChoiceEachOption"
                  >
                    <ng-container *ngFor="let sub_option of option.options">
                      <td class="custom-td">
                        <div class="flex-wrapper">
                          <div class="single-chart">
                            <svg
                              viewBox="0 0 36 36"
                              class="circular-chart orange"
                            >
                              <path
                                class="circle-bg"
                                d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                class="circle"
                                attr.stroke-dasharray="{{
                                  sub_option.percentage
                                    ? sub_option.percentage
                                    : 0
                                }}, 100"
                                d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <text x="18" y="20.35" class="percentage">
                                {{
                                  sub_option.percentage
                                    ? sub_option.percentage
                                    : 0
                                }}%
                              </text>
                            </svg>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
            </table>
            <table *ngIf="enableEachSubOption">
              <tr class="custom-tr">
                <th class="custom-th w-50"></th>
                <th class="custom-th text-left ml-4 pl-3">Positive</th>
                <th class="custom-th text-left ml-4 pl-3">Neutral</th>
                <th class="custom-th text-left ml-4 pl-3">Negative</th>
              </tr>
              <ng-container
                *ngFor="let survey of surveyDetailsBySubotionCategory"
              >
                <tr class="cutom-tr">
                  <td class="w-50">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="pr-2">
                        <mat-icon class="receipt">receipt</mat-icon>
                      </div>
                      <div class="w-75 text-display">
                        {{ survey?.display_text }}
                      </div>
                    </div>
                  </td>
                  <ng-container
                    *ngFor="let option of survey.subOptionByCategories"
                  >
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.positive ? option.positive : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.positive ? option.positive : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.neutral ? option.neutral : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.neutral ? option.neutral : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.negative ? option.negative : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.negative ? option.negative : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
              <ng-container
                *ngFor="
                  let survey of singleChoiceQuestionQuestionOptionCategory
                "
              >
                <tr class="cutom-tr">
                  <td class="w-50">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="pr-2">
                        <mat-icon class="receipt">receipt</mat-icon>
                      </div>
                      <div class="w-75 text-display">
                        {{ survey?.display_text }}
                      </div>
                    </div>
                  </td>
                  <ng-container
                    *ngFor="let option of survey.singleChoiceOptionByCategories"
                  >
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.positive ? option.positive : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.positive ? option.positive : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.neutral ? option.neutral : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.neutral ? option.neutral : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="custom-td">
                      <div class="flex-wrapper">
                        <div class="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            class="circular-chart orange"
                          >
                            <path
                              class="circle-bg"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              class="circle"
                              attr.stroke-dasharray="{{
                                option.negative ? option.negative : 0
                              }}, 100"
                              d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">
                              {{ option.negative ? option.negative : 0 }}%
                            </text>
                          </svg>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="surveyName">
    <div class="row pt-2 pb-2" *ngIf="biReportFlag.reportConfigRadarChart">
      <div style="background-color: #e5e4e2">
        <h5 class="fw-bold pt-4 pl-4 pb-0">
          Section-wise and Vendor-wise Comparison of Avg. Scores
        </h5>
        <powerbi-report
          [embedConfig]="reportConfigRadarChart"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
    </div>
    <div class="row pt-2 pb-2" *ngIf="biReportFlag.sentimentGraph">
      <div style="background-color: #e5e4e2">
        <h5 class="fw-bold pt-4 pl-4 pb-0">
          Comparison of Sentiment Score and Avg. Score for Each Question
        </h5>
        <powerbi-report
          [embedConfig]="sentimentGraph"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
    </div>
    <div class="row pt-2 pb-2" *ngIf="biReportFlag.reportConfigSUnBurst">
      <div style="background-color: #e5e4e2">
        <h5 class="fw-bold pt-4 pl-4 pb-0">
          Comparison of Avg. Scores for Portfolios and Sub Portfolios
        </h5>
        <powerbi-report
          [embedConfig]="reportConfigSUnBurst"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
    </div>
  </div>
</ng-template>
