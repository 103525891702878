<div class="px-0">
  <app-filter-card [pagetype]="'maven'"></app-filter-card>
</div>
<div class="py-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div
        class="d-flex mb-2"
        style="align-items: center; justify-content: space-between"
      >
        <h5>Maven</h5>
        <div class="justify-content-end">
          <button
            mat-button
            class="bg-primary text-white mr-2"
            mat-button
            mat-flat-button
            (click)="onClickAddCuratedInsights(NewCuratedInsights, 'request')"
          >
            Ask an Expert
          </button>
          <button
            mat-button
            class="bg-primary text-white"
            mat-button
            mat-flat-button
            (click)="onClickAddCuratedInsights(NewCuratedInsights, 'insight')"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5"
              >add
            </mat-icon>
            Insights
          </button>
        </div>
      </div>
      <div>
        <mat-tab-group
          mat-align-tabs="start"
          (selectedTabChange)="changeTab($event)"
        >
          <mat-tab label="AI Powered Insights">
            <div *ngIf="activeTab == 0">
              <div class="tab-holder">
                <div class="flex-container-1">
                  <div
                    class="flex-item p-1 mt-3 f-13"
                    style="width: 50%; height: 27vh"
                    *ngFor="let insight of mlcaseList"
                    (click)="
                      mlCasePopUp(insight, mlPopup) && $event.stopPropagation()
                    "
                  >
                    <div
                      class="trn-x h-100"
                      [ngStyle]="{ background: insight.color }"
                    >
                      <div class="d-flex justify-content-between">
                        <h6
                          class="mb-2 trn-nm"
                          style="width: 90%; font-size: 14px"
                          matTooltip="{{ insight.name }}"
                        >
                          {{ insight.name }}
                        </h6>
                      </div>
                      <div class="flex-item">
                        <span>{{ insight?.box_description }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- <mat-tab label="AI Powered Insights">
            <div *ngIf="activeTab == 0">
               <h6>Created by You</h6> 
              <div
                class="tab-holder"
                *ngIf="aiPoweredInsights?.createdByYou?.length > 0"
              >
                <div class="flex-container-1">
                  <div
                    class="flex-item p-1"
                    *ngFor="let insight of aiPoweredInsights?.createdByYou"
                    (click)="
                      editInsight(NewCuratedInsights, insight, 'insight') &&
                        $event.stopPropagation()
                    "
                  >
                    <div
                      class="trn-x"
                      [ngStyle]="{ background: insight.color }"
                    >
                      <div class="d-flex justify-content-between">
                        <h6
                          class="mb-0 trn-nm"
                          style="width: 90%"
                          matTooltip="{{ insight.title }}"
                        >
                          {{ insight.title }}
                        </h6>
                        <button
                          class="action-vert"
                          color="warn"
                          (click)="
                            deleteInsight(insight, $event);
                            $event.stopPropagation()
                          "
                          mat-icon-button
                        >
                          <mat-icon style="color: red">delete</mat-icon>
                        </button>
                      </div>
                      <div
                        id="myDiv"
                        class="mt-1"
                        style="font-size: 11px; height: 35px; overflow: hidden"
                        matTooltip="{{ insight.description }}"
                      >
                        {{ getTrucatedName(insight?.description) }}
                      </div>
                      <div class="lates-update-style">
                        Updated :
                        {{ insight?.last_updated_on | date : "d-MMM-YYYY" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="aiPoweredInsights?.requestedByYou?.length > 0">
                <h6>Requested by You</h6>
                <div class="tab-holder">
                  <div class="flex-container-1">
                    <div
                      class="flex-item p-1"
                      *ngFor="let insight of aiPoweredInsights?.requestedByYou"
                      (click)="
                        editInsight(NewCuratedInsights, insight, 'request') &&
                          $event.stopPropagation()
                      "
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: insight.color }"
                      >
                        <div class="d-flex justify-content-between">
                          <h6
                            class="mb-0 trn-nm"
                            style="width: 90%"
                            matTooltip="{{ insight.title }}"
                          >
                            {{ insight.title }}
                          </h6>
                        </div>
                        <div
                          class="mt-1"
                          style="
                            font-size: 11px;
                            height: 35px;
                            overflow: hidden;
                          "
                          matTooltip="{{ insight.description }}"
                        >
                          <span>{{ insight?.description }} </span>
                        </div>
                        <div
                          class="st-col text-capitalize mt-3"
                          *ngIf="insight.type == 'completed'"
                          style="background-color: green"
                        >
                          Completed
                        </div>
                        <div class="lates-update-style">
                          Updated :
                          {{ insight?.last_updated_on | date : "d-MMM-YYYY" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab> -->
          <mat-tab label="Curated Perspectives">
            <div *ngIf="activeTab == 1">
              <h6>Created by You</h6>
              <div
                class="tab-holder"
                *ngIf="curatedInsights?.createdByYou?.length > 0"
              >
                <div class="flex-container-1">
                  <div
                    class="flex-item p-1 w-20"
                    *ngFor="let insight of curatedInsights?.createdByYou"
                    (click)="
                      editInsight(NewCuratedInsights, insight, 'insight') &&
                        $event.stopPropagation()
                    "
                  >
                    <div
                      class="trn-x"
                      style="height: 135px"
                      [ngStyle]="{ background: insight.color }"
                    >
                      <div class="d-flex justify-content-between">
                        <h6
                          class="mb-0 trn-nm"
                          style="width: 90%"
                          matTooltip="{{ insight.title }}"
                        >
                          {{ insight.title }}
                        </h6>
                        <button
                          class="action-vert"
                          color="warn"
                          (click)="
                            deleteInsight(insight, $event);
                            $event.stopPropagation()
                          "
                          mat-icon-button
                        >
                          <mat-icon style="color: red">delete</mat-icon>
                        </button>
                      </div>
                      <div
                        id="myDiv"
                        class="mt-1"
                        style="font-size: 11px; height: 35px; overflow: hidden"
                        matTooltip="{{ insight.description }}"
                      >
                        {{ getTrucatedName(insight?.description) }}
                      </div>
                      <div class="lates-update-style">
                        Updated :
                        {{ insight?.last_updated_on | date : "d-MMM-YYYY" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="aiPoweredInsights?.requestedByYou?.length?.length > 0"
              >
                <h6>Requested by You</h6>
                <div class="tab-holder">
                  <div class="flex-container-1">
                    <div
                      class="flex-item p-1"
                      *ngFor="
                        let insight of aiPoweredInsights?.requestedByYou?.length
                      "
                      (click)="
                        editInsight(NewCuratedInsights, insight, 'request') &&
                          $event.stopPropagation()
                      "
                    >
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: insight.color }"
                      >
                        <div class="d-flex justify-content-between">
                          <h6
                            class="mb-0 trn-nm"
                            style="width: 90%"
                            matTooltip="{{ insight.title }}"
                          >
                            {{ insight.title }}
                          </h6>
                        </div>
                        <div
                          class="mt-1"
                          style="
                            font-size: 11px;
                            height: 35px;
                            overflow: hidden;
                          "
                          matTooltip="{{ insight.description }}"
                        >
                          <span>{{ insight?.description }} </span>
                        </div>
                        <div
                          class="st-col text-capitalize mt-3"
                          *ngIf="insight.type == 'completed'"
                          style="background-color: green"
                        >
                          Completed
                        </div>
                        <div class="lates-update-style">
                          Updated :
                          {{ insight?.last_updated_on | date : "d-MMM-YYYY" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
    </div>
  </div>
</div>

<ng-template #NewCuratedInsights let-data>
  <div class="modal-dialog custom-width m-0">
    <div class="modal-content">
      <app-modal-header-common
        [title]="insightType == 'insight' ? 'Add Insights' : 'Ask an Expert'"
      >
      </app-modal-header-common>

      <div class="modal-body p-0 p-3 curated-insight" style="">
        <div style="display: flex; flex-direction: row">
          <div
            [ngStyle]="{ width: data.mode == 'edit' ? '30%' : '100%' }"
            style="
              overflow-y: scroll;
              max-height: 60vh;
              padding: 10px;
              overflow-x: hidden;
            "
          >
            <form [formGroup]="curatedInsightsForm" class="pt-2">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Title</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="title"
                />
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea
                  type="text"
                  matInput
                  status="basic"
                  formControlName="description"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select formControlName="category_id">
                  <mat-option
                    *ngFor="let option of mavenCategory"
                    [value]="option.id"
                    >{{ option.display_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <app-tag
                [noIds]="true"
                *ngIf="insightType == 'insight'"
                [tagsIds]="curatedTags"
                (childTags)="getChildTags($event)"
              ></app-tag>
              <br />
              <mat-form-field
                class="w-100"
                *ngIf="insightType == 'insight'"
                appearance="outline"
              >
                <mat-label>Audience</mat-label>
                <mat-select formControlName="assignees" multiple>
                  <ng-container *ngFor="let user of teamList">
                    <mat-option
                      *ngIf="user?.first_name || user?.last_name"
                      [value]="user.id"
                      >{{ user?.first_name }} {{ user?.last_name }}</mat-option
                    >
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <div class="row mt-1" *ngIf="insightType == 'insight'">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Link URL</mat-label>
                    <input
                      matInput
                      [(ngModel)]="linkURL"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
                <div
                  class="col-sm-2"
                  style="display: flex; justify-content: space-between"
                >
                  <button mat-icon-button color="success" (click)="addLink()">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
              <mat-accordion *ngIf="insightType == 'insight'">
                <mat-expansion-panel
                  [expanded]="true"
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                  style="border-radius: 20px"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Links ({{ curatedInsightsForm.value.links.length }})
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-x: scroll">
                    <div
                      class="d-flex flex-row flex-nowrap overflow-auto"
                      style="padding: 10px"
                    >
                      <div
                        class="card card-block mx-2"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: end;
                          border-radius: 15px;
                          cursor: pointer;
                        "
                        *ngFor="
                          let x of curatedInsightsForm.value.links;
                          let i = index
                        "
                        (click)="openLink(x)"
                      >
                        <div class="d-flex align-items-center">
                          <mat-icon class="lk">link</mat-icon>
                          <p class="m-0 asw">{{ x }}</p>
                          <mat-icon color="remove" (click)="removeLink(i)"
                            >clear</mat-icon
                          >
                        </div>
                      </div>
                      <div *ngIf="curatedInsightsForm.value.links.length == 0">
                        No links added
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <br />
              <div
                class="d-flex justify-content-between mt-2"
                *ngIf="insightType == 'insight'"
              >
                <mat-label>Upload Document *</mat-label>
                <button mat-icon-button color="success">
                  <input
                    hidden
                    #fileInput
                    type="file"
                    id="file"
                    (change)="onFileSelected($event)"
                  />
                  <mat-icon (click)="fileInput.click()">add</mat-icon>
                </button>
              </div>
              <div *ngIf="insightType == 'insight'">
                <div
                  class="d-flex flex-row flex-nowrap overflow-auto"
                  style="padding: 10px 0; overflow-x: scroll"
                >
                  <div
                    class="card card-block mx-2"
                    style="
                      min-width: 120px;
                      height: 120px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 15px;
                      overflow: hidden;
                    "
                    *ngFor="let x of fileAttached; let i = index"
                  >
                    <div class="mr-2 mt-2">
                      <img
                        *ngIf="
                          getType(x?.name || x?.document) == 'doc' ||
                          getType(x?.name || x?.document) == 'docx'
                        "
                        src="./assets/images/word.svg"
                        height="40"
                      />
                      <img
                        *ngIf="getType(x?.name || x?.document) == 'pdf'"
                        src="./assets/images/pdf.svg"
                        height="40"
                      />
                      <img
                        *ngIf="
                          getType(x?.name || x?.document) == 'xls' ||
                          getType(x?.name || x?.document) == 'xlsx'
                        "
                        src="./assets/images/excel.svg"
                        height="40"
                      />
                      <img
                        *ngIf="getType(x?.name || x?.document) == 'csv'"
                        src="./assets/images/csv.png"
                        height="40"
                      />
                      <mat-icon
                        *ngIf="
                          getType(x?.name || x?.document) !== 'pdf' &&
                          getType(x?.name || x?.document) !== 'doc' &&
                          getType(x?.name || x?.document) !== 'docx' &&
                          getType(x?.name || x?.document) !== 'xls' &&
                          getType(x?.name || x?.document) !== 'xlsx' &&
                          getType(x?.name || x?.document) !== 'csv'
                        "
                        mat-list-icon
                        class="material-icons my-auto fs-1 text-danger"
                      >
                        description</mat-icon
                      >
                    </div>
                    <p class="m-0 pl-2" style="font-size: 10px">
                      {{ x?.name || x?.document }}
                    </p>
                    <mat-icon color="remove" (click)="DeleteFile(i, x?.id)"
                      >clear</mat-icon
                    >
                  </div>
                  <div *ngIf="fileAttached.length == 0" class="mt-4">
                    No document attached.<br /><br />
                  </div>
                </div>
              </div>
            </form>
            <div
              class="pr-4"
              *ngIf="insightType == 'insight' && editInsightFlag"
            >
              <button
                (click)="requestInsight()"
                class="w-100 bg-primary text-white mr-2 ml-2"
                mat-flat-button
                mat-button
                color="primary"
              >
                Request Insight
              </button>
            </div>
          </div>
          <div
            [ngStyle]="{ width: data.mode == 'edit' ? '70%' : '100%' }"
            style="overflow-y: scroll; max-height: 60vh"
            *ngIf="data.mode == 'edit' && insightDocument"
          >
            <pdf-viewer
              [src]="insightDocument"
              [render-text]="true"
              [original-size]="false"
              style="width: 100%; height: 100%"
            ></pdf-viewer>
          </div>
          <div
            [ngStyle]="{ width: data.mode == 'edit' ? '70%' : '100%' }"
            style="overflow-y: scroll; max-height: 60vh"
            *ngIf="data.mode == 'edit' && !insightDocument"
          >
            <div class="d-flex justify-content-center">
              <span>{{
                insightDocumentError
                  ? "Unable to load the document"
                  : "Loading the report..."
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="d-flex justify-content-between">
            <button (click)="cancel()" mat-button color="primary">Close</button>
            <button
              (click)="saveCuratedInsight()"
              class="bg-primary text-white mr-2 ml-2"
              mat-flat-button
              mat-button
              color="primary"
            >
              <span *ngIf="insightType == 'insight'"> Save </span>
              <span *ngIf="insightType != 'insight'"> Action </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mlPopup>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="''"> </app-modal-header-common>
      <div class="modal-body p-4">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Name: </span>

            <p class="mb-0">{{ selectedMl?.name }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: top">
            <span class="sjec krq">Description: </span>

            <p class="mb-0">{{ selectedMl?.pop_up_description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
