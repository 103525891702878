import { Component, OnInit } from '@angular/core';
import { MessageService } from "src/app/shared/services/message.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { PrismService } from 'src/app/shared/services/prism.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FilterService } from 'src/app/shared/services/filter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-people-competency-map',
  templateUrl: './people-competency-map.component.html',
  styleUrls: ['./people-competency-map.component.scss']
})
export class PeopleCompetencyMapComponent implements OnInit {
  headerList:any =['Id','Title','Job Category','Client Location','Qualification','Responsibilities','Personas']
  competencyList:any =[];
  staticText: any = (textConfiguration as any).default;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  firstTimeLoad:boolean = true;
  filterObj:any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private prism : PrismService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.messageService.setGnews("IPDB - People-competency-map");
    this.getData();

    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        this.getData();
      }
    })
    
  }

  getData(){
    this.competencyList = [];
    let queryparam:string = '';
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "pcmTitle":
              queryparam = queryparam+ '&title='+element;
              break;
            case "pcmCategory":
              queryparam = queryparam+ '&job_category='+element;
              break;
            case "pcmLocation":
              queryparam = queryparam+ '&client_location='+element;
              break;
          }}
        )}
      )}
   
    this.prism.getCompetencyList(this.startValue, this.EndValue,queryparam).subscribe((res:any)=>{
      this.competencyList = res;
    });
  }

  getRecords(isFromAttrition?:boolean) {
    if (this.CurrentPage > 0) {
      this.startValue = (parseInt(this.CurrentPage) - 1) * (parseInt(this.sortRecords));
    } else {
      this.startValue = 0
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if(!this.firstTimeLoad){
      this.getData();
    }
    this.firstTimeLoad = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
