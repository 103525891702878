<div class="search">
    <header class="search-Header">
        <div class="w-100 bg-lavendar mt-80">
            <app-header></app-header>
        </div>
    </header>

    <div class="search-body container-fluid container-xxl">
        <div class="searchContainer">
            <div class="searchCard">
                <div class="d-flex flex-column">
                    <div class="border-bottom p-3 pe-4 ps-4">
                        <div class="searchControl container">
                            <div class="d-flex justify-content-between align-items-center">
                                <mat-form-field class="example-full-width w-100 pe-2 ps-2" appearance="outline">
                                    <img class="img-fluid" matPrefix
                                        src="/assets/images/balancescore/balanceheader/search.svg" alt="filter">
                                    <mat-label>{{staticText?.home?.search}}</mat-label>
                                    <input class="text-ellipse" matInput type="text" placeholder="Search..."
                                        [(ngModel)]="searchPage"
                                        (keyup.enter)="getSearchResult();CurrentPage=1;EndValue=10;"
                                        (keyup.backspace)="backSpaceClear()">
                                    <img class="img-fluid cursor-pointer" matSuffix *ngIf="searchPage"
                                        src="/assets/images/pages/searchpage/clear.svg" alt="filter" width="11"
                                        height="11" (click)="clearSearch()">
                                </mat-form-field>

                                <button class="searchButton text-white cursor-pointer pe-2 ps-2 fs-14" mat-raised-button
                                    (click)="getSearchResult();CurrentPage=1;EndValue=10;">{{staticText?.home?.search}}</button>
                            </div>
                            <div class="pt-4">
                                <h5 class="mb-0 font-17 fw-normal">{{staticText?.home?.match_found}}&nbsp;({{searchedList?.length}})</h5>
                            </div>
                        </div>
                    </div>

                    <ng-container>
                        <div class="searchList">
                            <div class="p-3 pe-4 ps-4 container">
                                <div class="searchControl">
                                    <div *ngFor="let List of searchedList">
                                        <div class="row row-cols-2 p-4 falseList" *ngIf="!List?._source?.hide"
                                            #searchList>
                                            <div class="col-1 d-flex justify-content-center align-items-center">
                                                <div class="page-icon"
                                                    *ngIf="!!List?._source?.page_asset?.backgroundcolor == true;else imageOnly"
                                                    [ngStyle]="{'background-color': List?._source?.page_asset?.backgroundcolor}">
                                                    <img [src]="'/assets/images/home/'+List?._source?.page_asset?.ico"
                                                        [alt]="List?._source.page_asset?.ico">
                                                </div>
                                                <ng-template #imageOnly>
                                                    <img [src]="'/assets/images/home/'+List?._source?.page_asset?.ico"
                                                        style="width: 4.063rem !important; height: 4.063rem !important;"
                                                        [alt]="List?._source.page_asset?.ico">
                                                </ng-template>
                                            </div>
                                            <div class="col-11 d-flex justify-content-start align-items-center">
                                                <div class="d-flex flex-column fw-bold cursor-pointer">
                                                    <div class="p-1 text-capitalize">
                                                        <h5 class="fs-16 color-blue text-decoration-underline mb-0"
                                                            (click)="navigateTo(List?._source?.page_asset?.routepath)">
                                                            {{List?._source?.name}}
                                                        </h5>
                                                    </div>
                                                    <div class="page-capsule p-1 text-center text-uppercase text-white">
                                                        <h5 class="fs-14 fw-500 mb-0 mx-2">
                                                            {{List?._source?.type?.replaceAll('_',' ')}}
                                                        </h5>
                                                    </div>
                                                    <!-- <p class="fs-14 mb-0">{{List?.desc}}</p> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="searchStatus !== true">
                                        <div class="noRecords">
                                            <span class="text-capitalize fs-18">
                                                {{searchStatus}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="searchStatus === true">
                                        <div class="row">
                                            <div class="col-12 mx-auto">
                                                <img src="./assets/images/no-record.png"
                                                    class="no-recordsimg" width="100%">
                                            </div>
                                            <div class="col-8 mx-auto">
                                                <p class="text-center fw-bold text-muted">
                                                    {{staticText.common.no_records_found}}
                                                </p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="pe-4 ps-4" *ngIf="searchedResults?.length >= 10 && searchedList.length>0">
                                <div class="d-flex justify-content-center align-items-center color-blue container">
                                    <div class="d-flex flex-row align-items-center cursor-pointer" (click)="loadMore()">
                                        <p class="mb-0">{{staticText?.common?.show_more}}</p>
                                        <i class="fas fa-angle-double-down ml-2 fw-normal"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <footer class="search-Footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-muted">
                            <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>