import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as moment from 'moment';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})

export class ProposalComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  proposalLists: any[] = [];
  proposalListsTotal: any[] = [];
  limit: any;
  offset: any;
  totalCount: any;
  searchText = '';
  search = false;
  filterForm: FormGroup | any;
  filterOptionPortfolio1: Observable<any[]> | any;
  filterOptionSubPortfolio1: Observable<any[]> | any;
  portList: any[] = [];
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  subPortId: any;
  page: number = 1;
  endVal: number = 10;
  startVal: number = 0;
  itemsPerPage: number = 10;
  subPortChildOptions: any[] = [];
  OpportunityFieldList: any;
  disableShowMore: boolean = false;
  scree_name = 'proposal-screen';
  statusList: any;
  status: any;



  constructor(
    private proposalService: GetProposalService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private csvService: DataToCsvService,private datePipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.getOpportunity();
    this.getDataSource();
    this.createForm();
    //this.getStatus();
  }

  getDataSource(isTotal:boolean=false) {
    this.spinner.show();
    let endVal:any = isTotal ? this.totalCount : this.endVal;
    this.proposalService.getPropsals(this.startVal, endVal, this.subPortId, this.searchText, this.portFolioId, this.status).subscribe((res: any) => {
      this.spinner.hide();
      this.totalCount = res.totalRecords;
      let list = res.records;
      list = list.map((i: any) => {
        //i.start_date = !!i.start_date ? moment(i.start_date).format('DD-MM-yyyy  HH:mm') : '';
        i.last_updated_on = !!i.last_updated_on ? moment(i.last_updated_on).format('DD-MM-yyyy  HH:mm') : '';
        i.closure_date = !!i.closure_date ? moment(i.closure_date).format('DD-MM-yyyy  HH:mm') : '';
        return i;
      });
      if(isTotal){
        this.proposalListsTotal = list;
        this.saveAsCSV();
      }else{
        this.proposalLists =list
      }
     }, (error: any) => {
        this.spinner.hide();
        throw error;
      })
  }

  getOpportunity() {
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;

        }
      })
      this.createForm();
    }, error => {
      throw error;
    })
  }

  clearFilter() {
    this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
    })
    this.getDataSource();
  }

  deleteProposal(id: any) {
    this.proposalService.deleteProposal(id).subscribe((res: any) => {
      this.getDataSource();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.toastrService.error('Error');
      throw error;
    })
  }
  editProposal(id: any) {
    this.router.navigate([`rfx/`+btoa(id)+ `/opportunity/setup`]);
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
    });

    this.filterOptionPortfolio1 = this.filterForm?.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
      );
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }

  statusChange(e: any) {
    console.log('e', e);
    this.status = e;
    this.getDataSource();
  }
  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortId = "";
    this.filterForm.patchValue({
      subPortfolio: ''
    })
    let selectedPortfolio = this.portList.find((e:any)=> e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
    if (this.portFolioId==="") {
      this.subPortfilteredOptions = [];
      this.filterOptionSubPortfolio1 = this.filterForm?.get('subPortfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((display_name: any) => display_name ? this.subPortfolioFilter(display_name) : this.subPortfilteredOptions.slice())
        );
    }
    this.getDataSource();
  }


  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    this.getDataSource();
  }


  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }



  changeSubPortfolio(e: any) {
    if (e.isUserInput) {
      this.subPortId = e.source.value.id;
      this.getDataSource();
    }
  }

  viewProposal(id: any) {
    this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`])
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getDataSource();
  }

  searchName() {
    this.getDataSource();
  }
  onInputChange(e: any) {
    if (this.searchText == '') {
      this.getDataSource();
    }
  }

  callRefereshParentData(e: any) {
    this.getDataSource()
  }
  showAllProposals() {
    this.startVal = 0;
    this.endVal = this.proposalLists?.length + (this.itemsPerPage);
    this.getDataSource();
    if (this.totalCount < this.endVal) {
      this.disableShowMore = true;
    }
  }


  // getStatus() {
  //   this.ps.getStatus().subscribe((res: any) => {
  //     this.statusList = res;
  //     console.log('status', res);
  //   }, error => {
  //     throw error;
  //   })
  // }
  
  saveAsCSV() {
    if(this.proposalListsTotal.length > 0){
      const items:any = [];
      this.proposalListsTotal.forEach((item:any) => {
        let last_date_val=item?.last_updated_on;
        if(item?.last_updated_on){
          let date = item?.last_updated_on?.split(" ");
          let last_date = date?.[0]?.split("-")
          last_date_val =  new Date(last_date[1],last_date[0],last_date[2]);
        }
        let csvData = {
          "Opportunity Number": item.opportunity_number,
          "Opportunity Name": item.opportunity_name,
          "Portfolio Name": item?.portfolio_details?.[0]?.display_name,
          "Sub Portfolio Name": item?.portfolio_details?.[1]?.display_name,
          "Proposal Initiated by": item?.initiated_by?.full_name,  
          "Proposal Initiated on": item?.start_date ? this.datePipe.transform(item?.start_date,'dd MMM yyyy'):'',
          "Received on" : item?.received_date ? this.datePipe.transform(item?.received_date,'dd MMM yyyy'):'',
          "Updated on" :last_date_val?this.datePipe.transform(last_date_val,'dd MMM yyyy'):'',
          "Proposal Status":item.status
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Proposal_List.CSV', items);
    }else{
      this.toastrService.error('No Records to download');
    }
  }

}

