import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";

@Component({
  selector: "turnkey-create-modal",
  templateUrl: "turnkey-create-modal.html",
  styleUrls: ["turnkey-create-modal.scss"],
  providers: [DatePipe],
})
export class TurnkeyCreateModalDialogComponent {
  planForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    owner: new FormArray([]),
    workgroup: new FormArray([]),
    start_date: new FormControl(""),
    end_date: new FormControl(""),
  });

  selectedOwners: any = [];
  selectedWorkgroup: any = [];

  constructor(
    public dialogRef: MatDialogRef<TurnkeyCreateModalDialogComponent>,
    private turnkeyTrackerService: TurnkeyTrackerService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  createPlan() {
    let postdata = this.planForm.value;
    postdata.start_date = this.datePipe.transform(
      postdata.start_date,
      "YYYY-MM-dd"
    );
    postdata.end_date = this.datePipe.transform(
      postdata.end_date,
      "YYYY-MM-dd"
    );
    postdata.owner_ids = this.selectedOwners;
    postdata.workgroup = this.selectedWorkgroup;
    postdata.template_id = this.data.template_id;
    postdata.details_id = this.data.accDetailsId;
    this.turnkeyTrackerService.createPlan(postdata).subscribe((res: any) => {
      this.dialogRef.close();
      this.toastrService.success("Plan created successfully...");
      this.router.navigateByUrl("confluence/plan/" + btoa(res.id + ""));
    });
  }

  ownerdata(e: any) {
    this.selectedOwners = e;
    console.log(e);
  }
  workgroupdata(e: any) {
    this.selectedWorkgroup = e;
    console.log(e);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
