import { Component, OnInit } from "@angular/core";
import { MessageService } from "src/app/shared/services/message.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-masters",
  templateUrl: "./masters.component.html",
  styleUrls: ["./masters.component.scss"],
})
export class MastersComponent implements OnInit {
  mastersList: any = [
    { name: "OKR master", url: "/okrconvergence/okr-register" },
    // { name: "Hubble Templates", url: "" },
    { name: "Contract Central", url: "/sowmaster" },
    { name: "Vendor Master", url: "/vendor-master" },
    { name: "Application Master", url: "/application-master" },
    { name: "Rate master", url: "/rate/rate-master" },
    { name: "Organization Hierarchy", url: "prism/masters/organization-hierarchy" },
  ];
  loading: any = false;
  constructor(private router: Router, private messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.setGnews("IPDB - OKR");
  }

  navigateTo(url: any) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
