<app-modal-header-common title="{{ surveyDetails.name }}">
</app-modal-header-common>
<div class="plan-form">
  <h6>{{ this.surveyDetails.name }}</h6>
  <div>
    <iframe
      [src]="surveyURL"
      frameborder="0"
      style="height: 700px; width: 100%; border-radius: 15px"
      marginwidth="0"
      marginheight="0"
      scrolling="auto"
      allow="geolocation"
    ></iframe>
  </div>
</div>
