<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div class="row">
        <div class="col-sm-6 trn-trc">
          <h5>Plan Central</h5>
        </div>
        <div class="col-sm-6 trn-trc-1">
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white"
            aria-label="add"
            (click)="navigate()"
          >
            <mat-icon>add</mat-icon> Add Plan
          </button>
        </div>
      </div>

      <!-- <div class="row mt-2">
        <div class="col-sm-12">
          <div style="overflow-x: scroll">
            <app-filter-card [pagetype]="'turnkey-tracker'"></app-filter-card>
          </div>
        </div>
      </div> -->

      <div class="filter-container mb-2 mt-3">
        <div class="filter-icon">
          <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
        </div>
        <input
          type="text"
          placeholder="Search Category..."
          class="form-control text-input"
          [(ngModel)]="filterCategory"
          (ngModelChange)="search()"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <input
          type="text"
          placeholder="Search Name..."
          class="form-control text-input"
          [(ngModel)]="filterName"
          (ngModelChange)="search()"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterOwner"
            (ngModelChange)="search()"
            placeholder="Search Owner..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let owner of ownersList"
              value="{{ owner.id }}"
              >{{ owner.first_name + " " + owner.last_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <app-filter-card [pagetype]="'plan-central'"></app-filter-card>
        <mat-form-field
          appearance="outline"
          class="filter-dropdown"
          style="font-size: 12px; margin-left: -10px"
        >
          <input
            matInput
            [matDatepicker]="picker1"
            autocomplete="off"
            (click)="picker1.open()"
            [(ngModel)]="filterDueDate"
            (ngModelChange)="search()"
            placeholder="Search due date..."
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterWorkgroup"
            (ngModelChange)="search()"
            placeholder="Search Workgroup..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let workgroup of workgroupList"
              value="{{ workgroup.display_name }}"
              >{{ workgroup.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Plans created by you">
          <div style="overflow-x: hidden">
            <div class="tab-holder mt-3">
              <div class="accordion" id="accordionPanel">
                <div
                  *ngFor="let category of ownPlans; let i = index"
                  class="accordion-item"
                >
                  <h2 class="accordion-header" id="heading{{ i }}">
                    <button
                      class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                      type="button"
                      data-bs-toggle="collapse"
                      attr.data-bs-target="#collapse{{ i }}"
                      aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                      aria-controls="collapse{{ i }}"
                      style="font-size: 13px; font-weight: 700"
                    >
                      {{ category.category }}
                    </button>
                  </h2>
                  <div
                    id="collapse{{ i }}"
                    class="accordion-collapse collapse {{
                      i === 0 ? 'show' : ''
                    }}"
                    aria-labelledby="heading{{ i }}"
                    data-bs-parent="#accordionPanel"
                  >
                    <div class="accordion-body p-2">
                      <div class="flex-container-1">
                        <div
                          *ngIf="category.plans && category.plans.length <= 0"
                          class="trn-no"
                        >
                          You haven't created any plans.
                        </div>
                        <app-loading
                          style="width: 100%; min-height: 200px"
                          *ngIf="!category.plans"
                        ></app-loading>
                        <div
                          class="flex-item p-1"
                          *ngFor="let x of category.plans; let i = index"
                        >
                          <div
                            class="trn-x"
                            [ngStyle]="{ background: x.color }"
                            (click)="openPlan(x)"
                          >
                            <h6 class="mb-0 trn-nm">{{ x.name }}</h6>
                            <p class="mb-0 trn-ph">
                              {{ x.phases.length }} Phases <span>.</span>
                              {{ calcActivitiesCount(x) }} Activities
                            </p>
                            <div class="prg">
                              <mat-progress-bar
                                mode="determinate"
                                [value]="x.completion_percentage"
                              ></mat-progress-bar>
                              <p class="m-0 prg-x">
                                {{ x.completion_percentage }}%
                              </p>
                            </div>
                            <div class="row tmg">
                              <div class="col-sm-9 ta">
                                <p class="mb-0 tap">
                                  Updated {{ x.last_updated_on_dt | timeago }}
                                </p>
                              </div>
                              <div class="col-sm-3 own">
                                <app-owner
                                  [editable]="false"
                                  *ngIf="x?.owners?.length > 0"
                                  [ownerdata]="x.owners"
                                  style="margin-right: 30px"
                                ></app-owner>
                                <mat-icon
                                  *ngIf="x?.owners?.length <= 0"
                                  class="mi"
                                  >perm_identity</mat-icon
                                >
                              </div>
                            </div>
                            <button
                              mat-icon-button
                              class="action-vert"
                              color="warn"
                              (click)="
                                deletePlan(x.id, i); $event.stopPropagation()
                              "
                            >
                              <mat-icon style="font-size: 15px; color: red"
                                >delete</mat-icon
                              >
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="ownPlans?.length === 0" class="text-center">
                You have not created any plans
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Plans you are collaborating on">
          <div style="overflow-x: hidden">
            <div class="tab-holder mt-3">
              <div class="accordion" id="accordionPanel">
                <div
                  *ngFor="let category of otherPlans; let i = index"
                  class="accordion-item"
                >
                  <h2 class="accordion-header" id="heading{{ i }}">
                    <button
                      class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                      type="button"
                      data-bs-toggle="collapse"
                      attr.data-bs-target="#collapse{{ i }}"
                      aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                      aria-controls="collapse{{ i }}"
                      style="font-size: 13px; font-weight: 700"
                    >
                      {{ category.category }}
                    </button>
                  </h2>
                  <div
                    id="collapse{{ i }}"
                    class="accordion-collapse collapse {{
                      i === 0 ? 'show' : ''
                    }}"
                    aria-labelledby="heading{{ i }}"
                    data-bs-parent="#accordionPanel"
                  >
                    <div class="accordion-body p-2">
                      <div class="flex-container-1">
                        <app-loading
                          style="width: 100%; min-height: 200px"
                          *ngIf="!otherPlans"
                        ></app-loading>
                        <div
                          *ngIf="category.plans && category.plans.length <= 0"
                          class="trn-no"
                        >
                          There are currently no available plans for
                          collaboration.
                        </div>
                        <div
                          class="flex-item p-1"
                          *ngFor="let x of category.plans"
                        >
                          <div
                            class="trn-x"
                            [ngStyle]="{ background: x.color }"
                            (click)="openPlan(x)"
                          >
                            <h6 class="mb-0 trn-nm">{{ x.name }}</h6>
                            <p class="mb-0 trn-ph">
                              {{ x.phases.length }} Phases <span>.</span>
                              {{ calcActivitiesCount(x) }} Activities
                            </p>
                            <div class="prg">
                              <mat-progress-bar
                                mode="determinate"
                                [value]="x.completion_percentage"
                              ></mat-progress-bar>
                              <p class="m-0 prg-x">
                                {{ x.completion_percentage }}%
                              </p>
                            </div>
                            <div class="row tmg">
                              <div class="col-sm-9 ta">
                                <p class="mb-0 tap">
                                  Updated {{ x.last_updated_on_dt | timeago }}
                                </p>
                              </div>
                              <div class="col-sm-3 own">
                                <app-owner
                                  [editable]="false"
                                  *ngIf="x?.owners?.length > 0"
                                  [ownerdata]="x.owners"
                                  style="margin-right: 30px"
                                ></app-owner>
                                <mat-icon
                                  *ngIf="x?.owners?.length <= 0"
                                  class="mi"
                                  >perm_identity</mat-icon
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="otherPlans?.length === 0" class="text-center">
                You are not collaborating to any plan
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
    </div>
  </div>
</div>
