<app-modal-header-common
  title="{{ isReadOnly ? 'Survey Info' : 'Create Survey' }}"
>
</app-modal-header-common>
<div class="plan-form">
  <p *ngIf="!isTemplateCreation">
    Please upload the excel format of your survey with relevant details. Our
    team will create it within 24-48 hours
  </p>

  <div class="row mb-3">
    <div class="col-sm-3 label">File upload</div>
    <div class="col-sm-9 value">
      <input
        type="file"
        (change)="this.onFileChange($event)"
        #fileInput
        hidden
      />
      <div class="d-flex align-items-center gap-2">
        <mat-icon *ngIf="!isReadOnly" (click)="fileInput.click()"
          >cloud_upload</mat-icon
        >
        <mat-icon *ngIf="isReadOnly" (click)="downloadFile()"
          >cloud_download</mat-icon
        >
        <span *ngIf="fileName">{{ fileName }}</span>
      </div>
    </div>
  </div>
  <form
    [formGroup]="templateForm"
    ngClass="{{ isReadOnly ? 'read-only' : '' }}"
  >
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <div class="row" *ngIf="!isTemplateCreation">
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="start_date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker1">
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="end_date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Applicability</mat-label>
      <input matInput formControlName="applicability" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Scoring methodology</mat-label>
      <input matInput formControlName="scoring_methodology" />
    </mat-form-field>
    <div *ngIf="isTemplateCreation">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let option of typeList" [value]="option.id"
            >{{ option.display_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!isTemplateCreation">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Scoring scale</mat-label>
        <input matInput formControlName="scoring_scale" />
      </mat-form-field>
    </div>
    <div *ngIf="isTemplateCreation">
      <div class="row mt-1">
        <div class="col-sm-10">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Link URL</mat-label>
            <input
              matInput
              [(ngModel)]="linkURL"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
        <div
          class="col-sm-2"
          style="display: flex; justify-content: space-between"
        >
          <button mat-icon-button color="success" (click)="addLink()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" style="border-radius: 20px">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Links ({{ surveyLinks.length }})
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div style="overflow-x: scroll">
            <div
              class="d-flex flex-row flex-nowrap overflow-auto"
              style="padding: 10px"
            >
              <div
                class="card card-block mx-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  border-radius: 15px;
                  cursor: pointer;
                "
                *ngFor="let link of surveyLinks.controls; let i = index"
                (click)="openLink(link.value)"
              >
                <div class="d-flex align-items-center">
                  <mat-icon class="lk">link</mat-icon>
                  <p class="m-0 asw">{{ link.value }}</p>
                  <mat-icon color="warn" (click)="removeLink(i)"
                    >clear</mat-icon
                  >
                </div>
              </div>
              <div *ngIf="surveyLinks.length == 0">No links added</div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <br />
    <app-tag
      [noIds]="true"
      [tagsIds]="assessmentTags"
      (childTags)="getChildTags($event)"
    ></app-tag>
    <div
      *ngIf="!isReadOnly"
      style="display: flex; justify-content: end"
      class="mt-3"
    >
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="createSurvey()"
        [disabled]="templateForm.invalid"
      >
        Create
      </button>
    </div>
  </form>
</div>
