import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SurveyService } from "src/app/shared/services/survey.service";
import { TagsService } from "src/app/shared/services/tag.service";

@Component({
  selector: "app-create-survey-dialog",
  templateUrl: "./create-survey-dialog.component.html",
  styleUrls: ["./create-survey-dialog.component.scss"],
  providers: [DatePipe],
})
export class CreateSurveyDialogComponent implements OnInit {
  assessmentTags: any;
  linkURL: any;
  file: any;
  fileName: any;
  isTemplateCreation: boolean;
  typeList: any;
  surveyInfo: any;
  isReadOnly: boolean = false;
  templateForm = this.fb.group({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    applicability: new FormControl(""),
    scoring_methodology: new FormControl(""),
    type: new FormControl(""),
    survey_link: this.fb.array([]),
    start_date: new FormControl(""),
    end_date: new FormControl(""),
    scoring_scale: new FormControl(""),
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateSurveyDialogComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService,
    private tagService: TagsService,
    private surveyService: SurveyService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getTypeList();
    this.isTemplateCreation = this.data.isTemplate;
    this.surveyInfo = this.data.surveyInfo;
    if (this.surveyInfo) {
      this.assessmentTags = this.surveyInfo.tag_ids;
      this.templateForm.patchValue(this.surveyInfo);
      if (this.isTemplateCreation) {
        this.updateSurveyLinks();
      }
      this.fileName = this.surveyInfo.file_name;
      this.isReadOnly = true;
    }
  }

  updateSurveyLinks() {
    const surveyLinksArray = this.templateForm.get("survey_link") as FormArray;
    this.surveyInfo.survey_link.forEach((url: string) => {
      surveyLinksArray.push(this.fb.control(url));
    });
  }

  get surveyLinks() {
    return this.templateForm.get("survey_link") as FormArray;
  }

  addLink() {
    const surveyLinksArray = this.templateForm.get("survey_link") as FormArray;
    surveyLinksArray.push(this.fb.control(this.linkURL));
    this.linkURL = "";
  }

  removeLink(index: number) {
    const surveyLinksArray = this.templateForm.get("survey_link") as FormArray;
    surveyLinksArray.removeAt(index);
  }

  getChildTags(data: any) {
    this.assessmentTags = data;
  }

  async addTags(id: any, selectedTags: any) {
    if (selectedTags?.length > 0) {
      await selectedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: this.isTemplateCreation
            ? "survey_template"
            : "survey_instance",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  createHubbleSurvey(payload: any) {
    this.surveyService.createHubbleSurvey(payload).subscribe((resp: any) => {
      this.addTags(resp.id, this.assessmentTags);
    });
  }

  getTypeList() {
    this.surveyService.getTypeList().subscribe((res: any) => {
      this.typeList = res;
    });
  }

  downloadFile() {
    this.spinner.show();
    this.surveyService
      .downloadFile(this.surveyInfo.id, this.isTemplateCreation)
      .subscribe(
        (res: any) => {
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(res);
          downloadLink.setAttribute("download", this.fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.toastrService.success("File downloaded successfully");
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  createSurveyTemplate(data: any) {
    this.surveyService.createSurvey(data).subscribe((resp: any) => {
      this.spinner.hide();
      this.addTags(resp.id, this.assessmentTags);
    });
  }

  createSurvey() {
    let formData: any = new FormData();
    let postdata = this.templateForm.value;
    formData.append("file", this.file);
    formData.append("name", postdata.name);
    formData.append("description", postdata.description);
    formData.append("applicability", postdata.applicability);
    formData.append("scoring_methodology", postdata.scoring_methodology);
    formData.append("survey_link", postdata.survey_link);
    formData.append("type", postdata.type);
    if (!this.isTemplateCreation) {
      formData.append(
        "start_date ",
        this.datePipe.transform(postdata.start_date, "YYYY-MM-dd")
      );
      formData.append(
        "end_date",
        this.datePipe.transform(postdata.end_date, "YYYY-MM-dd")
      );
      formData.append("scoring_scale", postdata.scoring_scale);
    }
    if (!this.isTemplateCreation) {
      this.createHubbleSurvey(formData);
    } else {
      this.createSurveyTemplate(formData);
    }
    this.dialogRef.close(formData);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }

  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    event.target.value = "";
  }
}
