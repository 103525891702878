import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from './../../../shared/constant/constant';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  userDomain= window.location.host?.split('.')?.[0];
  user: any;
  staticText: any = (textConfiguration as any).default;
  notificationList: any = {};
  pageKey: any;
  ipdbURL= environment.ipdbURL;
  constructor(private router: Router, private spinner:NgxSpinnerService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.getPagesData();
  }
  navigateTo(link: string) {
    this.router.navigate([link])
  }

  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, '_blank');
  }

  getNotificationsByPage(pageKey: any) {
    this.spinner.show()
    this.notificationService.getNotificationsByPage(pageKey).subscribe((notificationList: any) => {
      this.spinner.hide()
      let key = pageKey;
      if (key.includes('-')) {
        key = pageKey.split('-')[0]
      };
      this.notificationList = notificationList.notifications;
      this.notificationList = this.notificationList?.map((mapData: any) => {
        mapData['routepath'] = this.routePage(mapData?.subject?.toLowerCase(), mapData?.details);
        return mapData;
      }) ?? []
      // if (this.notificationList[key].length > 1) this.notificationList[key].length = 2;
      // else this.notificationList[key]
    },err=>this.spinner.hide())
  }

  getPagesData() {
    let pages: any = ['bid-awards', 'deal-structuring', 'delivery-performance', 'bvt', 'work-force-dashboard', 'okr', 'continuous-improvement']
    pages.forEach((pageKey: any) => {
      this.getNotificationsByPage(pageKey);
    });
  }

  routePage(Page: any, routedata: any) {
    let routeId = routedata?.id;
    let routepath: any;
    switch (Page) {
      case 'proposal':
        routepath = `/getproposal/${btoa(routeId)}/proposal-overview`;
        break;
      case 'opportunity':            
        if(routedata?.status?.label =='Published' || routedata?.status?.label =='SoW Signed'){          
          routepath = `/getproposal/`+btoa(routeId)+`/proposal-evaluation`;
        }else{
          routepath = `/rfx/`+btoa(routeId)+`/opportunity/preview`;
        }        
        break;
      case 'delivery':
        routepath = `/balancedscorecard/dashboard?project=${btoa(routeId)}`;
        break;
      case 'bvt':
        routepath = '/BVT/list';
        break;
      case 'okr':
        routepath = '/okrconvergence';
        break;
        
      case 'workforcedashboard':
        routepath = `/workforce/dashboard`;
        break;
      case 'ci':
        routepath = `/innovation/detail-ideas/${btoa(routeId)}`;
        break;
      default:
        routepath = '/home';
        break;
    }    
    return routepath;

  }

  MarkAsReadNotification(id: number, pageKey: any, routePath: any) {
    this.spinner.show()
    this.notificationService.getAllReadNotifications(id, pageKey).subscribe((getReadStatus: any) => {
      this.spinner.hide()
      if (getReadStatus?.status === 200) {
        let key = pageKey;
        if (key.includes('-')) {
          key = pageKey.split('-')[0]
        };
        this.notificationList[key] = getReadStatus?.body?.notifications;
        this.router.navigate([routePath]);
      }
    },err=>this.spinner.hide());
  }

}
