<div class="single-center-pane">
  <div class="row">
    <div
      class="d-flex align-items-center"
      role="button"
      (click)="navigateToGovernanceLighthouse()"
    >
      <mat-icon>keyboard_backspace</mat-icon>
      <strong class="ml-2">Go back</strong>
    </div>
    <div class="col-sm-4" style="display: flex; align-items: center">
      <h6 class="m-0">Governance Lighthouse Library</h6>
    </div>
    <div
      *ngIf="!readMode"
      class="col-sm-8"
      style="display: flex; align-items: center"
    >
      <button
        mat-flat-button
        color="primary"
        class="mr-1"
        style="width: 100%"
        (click)="templateDownload()"
      >
        <mat-icon>cloud_download</mat-icon> &nbsp;
        <span>Download Template</span>
      </button>
      <input
        (change)="this.onFilechange($event)"
        class="form-control"
        type="file"
        #formFile
        hidden
      />
      <button
        mat-flat-button
        color="primary"
        class="mr-1"
        style="width: 100%"
        (click)="formFile.click()"
      >
        <mat-icon>cloud_upload</mat-icon> &nbsp;
        <span>Upload Template</span>
      </button>
    </div>
  </div>
  <app-loading *ngIf="!templatesLoaded"></app-loading>
  <div *ngIf="templatesLoaded">
    <div class="filter-container my-2">
      <div class="filter-icon">
        <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
      </div>
      <input
        type="text"
        placeholder="Search Category..."
        class="form-control text-input"
        [(ngModel)]="filterCategory"
        (ngModelChange)="search()"
        style="
          min-width: 13rem;
          border-radius: 8px;
          font-size: 12px;
          height: 40px;
        "
      />
      <input
        type="text"
        placeholder="Search Name..."
        class="form-control text-input"
        [(ngModel)]="filterName"
        (ngModelChange)="search()"
        style="
          min-width: 13rem;
          border-radius: 8px;
          font-size: 12px;
          height: 40px;
        "
      />
      <input
        type="text"
        placeholder="Search Applicability..."
        class="form-control text-input"
        [(ngModel)]="filterApplicability"
        (ngModelChange)="search()"
        style="
          min-width: 13rem;
          border-radius: 8px;
          font-size: 12px;
          height: 40px;
        "
      />
      <app-filter-card
        [pagetype]="'governance-assessment-template'"
      ></app-filter-card>
      <mat-form-field
        class="w-100 filter-dropdown"
        appearance="outline"
        style="font-size: 12px; margin-left: -10px"
      >
        <mat-select
          [(ngModel)]="filterOwner"
          (ngModelChange)="search()"
          placeholder="Search Owner..."
        >
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let owner of ownersList" value="{{ owner.id }}">{{
            owner.first_name + " " + owner.last_name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mt-2">
      <div class="accordion" id="accordionPanel">
        <div
          *ngFor="let category of filteredTemplate; let i = index"
          class="accordion-item"
        >
          <h2 class="accordion-header" id="heading{{ i }}">
            <button
              class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
              type="button"
              data-bs-toggle="collapse"
              attr.data-bs-target="#collapse{{ i }}"
              aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
              aria-controls="collapse{{ i }}"
              style="font-size: 13px; font-weight: 700"
            >
              {{ category.category }}
            </button>
          </h2>
          <div
            id="collapse{{ i }}"
            class="accordion-collapse collapse {{ i === 0 ? 'show' : '' }}"
            aria-labelledby="heading{{ i }}"
            data-bs-parent="#accordionPanel"
          >
            <div class="accordion-body p-2">
              <div class="flex-container-1">
                <div
                  class="flex-item p-1"
                  *ngFor="let x of category.templates; let i = index"
                  (click)="previewAssessment(x)"
                  style="position: relative"
                >
                  <div
                    style="
                      position: relative;
                      cursor: pointer;
                      padding: 10px;
                      height: 13rem;
                      border-radius: 10px;
                      background-size: cover;
                      background-position: center;
                      overflow: hidden;
                      border: 0.5px solid grey;
                    "
                    [ngStyle]="{ 'background-color': x.color }"
                  >
                    <div style="color: white" class="mb-3">
                      <h5 style="font-size: 11px">Total Questions</h5>
                      <h3>{{ x.questions.length }}</h3>
                    </div>
                    <div style="color: white">
                      <h5 style="font-size: 11px" class="m-0">Created Date</h5>
                      <h4 style="font-size: 13px">
                        {{ x.created_on | date : "dd MMM, yyyy" }}
                      </h4>
                    </div>
                    <div
                      class="mb-0 mt-2 trn-ph"
                      style="color: white"
                      *ngIf="x.created_by"
                    >
                      <div class="d-flex align-items-center">
                        <mat-icon class="small-icon mr-1"
                          >perm_identity</mat-icon
                        >
                        <span style="font-size: 11px">Owner</span>
                      </div>
                      <span
                        style="display: flex; font-weight: 700; font-size: 13px"
                      >
                        {{
                          x.created_by.first_name + " " + x.created_by.last_name
                        }}
                      </span>
                    </div>
                    <div class="overlayer"></div>
                    <div
                      style="
                        position: absolute;
                        width: 100%;
                        background: #0000008c;
                        padding: 10px;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        bottom: 0;
                        left: 0;
                        height: 50px;
                        font-size: 10px;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <h6
                        class="mb-0"
                        style="
                          font-weight: 800;
                          font-size: 12px;
                          color: white;
                          text-align: center;
                          height: 43px;
                          overflow: hidden;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ x.name }}
                      </h6>
                    </div>
                  </div>

                  <button
                    mat-icon-button
                    class="action-vert"
                    color="warn"
                    (click)="
                      deleteTemplate(x.id, x.name, i); $event.stopPropagation()
                    "
                  >
                    <mat-icon style="font-size: 15px; color: red"
                      >delete</mat-icon
                    >
                  </button>

                  <!-- <button mat-icon-button color="primary" [mat-menu-trigger-for]="actionmenu" class="action-vert" (click)="$event.stopPropagation()">
                      <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionmenu="matMenu">
                      <button mat-menu-item  (click)="deleteTemplate(x.id)">
                          <span>Delete Template</span>
                      </button>
                  </mat-menu> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
