import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-survey-embed-dialog",
  templateUrl: "./survey-embed-dialog.component.html",
  styleUrls: ["./survey-embed-dialog.component.scss"],
})
export class SurveyEmbedDialogComponent implements OnInit {
  surveyDetails: any;
  surveyURL: any;
  constructor(
    public dialogRef: MatDialogRef<SurveyEmbedDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.surveyDetails = this.data.item;
    this.surveyURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.url
    );
  }
}
