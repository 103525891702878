import { E } from "@angular/cdk/keycodes";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import * as Highcharts from "highcharts";
const noData = require("highcharts/modules/no-data-to-display");
noData(Highcharts);
@Component({
  selector: "app-governance-assessment-result",
  templateUrl: "./governance-assessment-result.component.html",
  styleUrls: ["./governance-assessment-result.component.scss"],
})
export class GovernanceAssessmentResultComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  staticText: any = (textConfiguration as any).default;
  users: any;
  assessment: any;
  assessmentId: any;
  usersList: any = [];
  submittedUsersList: any = [];
  questionsObj: any = [];
  answers: any = [];
  selectedUsr: any;
  opportunitNumber: any;
  tableColumns: any = ["Group", "Question", "Responses"];
  instanceTags: any;
  aggregateData: any;
  individualData: any;
  userResponse: any;
  userChartData: any;
  assessmentInfo: any;
  audienceInfo: any;

  constructor(
    private router: Router,
    private governanceLighthouseService: GovernanceLighthouseService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.assessmentId = atob(params.id);
      this.getUserList();
      this.getAggregateUserResponse();
    });
  }

  getUserList() {
    this.governanceLighthouseService.getUsers().subscribe((res: any) => {
      this.users = res;
      this.getAssessmentById();
    });
  }

  getAggregateUserResponse() {
    this.governanceLighthouseService
      .getAggregateResponseDetails(this.assessmentId)
      .subscribe((response: any) => {
        this.aggregateData = response.group_wise_data;
        this.aggregateData.forEach((group: any) => {
          group.questions.forEach((question: any) => {
            question["chart"] = this.getChartData(question);
          });
        });
        this.individualData = response.user_counts;
      });
  }

  initializeAssessmentInfo() {
    this.assessmentInfo = "";
    if (this.assessment.portfolio?.parent_portfolio?.display_name) {
      this.assessmentInfo +=
        this.assessment.portfolio.parent_portfolio.display_name + " | ";
    }
    if (this.assessment.portfolio?.display_name) {
      this.assessmentInfo += this.assessment.portfolio.display_name + " | ";
    }
    if (this.assessment.program?.name) {
      this.assessmentInfo += this.assessment.program.name + " | ";
    }
    if (this.assessment.project?.project_name) {
      this.assessmentInfo += this.assessment.project.project_name + " | ";
    }
    if (this.assessment.vendor?.name) {
      this.assessmentInfo += this.assessment.vendor.name;
    }
    this.assessmentInfo = this.assessmentInfo.trimEnd();
    if (this.assessmentInfo.endsWith("|")) {
      this.assessmentInfo = this.assessmentInfo.slice(0, -1);
    }
  }

  initializeAudienceInfo() {
    this.audienceInfo = "";
    this.assessment.audiance.forEach((item: any) => {
      this.audienceInfo += item.first_name + " " + item.last_name + ", ";
    });
    this.audienceInfo = this.audienceInfo.trimEnd();
    if (this.audienceInfo.endsWith(",")) {
      this.audienceInfo = this.audienceInfo.slice(0, -1);
    }
  }

  getAssessmentById() {
    this.governanceLighthouseService
      .getAssessmentById(this.assessmentId)
      .subscribe((res: any) => {
        this.assessment = res;
        this.initializeAssessmentInfo();
        this.initializeAudienceInfo();
        this.instanceTags = res.tag_ids;
        this.assessment.user.forEach((element: any) => {
          let obj = this.users.filter((e: any) => e.id == element);
          obj[0].submission = false;
          let index = this.assessment.responses.findIndex(
            (e: any) => e.user == element
          );
          if (index > -1) {
            obj[0].submission = true;
            this.submittedUsersList.push(obj[0]);
            this.usersList.push(obj[0]);
          } else {
            obj[0].submission = false;
            this.usersList.push(obj[0]);
          }
        });

        this.assessment.questions.forEach((element: any) => {
          let userAns: any = [];
          this.submittedUsersList.forEach((r: any) => {
            let userAnswers = this.assessment.responses.filter(
              (e: any) => e.user == r.id
            );
            let quesObj = userAnswers[0].answersObj.filter(
              (e: any) => e.question_id == element.id
            );
            userAns.push(quesObj[0]);
          });

          this.questionsObj.push({
            question: element.question,
            users: userAns,
          });
        });
      });
  }

  generateIndividualGraphData() {
    const xAxis = Object.keys(this.userResponse.display_score_data);
    const yAxis = Object.values(this.userResponse.display_score_data);
    this.userChartData = {
      chart: { type: "category", heigth: 180 },
      credits: { enabled: false },
      dataLabels: { enabled: true },
      exporting: { enabled: false },
      legend: { enabled: true },
      series: [
        {
          data: yAxis,
          dataLabels: { enabled: true },
          name: "Responses",
          type: "column",
        },
      ],
      title: { text: "" },
      tooltip: { shared: true },
      xAxis: {
        categories: xAxis,
        scrollbar: { enabled: false },
      },
      yAxis: { title: false },
      display_text: "Respondent response summary",
      id: this.userResponse.user_id,
      question_type: "matrix_grid",
    };
  }

  getChartData(questionData: any) {
    const xAxis = Object.keys(questionData.display_score_data);
    const yAxis = Object.values(questionData.display_score_data);
    return {
      chart: { type: "category", heigth: 180 },
      credits: { enabled: false },
      dataLabels: { enabled: true },
      exporting: { enabled: false },
      legend: { enabled: true },
      series: [
        {
          data: yAxis,
          dataLabels: { enabled: true },
          name: "Responses",
          type: "column",
        },
      ],
      title: { text: "" },
      tooltip: { shared: true },
      xAxis: {
        categories: xAxis,
        scrollbar: { enabled: false },
      },
      yAxis: { title: false },
      display_text: "Respondent response summary",
      id: questionData.question_id,
      question_type: "matrix_grid",
    };
  }

  getIndividualResponse() {
    this.userResponse = this.individualData.find(
      (resp: any) => resp.user_id === this.selectedUsr
    );
    this.generateIndividualGraphData();
  }

  viewResult(user: any) {
    this.selectedUsr = user.id;
    this.getIndividualResponse();
    let filtered = this.assessment.responses.filter(
      (e: any) => e.user == user.id
    )[0];
    this.answers = [];
    if (user.submission) {
      filtered.answersObj.forEach((element: any) => {
        let qobj = this.getQuestion(element.question_id);
        element.group = qobj.group_name;
        element.question = qobj.question;
        element.context = qobj.context;
        let i = this.answers.findIndex((e: any) => e.group == element.group);
        if (i > -1) {
          this.answers[i].questions.push(element);
        } else {
          this.answers.push({
            group: element.group,
            questions: [element],
          });
        }
      });

      // this.answers = this.assessment.responses.filter((e: any) => e.user === user.id)[0].answersObj;
    } else {
      this.answers = [];
    }
  }

  fileDownload(question_id: any, fileName: any) {
    this.spinner.show();
    this.governanceLighthouseService
      .fileDownload(this.assessment.id, question_id, this.selectedUsr)
      .subscribe(
        (res: any) => {
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(res);
          downloadLink.setAttribute("download", fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.toastrService.success("File downloaded successfully");
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  getQuestion(q: any) {
    return this.assessment.questions.filter((e: any) => e.id === q)[0];
  }

  getTagObject(id: any) {
    return {
      entity_id: id,
      entity_type: "okr",
    };
  }
}
