import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as Highcharts from "highcharts";
import { NgxPermissionsService } from "ngx-permissions";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { IReportEmbedConfiguration, models } from "powerbi-client";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { MessageService } from "src/app/shared/services/message.service";
import { SurveyService } from "src/app/shared/services/survey.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { PowerBiService } from "../../../shared/services/power-bi.service";
import { CreateSurveyDialogComponent } from "./create-survey-dialog/create-survey-dialog.component";
import { SurveyEmbedDialogComponent } from "./survey-embed-dialog/survey-embed-dialog.component";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { DatePipe } from "@angular/common";
import { CreatePulseDialogComponent } from "./create-pulse-dialog/create-pulse-dialog.component";
import { FilterService } from "src/app/shared/services/filter.service";

const noData = require("highcharts/modules/no-data-to-display");
noData(Highcharts);

@Component({
  selector: "app-hubble",
  templateUrl: "./hubble.component.html",
  styleUrls: ["./hubble.component.scss"],
  providers: [DatePipe],
})
export class HubbleComponent implements OnInit {
  @Output() surveyIdEmit = new EventEmitter();
  @Output() loadData = new EventEmitter<string>();
  totalSurveyList: any = [];
  totalSurveyClosedList: any = [];
  publishedSurveyList: any;
  totalPublishedCount: any;
  Highcharts: typeof Highcharts = Highcharts;
  staticText: any = (textConfiguration as any).default;
  filteredTeamList: any;
  teamList: any;
  teamCreateForm: FormGroup | any;
  surveyURL: any;
  allowed: any;
  reportModel: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  reportConfig = this.reportModel;
  reportConfigSUnBurst = this.reportModel;
  sentimentGraph = this.reportModel;
  reportConfigRadarChart = this.reportModel;
  rightPanelBiReport: any;
  zoomLevel: any;
  surveySummary: any;
  loading: any = false;
  reportClass = "report-container";
  backgroundColors: any = [];
  activeTab: number = 0;
  filterName: any = null;
  filterOwner: any = null;
  filterApplicability: any = null;
  filterDueDate: any = null;
  ownersList: any = [];
  pulsesList: any = [];
  pulseInfo: any;
  filterPortfolio: any = null;
  filterSubPortfolio: any = null;
  filterProject: any = null;
  filterProgram: any = null;
  filterVendor: any = null;
  filterPerformance: any = null;
  filterType: any = null;
  portfolioList: any = [];
  subportfolioList: any = [];
  programList: any = [];
  projectList: any = [];
  vendorList: any = [];
  performanceImpactList: any[];
  dataLoaded: boolean = false;
  pulseLoaded: boolean = false;

  constructor(
    private surveyService: SurveyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private permissionsService: NgxPermissionsService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private PowerBiService: PowerBiService,
    private assessmentCentralService: AssessmentCentralService,
    private datePipe: DatePipe,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.permissionsService.loadPermissions(
      JSON.parse(localStorage.getItem("permission") || "{}").permissions
    );
    this.getFilterOptions();
    this.teamCreateForm = this.formBuilder.array([]);
    this.messageService.setGnews("Hubble - Home page");
    this.allowed = this.permissionsService.getPermission("nps.add_survey")
      ? true
      : false;
    if (this.allowed) {
      this.getTeamList();
    }
    // setTimeout((e: any) => {

    // }, 400);
  }

  getFilterOptions() {
    this.getOwners();
    this.getPortfolioList();
    this.getSubportfolioList();
    this.getProgramList();
    this.getProjectList();
    this.getVendorList();
    this.getPerformanceImpactList();
  }

  getPerformanceImpactList() {
    this.surveyService.getPerformanceImpactList().subscribe((resp: any) => {
      this.performanceImpactList = resp;
    });
  }

  getVendorList() {
    this.filterService.getVendor().subscribe((resp) => {
      this.vendorList = resp;
    });
  }

  getProjectList() {
    this.filterService
      .getProject(this.filterSubPortfolio, this.filterProgram)
      .subscribe((resp) => {
        this.projectList = resp;
      });
  }

  getProgramList() {
    this.filterService.getProgram(this.filterSubPortfolio).subscribe((resp) => {
      this.programList = resp;
    });
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getPortfolioList() {
    this.filterService.getPortfolios().subscribe((resp: any) => {
      this.portfolioList = resp.records;
    });
  }

  getSubportfolioList() {
    this.filterService
      .getSubPortfolios(this.filterPortfolio)
      .subscribe((resp) => {
        this.subportfolioList = resp;
      });
  }

  handleResponse(res: any) {
    res?.records?.open_surveys.forEach((element: any) => {
      if (this.allowed && element.survey) {
        element.survey.respondents.forEach((respon: any) => {
          respon.first_name = this.teamList?.filter(
            (e: any) => e.mail === respon?.user?.email
          )[0]?.first_name;
          respon.last_name = this.teamList?.filter(
            (e: any) => e.mail === respon?.user?.email
          )[0]?.last_name;
          respon.mail = this.teamList?.filter(
            (e: any) => e.mail === respon?.user?.email
          )[0]?.mail;
        });
      }
      this.teamCreateForm.push(
        this.formBuilder.control({
          id: new FormControl(element.id),
          name: new FormControl(element.name),
          url: new FormControl(element.url),
          start_date: new FormControl(element.start_date),
          end_date: new FormControl(element.end_date),
          respondents:
            this.allowed && element.survey
              ? this.formBuilder.array(element.survey.respondents)
              : [],
          input: new FormControl(""),
          response_count: new FormControl(element.survey?.response_count),
          survey_id: new FormControl(element.survey?.survey_id),
          backend_survey_id: new FormControl(element.survey?.id),
        })
      );
      this.backgroundColors.push(this.getGMapRandomColor());
    });
    this.totalSurveyList = res?.records?.open_surveys;
    this.totalSurveyList.forEach((element: any) => {
      element.input = new FormControl("");
    });

    res?.records?.closed_surveys.forEach((element: any) => {
      if (this.allowed && element.survey) {
        element.survey?.respondents.forEach((respon: any) => {
          respon.first_name = this.teamList.filter(
            (e: any) => e.mail === respon.user.email
          )[0]?.first_name;
          respon.last_name = this.teamList.filter(
            (e: any) => e.mail === respon.user.email
          )[0]?.last_name;
          respon.mail = this.teamList.filter(
            (e: any) => e.mail === respon.user.email
          )[0]?.mail;
        });
      }
      this.totalSurveyClosedList.push({
        id: element.id,
        name: element.name,
        url: element.url,
        start_date: element.start_date,
        end_date: element.end_date,
        respondents:
          this.allowed && element.survey ? element.survey.respondents : [],
        color: this.getGMapRandomColor(),
        response_count: element.survey?.response_count,
      });
    });
  }

  getDataSource() {
    this.dataLoaded = false;
    this.spinner.show();
    this.teamCreateForm.clear();
    this.backgroundColors = [];
    this.totalSurveyClosedList = [];
    const tabName =
      this.activeTab == 0
        ? "created"
        : this.activeTab == 1
        ? "respondent"
        : "pulse";
    const dt = this.datePipe.transform(this.filterDueDate, "YYYY-MM-dd");
    this.surveyService.getSurveyList().subscribe((resp: any) => {
      this.surveyService
        .getFilteredHubbleSurveys(
          this.filterName,
          this.filterApplicability,
          this.filterOwner,
          dt,
          tabName
        )
        .subscribe((res: any) => {
          this.spinner.hide();
          this.handleResponse(res);
          this.dataLoaded = true;
        });
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  showCreateSurveyDialog() {
    const dialog = this.dialog.open(CreateSurveyDialogComponent, {
      width: "500px",
      data: {
        isTemplate: false,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getDataSource();
      }
    });
  }

  showCreatePulseDialog() {
    const dialog = this.dialog.open(CreatePulseDialogComponent, {
      width: "500px",
      data: {},
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        setTimeout(() => {
          this.getPulse();
        }, 1000);
      }
    });
  }

  navigateToSurveyCentral() {
    this.router.navigateByUrl("performanceInsights/survey-central");
  }

  showSurveyEmbedDialog(itemId: any) {
    const selectedSurvey = this.totalSurveyList.find(
      (item: any) => item.id === itemId
    );
    this.getSurveyDetails(selectedSurvey.survey);
  }

  showEditSurveyDialog(survey: any) {
    // if (survey.value.survey_id.value) {
    //   return;
    // }
    const selectedSurvey = this.totalSurveyList.find(
      (item: any) => item.id === survey.value.id.value
    );
    const dialog = this.dialog.open(CreateSurveyDialogComponent, {
      width: "500px",
      data: {
        isTemplate: false,
        surveyInfo: selectedSurvey,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }

  showEditPulseDialog(pulse: any) {
    const dialog = this.dialog.open(CreatePulseDialogComponent, {
      width: "500px",
      data: {
        pulseId: pulse.id,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getPulse();
      }
    });
  }

  getSurveyDetails(item: any) {
    this.spinner.show();
    this.surveyService.getSurveyDetails(item.id).subscribe(
      (response: any) => {
        this.spinner.hide();
        let user: any = JSON.parse(
          localStorage.getItem("permission") || "{}"
        ).id;
        this.surveyURL = response?.url + `?user=${user}`;
        const dialog = this.dialog.open(SurveyEmbedDialogComponent, {
          width: "800px",
          data: {
            item: item,
            url: this.surveyURL,
          },
        });
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  viewResults() {
    this.surveyService.setActiveTab("results");
    this.router.navigate([`BVT/survey-details/results`]);
  }

  viewClosedSurveyDetails(item: any) {
    this.router.navigate([`BVT/take-survey/${btoa(item.id)}`]);
  }

  getTeamList(type?: any) {
    this.surveyService.getUsers().subscribe((res: any) => {
      this.teamList = res;
      this.filteredTeamList = res;
      this.teamList.forEach((item: any) => {
        item["name"] = item.first_name + " " + item.last_name;
        item["mail"] = item.email;
      });
      this.filteredTeamList.forEach((item: any) => {
        item["name"] = item.first_name + " " + item.last_name;
        item["mail"] = item.email;
      });
      this.getDataSource();
    });
  }

  searchTeam(index: any) {
    return this.filteredTeamListFilter(index);
  }

  filteredTeamListFilter(index: any) {
    if (this.filteredTeamList) {
      let filteringArray = this.teamCreateForm.controls[index].value.respondents
        .value
        ? this.teamCreateForm.controls[index].value.respondents.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredTeamList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (innerel: any) {
                  return innerel.id == array_el.id;
                }).length == 0
              );
            })
          : this.filteredTeamList;

      filteredArray =
        typeof this.teamCreateForm.controls[index].value.input.value ===
        "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(
                    this.teamCreateForm.controls[
                      index
                    ].value.input.value.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  removeTeam(team: string, indx: any): void {
    const index =
      this.teamCreateForm.controls[indx].value.respondents.value.indexOf(team);

    if (index >= 0) {
      this.teamCreateForm.controls[indx].value.respondents.value.splice(
        index,
        1
      );
    }
  }

  selectedTeam(event: MatAutocompleteSelectedEvent, index: any): void {
    this.teamCreateForm.controls[index].value.respondents.value.push(
      event.option.value
    );
    this.teamCreateForm.controls[index].value.input.setValue(null);
  }

  addRespondant() {
    var payload: any = [];
    this.spinner.show();
    this.teamCreateForm.value.forEach((element: any) => {
      if (element.respondents.value) {
        const selectedSurvey = this.totalSurveyList.find(
          (item: any) => item.id === element.id.value
        );
        let surveyRec: any = {
          survey_id: selectedSurvey.survey.id,
          users: [],
        };
        element.respondents.value.forEach((user: any) => {
          surveyRec.users.push(user);
        });
        payload.push(surveyRec);
      }
    });
    this.surveyService.saveSurveyDetail(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success("Survey updated successfully");
    });
  }

  openUser(i: any, bool: any = true) {
    if (bool) {
      this.teamList.forEach((element: any) => {
        element.selected =
          this.teamCreateForm.controls[i].value.respondents.value.filter(
            (e: any) => e.mail == element.mail
          ).length > 0
            ? true
            : false;
      });
    } else {
      this.teamList.forEach((element: any) => {
        element.selected =
          this.totalSurveyClosedList[i].respondents.filter(
            (e: any) => e.mail == element.mail
          ).length > 0
            ? true
            : false;
      });
    }

    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = {
      name: "user_owner",
      section: "user_owner",
      respondents: [],
      team_list: this.teamList,
      filterTeamList: this.teamList,
      allowed: bool,
      header_name: "Respondants",
    };
    dialog.afterClosed().subscribe((val) => {
      if (val) {
        let selected = val?.filter((e: any) => e.selected == true);
        this.teamCreateForm.controls[i].value.respondents.value = selected;
        this.addRespondant();
      }
    });
  }

  surveyName: any;
  surveyId: any;
  surveyOptions: any = [];
  surveyData: any;
  optionType: any;
  surveyType: any;

  emitSurveyEvent(
    surveyId: any,
    surveyName: any,
    surveyGraphId: any,
    type: any
  ) {
    this.surveyId = surveyId;
    this.surveyType = type;
    this.surveyName = surveyName;
    this.loadSurveyData(surveyId, surveyName);
    this.getPowerBIReportDetails(surveyName);
    // this.loadPowerBIReport(surveyId,surveyName,surveyGraphId);
    this.loadSurveySummary(surveyId);
    this.loadSurveyResponse();
    // this.surveyIdEmit.emit({surveyId:surveyId,surveyName:surveyName});
  }

  loadSurveySummary(surveyId: any) {
    this.surveyService.getSurveySummary(surveyId).subscribe((res: any) => {
      this.surveySummary = res;
    });
  }

  loadSurveyData(surveyId: any, surveyName: any) {
    this.spinner.show();
    this.surveyOptions = [];
    this.surveyService.loadSurveyById(surveyId).subscribe((res: any) => {
      this.spinner.hide();
      this.surveyData = res;
      this.surveyData.forEach((elem: any) => {
        if (elem?.question_type != "single_choice") {
          elem?.options.forEach((elm: any, key: any) => {
            this.surveyOptions.push({
              id: elm?.option?.id,
              display_text: elm?.option?.display_text,
            });
          });
        }
      });
      this.surveyOptions = this.surveyOptions
        .map((item: any) => item.display_text)
        .filter(
          (value: any, index: any, self: any) => self.indexOf(value) === index
        );
      this.optionType = this.surveyOptions[0];
      this.loadQuestionByOption();
    });
  }
  clickOptions(option: any) {
    this.optionType = option;
    this.loadQuestionByOption();
  }
  surveyDetailsByEachSubOption: any = [];
  surveyDetailsBySubotionCategory: any = [];
  singleChoiceQuestionEachOption: any = [];
  singleChoiceQuestionQuestionOptionCategory: any = [];

  loadQuestionByOption() {
    this.surveyDetailsByEachSubOption = [];
    this.surveyDetailsBySubotionCategory = [];
    this.singleChoiceQuestionEachOption = [];
    this.singleChoiceQuestionQuestionOptionCategory = [];
    this.surveyData.forEach((elem: any) => {
      var eachSubOption: any = [];
      var subOptionByCategories: any = [];
      var singleChoiceEachOption: any = [];
      var singleChoiceOptionByCategories: any = [];
      if (elem?.question_type == "single_choice") {
        singleChoiceEachOption.push({ options: elem?.options });
        singleChoiceOptionByCategories.push({
          positive: elem?.options[0]?.percentage
            ? Number(elem?.options[0]?.percentage)
            : 0 + elem?.options[1]?.percentage
            ? Number(elem?.options[1]?.percentage)
            : 0,
          neutral: elem?.options[2]?.percentage
            ? Number(elem?.options[2]?.percentage)
            : 0,
          negative: elem?.options[3]?.percentage
            ? Number(elem?.options[3]?.percentage)
            : 0 + elem?.options[4]?.percentage
            ? Number(elem?.options[4]?.percentage)
            : 0 + elem?.options[5]?.percentage
            ? Number(elem?.options[5]?.percentage)
            : 0,
        });
      } else {
        elem?.options.forEach((option: any) => {
          if (option?.option?.display_text == this.optionType) {
            eachSubOption.push({
              option: option?.option?.display_text,
              sub_options: option?.sub_options,
            });
            subOptionByCategories.push({
              option: option?.option?.display_text,
              positive:
                Number(option?.sub_options[0]?.sub_option_percentage) +
                Number(option?.sub_options[1]?.sub_option_percentage),
              neutral: Number(option?.sub_options[2]?.sub_option_percentage),
              negative:
                Number(option?.sub_options[3]?.sub_option_percentage) +
                Number(option?.sub_options[4]?.sub_option_percentage) +
                Number(option?.sub_options[5]?.sub_option_percentage),
            });
          }
        });
      }
      if (eachSubOption?.length > 0) {
        this.surveyDetailsByEachSubOption.push({
          display_text: elem?.display_text,
          eachSubOption,
        });
        this.surveyDetailsBySubotionCategory.push({
          display_text: elem?.display_text,
          subOptionByCategories,
        });
      }
      if (singleChoiceEachOption?.length > 0) {
        this.singleChoiceQuestionEachOption.push({
          display_text: elem?.display_text,
          singleChoiceEachOption,
        });
        this.singleChoiceQuestionQuestionOptionCategory.push({
          display_text: elem?.display_text,
          singleChoiceOptionByCategories,
        });
      }
    });
  }
  biReportFlag: any = {
    reportConfig: false,
    reportConfigSUnBurst: false,
    sentimentGraph: false,
    reportConfigRadarChart: false,
  };
  getPowerBIReportDetails(surveyName: any) {
    let name = surveyName.toLowerCase().replace(/ /g, "_");
    this.PowerBiService.getPowerBIReportDetails(name).subscribe((res: any) => {
      let report = res;
      this.loadPowerBIReport(report);
    });
  }

  loadPowerBIReport(report: any) {
    this.biReportFlag = {
      reportConfig: false,
      reportConfigSUnBurst: false,
      sentimentGraph: false,
      reportConfigRadarChart: false,
    };
    this.reportConfig = this.reportModel;
    this.reportConfigSUnBurst = this.reportModel;
    this.sentimentGraph = this.reportModel;
    this.reportConfigRadarChart = this.reportModel;
    this.setBIZoomLevel();
    var basicFilter: any = {
      // $schema: "http://powerbi.com/product/schema#basic",
      // target: {
      //     table: "survey_data",
      //     column: "survey_id"
      // },
      // operator: "In",
      // values:[`${surveyName}`],
      // filterType: models.FilterType.Basic
    };
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
      report.forEach((element: any) => {
        this.PowerBiService.getBIEmbedURL(
          res.access_token,
          element?.report_id
        ).subscribe((reportData: any) => {
          this.PowerBiService.getBIEmbedToken(
            res.access_token,
            element?.report_id,
            element?.group
          ).subscribe((tokenData: any) => {
            if (element.section == "avg_score_each_quest") {
              this.biReportFlag.reportConfig = true;
              this.reportConfig = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "comp_avg_score_port_subport") {
              this.biReportFlag.reportConfigSUnBurst = true;
              this.reportConfigSUnBurst = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "sentiment_score_avg_Score_each_quest") {
              this.biReportFlag.sentimentGraph = true;
              this.sentimentGraph = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "section_vendor_comp") {
              this.biReportFlag.reportConfigRadarChart = true;
              this.reportConfigRadarChart = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
          });
        });
      });
    });
  }
  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      pageName: pageName,
      //  filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: this.zoomLevel,
        background: models.BackgroundType.Transparent,
      },
    };
  }
  setBIZoomLevel() {
    if (window.innerWidth > 1500) {
      this.zoomLevel = 0.6;
    } else if (window.innerWidth <= 1500 && window.innerWidth > 1246) {
      this.zoomLevel = 0.5;
    } else if (window.innerWidth <= 1245) {
      this.zoomLevel = 0.4;
    }
  }

  refresh(id: any) {
    this.spinner.show();
    this.surveyService.refreshData(id).subscribe((res: any) => {
      this.loadData.emit();
      this.spinner.hide();
    });
  }

  closeSurveyDetails() {
    this.surveyName = "";
    this.totalSurveyResponse = [];
  }
  totalSurveyResponse: any;
  loadSurveyResponse() {
    this.spinner.show();

    this.totalSurveyResponse = [];
    this.surveyService
      .getSurveyResponseById(this.surveyId)
      .subscribe((response: any) => {
        this.accordionTask("one");
        this.spinner.hide();

        // this.surveyResponseNew = this.barchartNew(response);
        // let objDiv: any = document.getElementById("centerPaneD");
        // objDiv.scrollTop = objDiv.scrollHeight;
        var maxAvrageVendor: any = [];

        response.forEach((res: any) => {
          var options: any = [];
          var datas: any = [];
          var avrages: any = [];
          maxAvrageVendor = [];
          res.options.forEach((opt: any) => {
            options.push(opt?.option?.display_text);
            opt?.stats.forEach((val: any) => {
              const index = avrages.findIndex((x: any) => x.name === val?.year);
              if (index >= 0) {
                avrages[index]?.data?.push(Number(val?.average));
              } else {
                avrages.push({
                  type: "column",
                  name: val?.year,
                  data: [Number(val?.average)],
                  dataLabels: { enabled: true },
                });
              }
              maxAvrageVendor.push({
                option: opt?.option?.display_text,
                avg: Number(val?.average),
                year: val?.year,
              });
            });
          });
          var res: any = {
            display_text: res.display_text,
            chart: this.barchartReturn(res.display_text, options, avrages),
            id: res.id,
            question_type: res.question_type,
            maxAvrageVendor,
          };
          this.totalSurveyResponse.push(res);
        });
      });
  }
  accordionTask(type: any) {
    var collapseOne = document.getElementById("collapseOne");
    var collapseOneButton = document.getElementById("collapseOneButton");
    var collapseTwo = document.getElementById("collapseTwo");
    var collapseTwoButton = document.getElementById("collapseTwoButton");
    if (type == "one") {
      collapseOne?.classList.remove("collapse");
      collapseOneButton?.classList.remove("collapsed");
      collapseTwo?.classList.add("collapse");
      collapseTwoButton?.classList.add("collapsed");
    }
    if (type == "two") {
      collapseOne?.classList.add("collapse");
      collapseOneButton?.classList.add("collapsed");
      collapseTwo?.classList.remove("collapse");
      collapseTwoButton?.classList.remove("collapsed");
    }
  }
  barchartReturn(question: any, options: any, values: any) {
    return {
      chart: {
        type: "category",
        height: 180,
      },
      title: {
        text: "",
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        scrollbar: {
          enabled: false,
        },
        categories: options,
      },
      series: values,
      dataLabels: {
        enabled: true,
      },
      // series: [
      //   {
      //     'type': 'column',
      //     data: values,
      //     dataLabels: {
      //       enabled: true,
      //      // rotation: -90,
      //       color: '#00000',
      //       align: 'right',
      //       y: 10, // 10 pixels down from the top
      //       style: {
      //           fontSize: '10px',
      //        //   fontFamily: 'helvetica, arial, sans-serif',
      //           textShadow: false,
      //           fontWeight: 'normal'

      //       }
      //   }
      //   }
      // ],
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
      legend: { enabled: true },
      yAxis: {
        title: false,
      },
    };
  }

  getHighestField(data: any) {
    if (data.length > 0) {
      return data.reduce(function (prev: any, current: any) {
        if (+current.avg > +prev.avg) {
          return current;
        } else {
          return prev;
        }
      });
    }
    return;
  }

  getPerformanceImpact(pulse: any) {
    let perfImpact = "";
    pulse.performance_impact.forEach((impact: any) => {
      perfImpact += impact.display_name + ", ";
    });
    perfImpact = perfImpact.trimEnd();
    if (perfImpact.endsWith(",")) {
      perfImpact = perfImpact.slice(0, -1);
    }
    return perfImpact;
  }

  getPulse() {
    this.pulseLoaded = false;
    this.surveyService.getPulse().subscribe((res: any) => {
      this.pulsesList = res;
      this.pulsesList.forEach((pulse: any) => {
        pulse["color"] = this.getGMapRandomColor();
        pulse["impact"] = this.getPerformanceImpact(pulse);
      });
      this.pulseLoaded = true;
    });
  }

  changeTab(tab: any) {
    this.activeTab = tab.index;
    if (this.activeTab === 2) {
      this.getPulse();
    } else {
      this.getDataSource();
    }
  }

  handlePulseFilter() {
    const type =
      this.filterType === "true"
        ? true
        : this.filterType === "false"
        ? false
        : "";
    const portfolio = this.filterPortfolio ? this.filterPortfolio : "";
    const subPortfolio = this.filterSubPortfolio ? this.filterSubPortfolio : "";
    const program = this.filterProgram ? this.filterProgram : "";
    const project = this.filterProject ? this.filterProject : "";
    const vendor = this.filterVendor ? this.filterVendor : "";
    const owner = this.filterOwner ? this.filterOwner : "";
    const performanceImpact = this.filterPerformance
      ? this.filterPerformance
      : "";
    this.surveyService
      .getFilteredPulses(
        portfolio,
        subPortfolio,
        program,
        project,
        vendor,
        owner,
        type,
        performanceImpact
      )
      .subscribe((resp: any) => {
        this.pulsesList = resp;
        this.pulsesList.forEach((pulse: any) => {
          pulse["color"] = this.getGMapRandomColor();
          pulse["impact"] = this.getPerformanceImpact(pulse);
        });
      });
  }

  search() {
    this.teamCreateForm.clear();
    this.backgroundColors = [];
    this.totalSurveyClosedList = [];
    const tabName =
      this.activeTab == 0
        ? "created"
        : this.activeTab == 1
        ? "respondent"
        : "pulse";
    const dt = this.datePipe.transform(this.filterDueDate, "YYYY-MM-dd");
    if (this.activeTab === 2) {
      this.handlePulseFilter();
    } else {
      this.surveyService
        .getFilteredHubbleSurveys(
          this.filterName,
          this.filterApplicability,
          this.filterOwner,
          dt,
          tabName
        )
        .subscribe((res: any) => {
          this.handleResponse(res);
        });
    }
  }
}
