import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as elasticsearch from 'elasticsearch-browser';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  private client: elasticsearch.Client;

  constructor(private http: HttpClient) {
    if (!this.client) {
      this.connectELServer();
    }
  }

  private connectELServer() {

    this.client = new elasticsearch.Client({
      //host: 'https://kpi.kepleruat.easyngo.com:9200/',
      // log: 'trace'
      host: environment.elasticSearchURL,
      httpAuth: environment.elastic_auth,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
        }
    });
  }

  CheckAvailability(): any {
    return this.client.ping({
      requestTimeout: Infinity,
      body: 'Elastic Server Connected',
    });
  }

  getAllSearchResult(index?: any, query?: any, startVal?: any, endValue?: any): any {
    return this.client.search({
      index: index,
      q: query,
      from: startVal,
      size: endValue,
      filterPath: ['hits.hits._source']
    });
  }

  getSearchResult(searchQuery: any, offset?: any, endValue?: any) {
    return this.http.get(`delivery/get-elastic-search-data/?search_word=${searchQuery}`);
  }

}
