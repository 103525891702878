import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "governance-image-preview-modal",
  templateUrl: "governance-image-preview-modal.html",
  styleUrls: ["governance-image-preview-modal.scss"],
})
export class GovernanceLighthouseImageDialog {
  template: any;
  questions: any = [];

  constructor(
    public dialogRef: MatDialogRef<GovernanceLighthouseImageDialog>
  ) {}

  closeModal(bool: boolean = false): void {
    this.dialogRef.close(bool);
  }
}

@Component({
  selector: "app-governance-assessment-central",
  templateUrl: "./governance-assessment-central.component.html",
  styleUrls: ["./governance-assessment-central.component.scss"],
  providers: [DatePipe],
})
export class GovernanceAssessmentCentralComponent implements OnInit {
  ownAssessments: any = [];
  mappedAssessments: any = [];
  audience: any = [];
  user: any;
  searchText: any = "";
  filterCategory: any = null;
  filterName: any = null;
  filterDueDate: any = null;
  filterStatus: any = null;
  filterPortfolio: any = null;
  filterSubportfolio: any = null;
  filterProgram: any = null;
  filterProject: any = null;
  filterVendor: any = null;
  filterOwner: any = null;
  ownersList: any = [];
  portfolioList: any = [];
  subportfolioList: any = [];
  programList: any = [];
  projectList: any = [];
  vendorList: any = [];
  assessmentsLoaded: boolean = false;
  constructor(
    private router: Router,
    private governanceLighthouseService: GovernanceLighthouseService,
    private toastrService: ToastrService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private filterService: FilterService,
    private assessmentCentralService: AssessmentCentralService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    this.messageService.setGnews("Assessment Central listing");
    this.getAssessments();
    this.getOwners();
    this.getPortfolioList();
    this.getSubportfolioList();
    this.getProgramList();
    this.getProjectList();
    this.getVendorList();
  }

  getVendorList() {
    this.filterService.getVendor().subscribe((resp) => {
      this.vendorList = resp;
    });
  }

  getProjectList() {
    this.filterService
      .getProject(this.filterSubportfolio, this.filterProgram)
      .subscribe((resp) => {
        this.projectList = resp;
      });
  }

  getProgramList() {
    this.filterService.getProgram(this.filterSubportfolio).subscribe((resp) => {
      this.programList = resp;
    });
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getPortfolioList() {
    this.filterService.getPortfolios().subscribe((resp: any) => {
      this.portfolioList = resp.records;
    });
  }

  getSubportfolioList() {
    this.filterService
      .getSubPortfolios(this.filterPortfolio)
      .subscribe((resp) => {
        this.subportfolioList = resp;
      });
  }

  getAssessments() {
    this.assessmentsLoaded = false;
    this.governanceLighthouseService
      .getAllAssessments()
      .subscribe((res: any) => {
        this.mappedAssessments = res.assigned_to_user;
        this.ownAssessments = res.created_by_user;

        this.audience = res.audience;
        this.audience.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
        this.mappedAssessments.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
        this.ownAssessments.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
        this.assessmentsLoaded = true;
      });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  navigate(readMode: any = 0) {
    this.router.navigateByUrl(
      `strategicRelevance/governancect/assessment-templates?readMode=${readMode}`
    );
  }

  openAssessment(x: any) {
    this.router.navigateByUrl(
      "strategicRelevance/governancect/assessment-attend/" + btoa(x.id + "")
    );
  }

  openResult(x: any) {
    this.router.navigateByUrl(
      "strategicRelevance/governancect/assessment-result/" + btoa(x.id + "")
    );
  }

  search() {
    const dt = this.datePipe.transform(this.filterDueDate, "YYYY-MM-dd");
    this.governanceLighthouseService
      .getFilteredAssessmentCentral(
        this.filterCategory,
        this.filterName,
        dt,
        this.filterStatus,
        this.filterPortfolio,
        this.filterSubportfolio,
        this.filterProgram,
        this.filterProject,
        this.filterVendor,
        this.filterOwner
      )
      .subscribe((res: any) => {
        this.mappedAssessments = res.assigned_to_user;
        this.ownAssessments = res.created_by_user;
        this.audience.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
        this.mappedAssessments.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
        this.ownAssessments.forEach((category: any) => {
          category.assessments.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
      });
  }

  showImageDialog() {
    console.log("show");
    const dialog = this.dialog.open(GovernanceLighthouseImageDialog, {
      width: "50vw",
    });
  }
}
