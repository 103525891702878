import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ProgramService } from 'src/app/shared/services/program.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith,takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe, Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { forEach, isArray } from 'lodash';
import { FilterService } from 'src/app/shared/services/filter.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ProgramComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  limit: any;
  offset: any;
  end: any
  offsetPaginate: any;
  searchText = '';
  sortBy = { name: 'name', value: 'ASC' };
  totalCount: any;
  columns: any[] = [];
  ColumnMode = ColumnMode;
  showHelperText = false;
  opportunity: any[] = [];
  opportunityTotal: any[] = [];
  list: any[] = [];
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterForm: FormGroup | any;
  @ViewChild('editTmpl', { static: true }) editTmpl: TemplateRef<any> | any;
  @ViewChild('createdTmpl', { static: true }) createdTmpl: TemplateRef<any> | any;
  @ViewChild('hdrTpl', { static: true }) hdrTpl: TemplateRef<any> | any;
  OpportunityFieldList: any;
  showOpportunityBtn = false;
  portList: any[] = [];
  programList: any[] = [];

  subPortfilteredOptions: any[] = [];
  portFolioId: any;
  subPortId: any;
  search = false;
  dupOpportunity: any;
  opportunityId: any;
  collection: any[] = [];
  page: number = 1;
  endVal: number = 10;
  startVal: number = 0;
  itemsPerPage: number = 10;
  subPortChildOptions: any[] = [];
  statusList: any;
  statusId: any;
  deleteID: any;
  screen_name = 'opportunity';
  disableShowMore: boolean = false;

  public context: any;

  portFolioCount: number = 0;
  portFolioList: any;
  portFolioListUnFiltered: any;
  filterObj: any;
  filterField: any;
  pagetype = 'opportunity';
  params: any;
  archiveListData: any;
  user: any;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ps: ProgramService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private permissionsService: NgxPermissionsService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private csvService: DataToCsvService,
    private datePipe:DatePipe,
    private router: Router,
    private filterService: FilterService,
    private route: ActivatedRoute,
    private location: Location,
    private messageService: MessageService
  ) {
    this.route.queryParams.subscribe(async params => {
      this.params = params;
      this.location.replaceState(this.location.path().split('?')[0], '');
    });

    this.messageService.setGnews('Opportunity listing');
    
    this.context = {
      componentParent: this,
    }; 
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.permissionsService.loadPermissions(this.user.permissions);
    // this.getDataSource();
    // this.getStatus();
    this.getOpportunity(); 
    this.subPortfilteredOptions = [];
    this.filterOptionSubPortfolio = this.filterForm?.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
      );
    this.createForm();
    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element].array.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        awl ? this.getFilteredList() : this.getPortfolioList();
      }else{
        this.getPortfolioList();
      }
    });
    this.filterService.filterFlag.subscribe((ele:any)=>{
      this.filterField = ele.field;
    })
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
      status: ['']
    });

    this.filterOptionPortfolio = this.filterForm.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
      );
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();

    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }

  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortId = "";
    this.filterForm.patchValue({
      subPortfolio: ''
    })    
    let selectedPortfolio = this.portList.find((e:any)=> e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
    if (this.portFolioId === "") { 
      this.subPortfilteredOptions = [];
      this.filterOptionSubPortfolio = this.filterForm?.get('subPortfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
        );
    }
    this.getDataSource();    
  }


  selectedSubportfolioValues(id: any) {
    this.subPortId = null;
    this.subPortId = id;
    this.getDataSource();

  }
  onInputChange(e: any) {
    if (this.searchText == '') {
      this.getDataSource();
    }
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  getDataSource(isTotal:boolean=false) {
    this.spinner.show();
    let endVal:any = isTotal ? this.totalCount : this.endVal;
    this.ps.getOpportunity(this.startVal,endVal, this.subPortId, this.searchText, this.portFolioId ,this.statusId).subscribe((res: any) => {
      this.spinner.hide();
      this.totalCount = res.totalRecords;
      if(isTotal){
        this.opportunityTotal.push(res.records);
        this.saveAsCSV();
      }else{
        res.records.forEach((element: any) => {
          this.opportunity.push(element);

        });
        this.collection = this.opportunity;
      }
    
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  async getFilteredList(){
    let filterArray:any = {portfolio:[],sub_portfolio:[],program:[],opportunity:[],vendor:[],tags:[],status:[]};
    if(this.filterField == 'portfolio'){
      this.ps.getPortfolioList().then((res: any) => {
        this.portFolioList = res.records.length > 0 ? res.records : [];
        this.portFolioListUnFiltered = [...this.portFolioList];
        this.portFolioCount = res.total_projects_count;

        if(this.filterObj.portfolio.array.length > 0){
          this.portFolioList = this.portFolioList.filter((el: any) => {
            return this.filterObj.portfolio.array.some((f: any) => {
              return f.id === el.id;
            });
          });
        }
      })
    }else{
    delete this.portFolioList;
    Object.keys(this.filterObj).forEach((key:any) => {
      this.filterObj[key].array.forEach((element:any) => {
        switch (key) {
          case "portfolio":
            filterArray.portfolio.push(element.id);
            break;
          case "subportfolio":
            filterArray.sub_portfolio.push(element.id);
            break;
          case "program":
            filterArray.program.push(element.id);
            break;
          case "opportunity":
            filterArray.opportunity.push(element.id);
            break;
          case "vendor":
            filterArray.vendor.push(element.id);
            break;
          case "tag":
            filterArray.tags.push(element.id);
            break;
          case "oppStatus":
            filterArray.status.push(element.id);
            break;
          default:
            break;
        }
      });
    });
    await this.ps.getFilteredList('', filterArray).then((res: any) => {
      this.portFolioList = [];
          res.forEach((es: any) => {
            this.portFolioList.push(es.portfolio);
          });
  
          this.portFolioList.forEach((port: any) => {
            port.children = port.subportfolio;
            port.children.forEach((sub: any) => {
              sub.children = sub.program;
              sub.parent = {
                id: port.id,
                level: 'portfolio'
              }
              sub.children.forEach((program: any) => {
                program.level = 'program';
                program.children = program.opportunity
                program.parent = {
                  id: sub.id,
                  level: 'sub_portfolio',
                  parent: {
                    id: port.id,
                    level: 'portfolio'
                  }
                }
  
                program.children.forEach((opp: any) => {
                  opp.level = 'last';
                });
              });
            });
          });
    })
  }
    // if(this.filterField == 'portfolio'){
    //   this.ps.getPortfolioList().then((res: any) => {
    //     this.portFolioList = res.records.length > 0 ? res.records : [];
    //     this.portFolioListUnFiltered = [...this.portFolioList];
    //     this.portFolioCount = res.total_projects_count;

    //     if(this.filterObj.portfolio.array.length > 0){
    //       this.portFolioList = this.portFolioList.filter((el: any) => {
    //         return this.filterObj.portfolio.array.some((f: any) => {
    //           return f.id === el.id;
    //         });
    //       });
    //     }
    //   })
    // }else if(this.filterField == 'subportfolio'){
    //   delete this.portFolioList;
    //   let bodyParam: any = {
    //     portfolio: []
    //   }
    //   this.filterObj.subportfolio.array.forEach((element: any) => {
    //     bodyParam.portfolio.push(element.id)
    //   });
    //   await this.ps.getFilteredList('', bodyParam).then((res: any) => {
    //     this.portFolioList = [];
    //     res.forEach((ea: any) => {
    //       this.portFolioList.push(ea.portfolio)
    //     });
    //     this.portFolioList.forEach((port: any) => {
    //       port.children = port.subportfolio;
    //       port.children.forEach((sub: any) => {
    //         sub.children = sub.program;
    //         sub.parent = {
    //           id: port.id,
    //           level: 'portfolio'
    //         }
    //         sub.children.forEach((program: any) => {
    //           program.level = 'program';
    //             program.parent = {
    //               id: sub.id,
    //               level: 'sub_portfolio',
    //               parent: {
    //                 id: port.id,
    //                 level: 'portfolio'
    //               }
    //             }
    //         });
    //       });
    //     });
    //   })
    // }else if(this.filterField == 'program'){
    //   delete this.portFolioList;
    //   let bodyParam:any = {
    //     program: []
    //   }
    //   this.filterObj.program.array.forEach((element: any) => {
    //     bodyParam.program.push(element.id)
    //   });

    //   await this.ps.getFilteredList('', bodyParam).then((res: any) => {
    //     this.portFolioList = [];
    //     res.forEach((es: any) => {
    //       this.portFolioList.push(es.portfolio);
    //     });

    //     this.portFolioList.forEach((port: any) => {
    //       port.children = port.subportfolio;
    //       port.children.forEach((sub: any) => {
    //         sub.children = sub.program;
    //         sub.children.forEach((program: any) => {
    //           program.level = 'program';
    //           program.parent = {
    //             id: sub.id,
    //             level: 'sub_portfolio',
    //             parent: {
    //               id: port.id,
    //               level: 'portfolio'
    //             }
    //           }
    //         });

    //         sub.parent = {
    //           id: port.id,
    //           level: 'portfolio'
    //         }
    //       });
    //     });
    //   })
    // }else if(this.filterField == 'opportunity'){
    //   delete this.portFolioList;
    //   let bodyParam: any = {
    //     opportunity: []
    //   }
    //   this.filterObj.opportunity.array.forEach((element: any) => {
    //     bodyParam.opportunity.push(element.id)
    //   });

    //   await this.ps.getFilteredList('', bodyParam).then((res: any) => {
    //     this.portFolioList = [];
    //     res.forEach((es: any) => {
    //       this.portFolioList.push(es.portfolio);
    //     });

    //     this.portFolioList.forEach((port: any) => {
    //       port.children = port.subportfolio;
    //       port.children.forEach((sub: any) => {
    //         sub.children = sub.program;
    //         sub.parent = {
    //           id: port.id,
    //           level: 'portfolio'
    //         }
    //         sub.children.forEach((program: any) => {
    //           program.level = 'program';
    //           program.children = program.opportunity
    //           program.parent = {
    //             id: sub.id,
    //             level: 'sub_portfolio',
    //             parent: {
    //               id: port.id,
    //               level: 'portfolio'
    //             }
    //           }

    //           program.children.forEach((opp: any) => {
    //             opp.level = 'last';
    //           });
    //         });
    //       });
    //     });
    //   })
    // }else if(this.filterField == 'vendor'){
    //   delete this.portFolioList;
    //   let bodyParam: any = {
    //     vendor: []
    //   }
    //   this.filterObj.vendor.array.forEach((element: any) => {
    //     bodyParam.vendor.push(element.id)
    //   });
    //   await this.ps.getFilteredList('',bodyParam).then((res: any) => {
    //     this.portFolioList = [];
    //     res.forEach((es: any) => {
    //       this.portFolioList.push(es.portfolio);
    //     });

    //     this.portFolioList.forEach((port: any) => {
    //       port.children = port.subportfolio;
    //       port.children.forEach((sub: any) => {
    //         sub.children = sub.program;
    //         sub.children.forEach((program: any) => {
    //           program.level = 'program';
    //           program.parent = {
    //             id: sub.id,
    //             level: 'sub_portfolio',
    //             parent: {
    //               id: port.id,
    //               level: 'portfolio'
    //             }
    //           }
    //         });

    //         sub.parent = {
    //           id: port.id,
    //           level: 'portfolio'
    //         }
    //       });
    //     });
    //   })
    // }else if(this.filterField == 'tag'){
    //   delete this.portFolioList;
    //   let bodyParam: any = {
    //     'tags': []
    //   }
    //   this.filterObj.tag.array.forEach((element: any) => {
    //     bodyParam.tags.push(element.id)
    //   });
    //   await this.ps.getFilteredList("", bodyParam).then((res: any) => {
    //     this.portFolioList = [];
    //     res.forEach((es: any) => {
    //       this.portFolioList.push(es.portfolio);
    //     });

    //     this.portFolioList.forEach((port: any) => {
    //       port.children = port.subportfolio;
    //       port.children.forEach((sub: any) => {
    //         sub.children = sub.program;
    //         sub.children.forEach((program: any) => {
    //           program.level = 'program';
    //           program.parent = {
    //             id: sub.id,
    //             level: 'sub_portfolio',
    //             parent: {
    //               id: port.id,
    //               level: 'portfolio'
    //             }
    //           }
    //         });

    //         sub.parent = {
    //           id: port.id,
    //           level: 'portfolio'
    //         }
    //       });
    //     });
    //   })
    // }else{
    //   this.portFolioList = [...this.portFolioListUnFiltered];

    //   if(this.filterObj.portfolio.length > 0){
    //     this.filterObj.portfolio.forEach((element: any) => {
    //       this.portFolioList = this.portFolioList.filter((e: any) => e.id == element.id);
    //     });
    //   }
    // }
  }

  getPortfolioList(){
    delete this.portFolioList;
    if(!this.portFolioListUnFiltered){
      this.ps.getPortfolioList().then((res: any) => {
        this.portFolioList = res.records.length > 0 ? res.records : [];
        this.portFolioListUnFiltered = [...this.portFolioList];
        this.portFolioCount = res.total_opportunity_count;
        return res.records;
      })
    }else{
      this.portFolioList = [...this.portFolioListUnFiltered];
    }
    
  }

  async getLevel(item: any = null){
    if(item.children){
      return
    }
    let response: any;
    if(item.level == 'portfolio'){
      response = await this.ps.getSubPortfolioList(item).then((res: any) => {
        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.parent = res.portfolio_details[0]
          });
        }
        this.portFolioList[this.portFolioList.indexOf(item)].children = res.records;
        return res;
      })
    }else if(item.level == 'sub_portfolio'){
      response = await this.ps.getPortfolioPrograms(item).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.id);
        let rec = res.records;
        if(this.filterObj?.subportfolio?.array?.length > 0){
          rec = res.program;
        }
        if(rec.length > 0){
          rec.forEach((element: any) => {
            element.level = 'program';
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex].id,
              level: 'sub_portfolio',
              parent: {
                id: this.portFolioList[portfolioIndex].id,
                level: 'portfolio'
              }
            }
          });
        }

        this.portFolioList[portfolioIndex].children[subportfolioIndex].children = rec;
        return rec;
      })
    }else if(item.level == 'program'){
      let queryParams = '';
      if(this.filterObj?.vendor?.array?.length > 0){
        queryParams = `&vendor=${this.filterObj.vendor}`
      }
      if(this.filterObj?.tag?.array?.length > 0){
        queryParams = `&tags=${this.filterObj.tag}`
      }
     
      response = await this.ps.getOpportunityList(item, queryParams).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
        let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.level = 'last';
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].id,
              level: "program",
              parent : {
                id: this.portFolioList[portfolioIndex].children[subportfolioIndex].id,
                level: 'sub_portfolio',
                parent: {
                  id: this.portFolioList[portfolioIndex].id,
                  level: 'portfolio'
                }
              }
            }
            
          });
        }

        this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children = res.records;
        return res;
      })
    }
    return response;
  }

  collapse(item: any){
    if(item.level == 'portfolio'){
      this.portFolioList.forEach((element: any) => {
        if(element.id != item.id) element.expanded = false;
      });
    }else if(item.level == 'sub_portfolio'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }else if(item.level == 'program'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children[subportfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children[subportfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }
  }

  navigateTo(item: any){
    if(item.status.label =='Published' || item?.status?.label =='SoW Signed'){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/proposal-evaluation`]);
    }
    else if(item.status.label =='Evaluating' && !this.user?.is_vendor && this.user?.permissions?.indexOf('opportunity.add_opportunity') > -1 ){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/summary`]);
    }else if(item.status.label =='Evaluating' && !this.user?.is_vendor && this.user?.permissions?.indexOf('opportunity.add_opportunity') < 0 ){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/proposal-evaluation`]);
    }else if(item.status.label =='Evaluating' && this.user?.is_vendor ){
      this.router.navigate([`getproposal/`+btoa(item.id)+`/proposal-evaluation`]);
    }else{
      this.router.navigate([`rfx/${btoa(item.id)}/opportunity/setup`]);
    }
  }

  getOpportunity() {
    this.spinner.show();
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })

      if(this.OpportunityFieldList.findIndex((e: any) => e.field_name == 'program_name') < 0 ){
        let programdata =  {
              "id": 1,
              "field_name": "program_name",
              "display_name": "Program Name",
              "lookup_field": "program_name",
              "field_type": "text-box",
              "field_status": true,
              "model_reference": "''",
              "field_values":this.programList,
              "form_details": {
                  "id": 1,
                  "form_name": "program_name"
              }
            }
          
          this.OpportunityFieldList.push(programdata);
      }
      this.showOpportunityBtn = true;
      this.createForm();
      this.spinner.hide();
    }, error => {
     // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
    this.ps.getProgramData().subscribe((res: any) => {
      this.programList = res;
     
    }, error => {
     // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
  }

  getStatus() {
    this.ps.getStatus().subscribe((res: any) => {
      this.statusList = res;
    }, error => {
      throw error;
    })
  }

  searchName() {
    this.getDataSource();
  }
  edit(i: any) {

  }
  add() {
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px' });
    dialog.componentInstance.type = 'Addopperuntiy';
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
  }

  paginate(event: any) {
    this.limit = event.limit;
    this.offset = this.limit * event.offset;
    this.offsetPaginate = event.offset;
    const checkBox = document.getElementsByName('check');
    // checkBox.forEach((item: HTMLInputElement) => item.checked = false);
    this.getDataSource()
  }

  sort(event: any) {
    if (this.totalCount > 1) {
      this.sortBy.name = event.column.prop;
      this.sortBy.value = event.newValue;
    }
  }

  deleteItem(item: any) {
    this.spinner.show();
    this.ps.deleteOpportunityById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let opportunityIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(opportunityIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveItem(item: any) {
    this.spinner.show();
    this.ps.archiveOpportunityById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let opportunityIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(opportunityIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Archived Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveList(item: any){
    this.spinner.show();
    this.archiveListData = [];
    this.ps.getArchiveList(item.parent.parent.id, item.parent.id, item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

      this.spinner.hide();
      this.archiveListData = res;
      this.archiveListData.forEach((element: any) => {
        element.selected = false;
      });
      
      if(this.archiveListData.length > 0){
        const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
        dialog.componentInstance.type = 'ArchiveList';
        dialog.componentInstance.typeName = 'Opportunity';
        dialog.componentInstance.archiveList = this.archiveListData;
  
        dialog.afterClosed().subscribe(val => {
          let payload: any = {
            opportunity: []
          };
          val.forEach((element: any) => {
            if(element.selected){
              payload.opportunity.push(element.id);
            }
          });
          this.spinner.show();
          if(payload.opportunity.length > 0){
            this.ps.unarchiveOppo(payload).then((res: any) => {
              this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].expanded = false;
                delete this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children;
                this.toastrService.success('Unarchived successfully');
                this.spinner.hide();
            })
          }
        })
      }else{
        this.toastrService.warning('No data to archive');
      }
      
      
      
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  deleteDialog() {
    this.spinner.show();
    this.ps.deleteOpportunity(this.deleteID).subscribe((res: any) => {
      this.spinner.hide();
      this.dialog.closeAll();
      this.getDataSource();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.dialog.closeAll();
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  clearFilter() {
    this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    this.statusId = 0;
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
      status: ''
    })
    this.getDataSource();
  }
  statusChange(e: any) {
    this.statusId = e;
    this.getDataSource();
  }

  showAllList() {
    this.startVal = this.page*this.itemsPerPage;
    this.opportunity.length;
    this.endVal = this.startVal+this.itemsPerPage;
    this.page++;
    this.getDataSource();
    if (this.totalCount < this.endVal) {
      this.disableShowMore = true;
    }
  }
  
  saveAsCSV() {
    if(this.opportunityTotal && this.opportunityTotal.length > 0){
      const items:any = [];
      this.opportunityTotal.forEach((item:any) => {
        let csvData = {
          "Opportunity Number": item.opportunity_number,
          "Opportunity Name": item.opportunity_name,
          "Portfolio Name": item?.portfolio_details?.[0]?.display_name,
          "Sub Portfolio Name": item?.portfolio_details?.[1]?.display_name,
          "Created by" :item?.created_user_details?.full_name,
          "Created on" :this.datePipe.transform(item?.created_on,'dd MMM yyyy'),
          "Updated on" :this.datePipe.transform(item?.last_updated_on,'dd MMM yyyy'),
          "Opportunity Status":item.status?.action_complete_label
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Opportuniy_List.CSV', items);
    }else{
      this.toastrService.error('No Records to download');
    }
  }
  onSelectOpportunity(evt:any){
    this.searchText = evt;
    this.getDataSource();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
