<div class="single-center-pane">
  <div *ngIf="usersList && assessment">
    <div class="row mb-1">
      <div class="col-sm-6 trn-trc">
        <h5 class="m-0" style="font-weight: 500">{{ assessment.name }}</h5>
        <p style="font-size: 12px" class="m-0">
          Due Date: <b>{{ assessment.end_date | date : "dd MMM, yyyy" }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Status: <b>{{ assessment.status }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Owner:
          <b>{{
            assessment.created_by.first_name +
              " " +
              assessment.created_by.last_name
          }}</b>
        </p>
        <p style="font-size: 12px; text-transform: capitalize" class="m-0">
          Audience:
          <b>{{ audienceInfo }}</b>
        </p>
      </div>
      <div class="col-sm-6">
        <div>
          <h5
            style="
              font-weight: 100;
              text-align: right;
              font-size: 14px;
              margin: 0;
            "
          >
            Total Users : <b style="font-weight: 600">{{ usersList.length }}</b>
          </h5>
          <h5
            style="
              font-weight: 100;
              text-align: right;
              font-size: 14px;
              margin: 0;
            "
          >
            Submitted :
            <b style="font-weight: 600">{{ submittedUsersList.length }}</b>
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <p class="m-0 mb-1" style="font-size: 13px">{{ assessmentInfo }}</p>
      <app-tag [tagsIds]="this.instanceTags" [isReadOnly]="true"></app-tag>
    </div>
    <hr class="my-2" />
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Responses">
        <div style="overflow-x: hidden">
          <div class="tab-holder mt-3">
            <h6 class="m-0" style="font-weight: 500; font-size: 14px">
              Respondents
            </h6>
            <div
              class="mb-3"
              style="
                font-size: 11px;
                display: flex;
                padding: 5px 0;
                overflow-x: scroll;
              "
            >
              <span
                class="usercircle"
                [ngClass]="{
                  submitted: user.submission,
                  selected: user.id == selectedUsr
                }"
                (click)="viewResult(user)"
                *ngFor="let user of usersList | orderBy : '-submission'"
                [matTooltip]="user.first_name + ' ' + user.last_name"
                >{{ user.first_name.charAt(0)
                }}{{ user.last_name.charAt(0) }}</span
              >
            </div>
            <div *ngIf="!selectedUsr">
              <p class="actiom">
                Kindly choose a user to access the responses.
              </p>
            </div>
            <div *ngIf="answers.length <= 0 && selectedUsr">
              <p class="actiom">
                The chosen user has not finished the assessment.
              </p>
            </div>
            <div *ngIf="answers.length > 0">
              <div class="user-level-summary mb-3">
                <h6>Respondent response summary -</h6>
                <div>
                  <highcharts-chart
                    style="display: flex"
                    [Highcharts]="Highcharts"
                    [options]="userChartData"
                    [callbackFunction]="chartCallback"
                    [oneToOne]="true"
                  ></highcharts-chart>
                </div>
              </div>

              <mat-accordion [multi]="true">
                <mat-expansion-panel
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                  [expanded]="true"
                  *ngFor="let group of answers"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ group.group }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div
                    *ngFor="let data of group.questions; let i = index"
                    class="q mb-2 m-2"
                  >
                    <span>{{ i + 1 }}</span>
                    <h6 class="m-0" style="width: 30%">
                      <b>{{ data.question }}</b>
                      <p class="mb-0" *ngIf="data.context">
                        Measure of Success: {{ data.context }}
                      </p>
                    </h6>
                    <h6
                      class="m-0"
                      style="
                        width: 10%;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ data.answer }}
                    </h6>
                    <p style="width: 55%" class="mb-0">{{ data.comments }}</p>
                    <p
                      class="m-0"
                      style="width: 5%; display: flex; align-items: center"
                    >
                      <mat-icon
                        *ngIf="data.file_name"
                        (click)="fileDownload(data.question_id, data.file_name)"
                        >cloud_download</mat-icon
                      >
                    </p>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Scorecard">
        <div style="overflow-x: hidden">
          <div class="tab-holder mt-3">
            <div class="row">
              <div class="col-md-12">
                <div class="custom-height">
                  <div
                    class="custom-table table-responsive"
                    style="max-height: calc(100vh - 220px)"
                  >
                    <table class="table table-bordered">
                      <thead style="position: sticky; z-index: 1">
                        <tr>
                          <th
                            *ngFor="let field of tableColumns"
                            scope="col"
                            class="text-center"
                          >
                            {{ field }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let group of aggregateData">
                          <tr
                            *ngFor="
                              let question of group.questions;
                              let i = index
                            "
                          >
                            <td
                              *ngIf="i === 0"
                              [attr.rowspan]="group.questions.length"
                              class="text-center"
                            >
                              {{ group.group_name }}
                            </td>
                            <td>{{ question.question }}</td>
                            <td class="text-center">
                              <highcharts-chart
                                style="display: flex"
                                [Highcharts]="Highcharts"
                                [options]="question.chart"
                                [callbackFunction]="chartCallback"
                                [oneToOne]="true"
                                style="
                                  display: block;
                                  width: 300px;
                                  height: 180px;
                                "
                              ></highcharts-chart>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                      <tr *ngIf="!aggregateData?.length">
                        <td colspan="10" class="noRecords text-center mt-2">
                          <span class="mt-2"
                            >{{ staticText.common.no_records_found }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Insights">
        <app-power-bi-module
          page="governance_lighthouse_insight"
        ></app-power-bi-module>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
