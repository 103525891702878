<app-modal-header-common [title]="'Save Template'"> </app-modal-header-common>
<div style="padding: 20px">
    <h6>Template Name</h6>
    <input type="text" class="form-control" [(ngModel)]="templateName">
    <div style="display: flex; justify-content: end" class="mt-3">
        <button mat-raised-button color="primary" class="templ-btn" (click)="saveTemplate()">
            Save
        </button>
    </div>
    
</div>