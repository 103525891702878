import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class NotificationPageComponent implements OnInit {
  currentYear!: number;
  notficationsList: any[] = [];
  notificationListStatus: string = 'read';
  
  staticText: any = (textConfiguration as any).default;
  constructor(private notificationService: NotificationsService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.getAllNotificaitonMessage('unread');
  }

  getAllNotificaitonMessage(status: string) {
    this.notificationListStatus = status === 'unread' ? 'read' : 'unread';
    this.spinner.show();
    this.notificationService.getAllNotifications(status).subscribe((getUnreadList: any) => {
      this.notficationsList = getUnreadList.notifications;
      this.notficationsList = this.notficationsList.map((mapData: any) => {
        mapData['routepath'] = this.routePage(mapData?.subject.toLowerCase(), mapData?.details?.id);
        return mapData;
      })
      console.log("notficationsList", this.notficationsList);

      this.spinner.hide();
    })

  }


  routePage(Page: any, routeId: any) {
    let routepath: any;
    switch (Page) {
      case 'proposal':
        routepath = `/getproposal/${btoa(routeId)}/proposal-overview`
        break;
      case 'opportunity':
        routepath = `/rfx/`+btoa(routeId)+`/opportunity/preview`
        break;
      case 'delivery':
        routepath = `/balancedscorecard/dashboard?project=${btoa(routeId)}`
        break;
      case 'bvt':
        routepath = '/BVT/list'
        break;
      case 'okr':
        routepath = '/okrconvergence'
        break;
      case 'workforcedashboard':
        routepath = `/workforce/view/`+btoa(routeId)
        break;
      case 'ci':
        routepath = `/innovation/detail-ideas/`+btoa(routeId)
        break;
      default:
        routepath = ''
        break;
    }
    return routepath;
  }

  MarkAsReadNotification(id: number) {
    this.notificationService.getAllReadNotifications(id, '').subscribe((getReadStatus: any) => {
      if (getReadStatus?.status === 200) {
        this.notficationsList = getReadStatus?.body?.notifications;
      }
    });
  }

}
