import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TurnkeyCreateModalDialogComponent } from "./turnkey-create-modal/turnkey-create-modal";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "src/app/shared/services/message.service";
import { TunkeyCreateTemplateModalComponent } from "./turnkey-create-template-modal/tunkey-create-template-modal/tunkey-create-template-modal.component";
import { TagsService } from "src/app/shared/services/tag.service";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
  selector: "turnkey-preview-modal",
  templateUrl: "turnkey-preview-modal.html",
  styleUrls: ["turnkey-preview-modal.scss"],
})
export class TurnkeyPreviewModalDialogComponent implements AfterViewInit {
  template: any;
  constructor(
    public dialogRef: MatDialogRef<TurnkeyPreviewModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {
    this.template = this.data.dataKey;
  }

  getTagObject() {
    return {
      entity_id: "",
      entity_type: "turnkey",
    };
  }

  closeModal(bool: boolean = false): void {
    this.dialogRef.close(bool);
  }
}

@Component({
  selector: "app-turnkey-templates",
  templateUrl: "./turnkey-templates.component.html",
  styleUrls: ["./turnkey-templates.component.scss"],
})
export class TurnkeyTemplatesComponent implements OnInit, OnDestroy {
  templates: any = [];
  filteredTemplate: any = [];
  file: any;
  searchText: string = "";
  acceratorDetailsId: any;
  loading: any = false;
  filterCategory: any = null;
  filterName: any = null;
  ownersList: any = [];
  subscription: any;
  filterObj: any;
  tagFilterId: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private turnkeyTrackerService: TurnkeyTrackerService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private tagService: TagsService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.acceratorDetailsId = localStorage.getItem("accelerator_details");
    this.messageService.setGnews("Turnkey tracker templates");
    localStorage.removeItem("accelerator_details");
    this.getTemplates();
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getTemplates() {
    this.turnkeyTrackerService.getTemplates().subscribe(
      (res: any) => {
        res.forEach((category: any) => {
          category.templates.forEach((element: any) => {
            element.color = this.getGMapRandomColor();
          });
        });
        this.templates = res;
        this.filteredTemplate = res;
      },
      (error) => {}
    );
  }

  previewPlan(template: any) {
    const dialog = this.dialog.open(TurnkeyPreviewModalDialogComponent, {
      width: "80vw",
      data: {
        dataKey: template,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
        this.proceed(template.id);
      }
    });
  }

  proceed(id: any) {
    const dialog = this.dialog.open(TurnkeyCreateModalDialogComponent, {
      width: "500px",
      data: { template_id: id, accDetailsId: this.acceratorDetailsId },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
      }
    });
  }

  templateDownload() {
    window.open("/assets/template/turnkey_tracker_template.xlsx", "_blank");
  }

  onFilechange(event: any) {
    this.file = event.target.files[0];
    const dialog = this.dialog.open(TunkeyCreateTemplateModalComponent, {
      width: "500px",
      data: {
        uploadedFile: this.file,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("category", data.category);
        this.upload(formData, data);
      }
      event.target.value = "";
    });
  }

  async addTags(id: any, selectedTags: any) {
    if (selectedTags?.length > 0) {
      await selectedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "plan_template",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  upload(formData: any, dialogData: any) {
    this.turnkeyTrackerService.uploadFile(formData).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response) {
          this.file = [];
          this.addTags(response.id, dialogData.selectedTags);
          this.getTemplates();
        }
      },
      (err) => this.spinner.hide()
    );
  }

  deleteTemplate(id: any) {
    this.turnkeyTrackerService.deleteTemplate(id).subscribe((res: any) => {
      this.toastrService.success("Templated deleted successfully !");
      this.getTemplates();
    });
  }

  blankPlan() {
    this.proceed(null);
  }

  navigateToPlanCentral() {
    this.router.navigateByUrl("confluence/teamplantracker");
  }

  search() {
    const tags = this.tagFilterId.join("|");
    this.turnkeyTrackerService
      .getFilteredPlanTemplates(this.filterCategory, this.filterName, tags)
      .subscribe(
        (res: any) => {
          res.forEach((category: any) => {
            category.templates.forEach((element: any) => {
              element.color = this.getGMapRandomColor();
            });
          });
          this.templates = res;
          this.filteredTemplate = res;
        },
        (error) => {}
      );
  }
}
