import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-north-star',
  templateUrl: './it-north-star.component.html',
  styleUrls: ['./it-north-star.component.scss']
})
export class ItNorthStarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
