import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MessageService } from "src/app/shared/services/message.service";
import { NgxSpinnerService } from "ngx-spinner";
import { PrismService } from "src/app/shared/services/prism.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-organization-hierarchy",
  templateUrl: "./organization-hierarchy.component.html",
  styleUrls: ["./organization-hierarchy.component.scss"],
})
export class OrganizationHierarchyComponent implements OnInit, AfterViewInit {
  dataList: any = [];
  portfolioName: any;
  portfolioId: any;
  subportfolioName: any;
  selectedOwner: any;
  costCenterName: any;
  costCenterCode: any;
  orgUnitType: any;
  userList: any;
  orgUnitTypeList: any;
  type: any;
  subPortfolioId: any;
  orgName: any;
  constructor(
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private prism: PrismService,
    public dialog: MatDialog,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.messageService.setGnews("Masters - organization-hierarchy");
    this.getData();
    this.getUsers();
    this.getOrgUnitType();
    this.getOrgName();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const keplerElement = document.getElementById("organisationName");
      if (keplerElement) {
        keplerElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 1500);
  }

  getOrgName() {
    this.prism.getOrgName().subscribe((resp: any) => {
      this.orgName = resp.org_name;
    });
  }

  getData() {
    this.spinner.show();
    this.prism.getMasterhierarchy().subscribe((res: any) => {
      this.dataList = res;
      this.spinner.hide();
    });
  }
  async getUsers() {
    await this.prism.getUser().subscribe((res: any) => {
      this.userList = res;
    });
  }
  async getOrgUnitType() {
    await this.prism.getBaseValue("org_unit_type").subscribe((res: any) => {
      this.orgUnitTypeList = res;
    });
  }

  onClickCreatePortfolio(modal: any, type: any, item?: any) {
    this.portfolioId = "";
    this.portfolioName = "";
    this.selectedOwner = "";
    this.type = "portfolio";
    if (item && type == "update") {
      this.portfolioId = item.id;
      this.portfolioName = item.name;
      this.selectedOwner = item.owner?.id;
    }
    this.dialog.open(modal, {
      width: "40vw",
    });
  }
  onClickCreateSubPortfolio(item: any, modal: any, subport?: any) {
    this.portfolioId = item.id;
    this.portfolioName = item.name;
    this.subPortfolioId = "";
    this.subportfolioName = "";
    this.selectedOwner = "";
    this.costCenterName = "";
    this.costCenterCode = "";
    this.orgUnitType = "";
    this.type = "subportfolio";
    if (subport) {
      this.subPortfolioId = subport.id;
      this.subportfolioName = subport.name;
      this.selectedOwner = subport?.owner?.id;
      this.costCenterName = subport?.cost_center_name;
      this.costCenterCode = subport?.cost_center_code;
      this.orgUnitType = subport?.org_unit_type;
    }
    this.dialog.open(modal, {
      width: "40vw",
    });
  }

  savePortfolio() {
    if (this.portfolioId) {
      this.updatePortfolio();
      return;
    }
    if (this.portfolioName && this.selectedOwner) {
      let payload = {
        name: this.portfolioName,
        display_name: this.portfolioName,
        owner_id: this.selectedOwner,
        level: "portfolio",
      };
      this.prism.createPortfolio(payload).subscribe(
        (res: any) => {
          this.portfolioName = "";
          this.selectedOwner = "";
          this.dataList.push(res);
          this.toastrService.success("Portfolio created successfully.");
          this.dialog.closeAll();
        },
        (err: any) => {
          if (err?.error["name"]) {
            this.toastrService.warning(err?.error["name"][0]);
          }
        }
      );
    } else {
      this.toastrService.error("Enter all fields!");
    }
  }
  saveSubPortfolio() {
    if (this.subPortfolioId) {
      this.updatePortfolio();
      return;
    }
    if (this.subportfolioName && this.selectedOwner) {
      let payload = {
        name: this.subportfolioName,
        display_name: this.subportfolioName,
        owner_id: this.selectedOwner,
        level: "sub_portfolio",
        cost_center_name: this.costCenterName,
        cost_center_code: this.costCenterCode,
        org_unit_type: this.orgUnitType,
      };
      this.prism
        .createSubPortfolio(this.portfolioId, payload)
        .subscribe((res: any) => {
          let index = this.dataList.findIndex(
            (i: any) => i.id === this.portfolioId
          );
          this.dataList[index].children.push(res);
          this.portfolioName = "";
          this.portfolioId = "";
          this.subportfolioName = "";
          this.selectedOwner = "";
          this.costCenterName = "";
          this.costCenterCode = "";
          this.orgUnitType = "";
          this.toastrService.success("Subportfolio created successfully.");
          this.dialog.closeAll();
        });
    } else {
      this.toastrService.success(
        "Subportfolio Name,Owner are mandatory fields!"
      );
    }
  }

  updatePortfolio() {
    let payload;
    if (this.type == "subportfolio") {
      this.portfolioId = this.subPortfolioId;
      payload = {
        name: this.subportfolioName,
        display_name: this.subportfolioName,
        owner_id: this.selectedOwner,
        level: "Level 2",
        cost_center_name: this.costCenterName,
        cost_center_code: this.costCenterCode,
        org_unit_type: this.orgUnitType,
      };
    } else {
      payload = {
        name: this.portfolioName,
        display_name: this.portfolioName,
        owner_id: this.selectedOwner,
        level: "Level 1",
      };
    }
    this.prism
      .updatePortfolio(this.portfolioId, payload)
      .subscribe((res: any) => {
        this.getData();
        this.dialog.closeAll();
        // let index = this.dataList.findIndex((i:any) => i.id === this.portfolioId);
        // if(this.type=='subportfolio'){
        //   let index = this.dataList.findIndex((i:any) => i.id === this.portfolioId)
        //   this.dataList[index].children.push(res);
        // }else{
        //   this.dataList[index] = res;
        //   this.toastrService.success("Portfolio updated successfully.");
        // }

        this.portfolioId = "";
        this.subPortfolioId = "";
      });
  }

  getunitType(id: any) {
    return this.orgUnitTypeList.filter((e: any) => e.id === id)[0]?.name;
  }
}
