import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { MessageService } from "src/app/shared/services/message.service";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";

@Component({
  selector: "app-team-plan-tracker",
  templateUrl: "./team-plan-tracker.component.html",
  styleUrls: ["./team-plan-tracker.component.scss"],
  providers: [DatePipe],
})
export class TeamPlanTrackerComponent implements OnInit, OnDestroy {
  ownPlans: any;
  otherPlans: any;
  user: any;
  loading: any = false;
  filterCategory: any = null;
  filterName: any = null;
  filterOwner: any = null;
  ownersList: any = [];
  workgroupList: any = [];
  subscription: any;
  filterObj: any;
  tagFilterId: any;
  filterDueDate: any = null;
  filterWorkgroup: any = null;

  constructor(
    private router: Router,
    private turnkeyTrackerService: TurnkeyTrackerService,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private assessmentCentralService: AssessmentCentralService,
    private filterService: FilterService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.messageService.setGnews("Turnkey Tracker listing");
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    this.getAllPlans();
    this.getOwners();
    this.getWorkgroup();
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
  }

  getWorkgroup() {
    this.turnkeyTrackerService.getWorkgroups().subscribe((res: any) => {
      this.workgroupList = res;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getAllPlans() {
    this.turnkeyTrackerService.getPlans().subscribe((res: any) => {
      this.ownPlans = res.created_by_user;
      this.otherPlans = res.collaborating_plan;
      this.ownPlans.forEach((category: any) => {
        category.plans.forEach((element: any) => {
          (element.color = this.getGMapRandomColor()),
            (element.last_updated_on_dt = new Date(element.last_updated_on));
          element.progress = element.progress ? element.progress : 0;
        });
      });
      this.otherPlans.forEach((category: any) => {
        category.plans.forEach((element: any) => {
          (element.color = this.getGMapRandomColor()),
            (element.last_updated_on_dt = new Date(element.last_updated_on));
          element.progress = element.progress ? element.progress : 0;
        });
      });
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  navigate() {
    this.router.navigateByUrl("confluence/teamplantemplates");
  }

  openPlan(x: any) {
    this.router.navigateByUrl("confluence/plan/" + btoa(x.id + ""));
  }

  calcActivitiesCount(x: any) {
    let count = 0;
    x.phases.forEach((element: any) => {
      count = count + element.activities.length;
    });

    return count;
  }

  deletePlan(id: any, i: any) {
    this.turnkeyTrackerService.deletePlan(id).subscribe((res: any) => {
      this.ownPlans.splice(i, 1);
      this.toastrService.success("Plan deleted successfully");
    });
  }

  search() {
    const tags = this.tagFilterId.join("|");
    const dt = this.datePipe.transform(this.filterDueDate, "YYYY-MM-dd");
    this.turnkeyTrackerService
      .getFilteredPlans(
        this.filterCategory,
        this.filterName,
        tags,
        this.filterOwner,
        dt,
        this.filterWorkgroup
      )
      .subscribe((res: any) => {
        this.ownPlans = res.created_by_user;
        this.otherPlans = res.collaborating_plan;
        this.ownPlans.forEach((category: any) => {
          category.plans.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on));
            element.progress = element.progress ? element.progress : 0;
          });
        });
        this.otherPlans.forEach((category: any) => {
          category.plans.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on));
            element.progress = element.progress ? element.progress : 0;
          });
        });
      });
  }
}
