import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { Router,ActivatedRoute } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { SurveyService } from "src/app/shared/services/survey.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import {
  IReportEmbedConfiguration,
  models,
  Page,
  Report,
  service,
  Embed,
} from "powerbi-client";
import { PowerBiService } from "../../../shared/services/power-bi.service";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";
import { MessageService } from "src/app/shared/services/message.service";
import { CreateSurveyDialogComponent } from "../hubble/create-survey-dialog/create-survey-dialog.component";
import { DeletePopupComponent } from "src/app/shared/components/delete-popup/delete-popup.component";
import { DatePipe } from "@angular/common";
 

const noData = require("highcharts/modules/no-data-to-display");
noData(Highcharts);

@Component({
  selector: "app-survey-central",
  templateUrl: "./survey-central.component.html",
  styleUrls: ["./survey-central.component.scss"],
  providers: [DatePipe],
})
export class SurveyCentralComponent implements OnInit {
  @Output() surveyIdEmit = new EventEmitter();
  @Output() loadData = new EventEmitter<string>();
  totalSurveyList: any = [];
  publishedSurveyList: any;
  totalPublishedCount: any;
  Highcharts: typeof Highcharts = Highcharts;
  staticText: any = (textConfiguration as any).default;
  typeList: any;
  filteredTeamList: any;
  teamList: any;
  teamCreateForm: FormGroup | any;
  backgroundColors: any = [];
  filterName: any = null;
  filterType: any = null;
  filterApplicability: any = null;

  allowed: any;
  reportModel: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  reportConfig = this.reportModel;
  reportConfigSUnBurst = this.reportModel;
  sentimentGraph = this.reportModel;
  reportConfigRadarChart = this.reportModel;
  rightPanelBiReport: any;
  zoomLevel: any;
  surveySummary: any;
  loading: any = false;
  pageType:any;
  reportClass = "report-container";
  constructor(
    private surveyService: SurveyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private permissionsService: NgxPermissionsService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private PowerBiService: PowerBiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getTypeList();
    this.permissionsService.loadPermissions(
      JSON.parse(localStorage.getItem("permission") || "{}").permissions
    );
    this.pageType = this.route.snapshot.queryParamMap.get('type');
    this.teamCreateForm = this.formBuilder.array([]);
    this.messageService.setGnews("Hubble - Home page");
    this.allowed = this.permissionsService.getPermission("nps.add_survey")
      ? true
      : false;
    if (this.allowed) {
      this.getTeamList();
    }
    setTimeout((e: any) => {
      this.getDataSource();
    }, 400);
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  handleResponse(res: any) {
    res.forEach((type: any) => {
      type.surveys.forEach((element: any) => {
        const typemapper = this.typeList.find(
          (form: any) => form.id === element.type
        );
        this.teamCreateForm.push(
          this.formBuilder.control({
            id: new FormControl(element.id),
            name: new FormControl(element.name),
            type: typemapper
              ? new FormControl(typemapper.display_name)
              : new FormControl(""),
            applicability: new FormControl(element.applicability),
          })
        );
        this.backgroundColors.push(this.getGMapRandomColor());
        this.totalSurveyList.push(element);
      });
    });
  }

  getDataSource() {
    this.spinner.show();
    this.teamCreateForm.clear();
    this.backgroundColors = [];
    this.surveyService
      .getFilteredSurveyCentral(
        this.filterName,
        this.filterApplicability,
        this.filterType
      )
      .subscribe((res: any) => {
        this.spinner.hide();
        this.handleResponse(res);
      });
  }

  editSurvey(data: any) {}

  getTypeList() {
    this.surveyService.getTypeList().subscribe((res: any) => {
      this.typeList = res;
    });
  }

  openCreateSurveyDialog() {
    const dialog = this.dialog.open(CreateSurveyDialogComponent, {
      width: "500px",
      data: {
        isTemplate: true,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getDataSource();
      }
    });
  }

  showDetails(survey: any) {
    const selectedSurvey = this.totalSurveyList.find(
      (item: any) => item.id === survey.value.id.value
    );
    const dialog = this.dialog.open(CreateSurveyDialogComponent, {
      width: "500px",
      data: {
        isTemplate: true,
        surveyInfo: selectedSurvey,
      },
    });
  }

  navigateToHubble() {
    this.router.navigateByUrl("performanceInsights/hubble");
  }

  viewResults() {
    this.surveyService.setActiveTab("results");
    this.router.navigate([`BVT/survey-details/results`]);
  }

  viewSurveyDetails(form: any) {
    // this.surveyService.setActiveTab("questions")
    this.router.navigate([`BVT/take-survey/${btoa(form?.value?.id?.value)}`]);
  }

  viewClosedSurveyDetails(item: any) {
    this.router.navigate([`BVT/take-survey/${btoa(item.id)}`]);
  }

  getTeamList(type?: any) {
    this.surveyService.getTeam().subscribe((res: any) => {
      this.teamList = res.risk_owners;
      this.filteredTeamList = res.risk_owners;
    });
  }

  searchTeam(index: any) {
    return this.filteredTeamListFilter(index);
  }

  filteredTeamListFilter(index: any) {
    if (this.filteredTeamList) {
      let filteringArray = this.teamCreateForm.controls[index].value.respondents
        .value
        ? this.teamCreateForm.controls[index].value.respondents.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredTeamList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (innerel: any) {
                  return innerel.id == array_el.id;
                }).length == 0
              );
            })
          : this.filteredTeamList;

      filteredArray =
        typeof this.teamCreateForm.controls[index].value.input.value ===
        "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(
                    this.teamCreateForm.controls[
                      index
                    ].value.input.value.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  removeTeam(team: string, indx: any): void {
    const index =
      this.teamCreateForm.controls[indx].value.respondents.value.indexOf(team);

    if (index >= 0) {
      this.teamCreateForm.controls[indx].value.respondents.value.splice(
        index,
        1
      );
    }
  }

  selectedTeam(event: MatAutocompleteSelectedEvent, index: any): void {
    this.teamCreateForm.controls[index].value.respondents.value.push(
      event.option.value
    );
    this.teamCreateForm.controls[index].value.input.setValue(null);
  }

  addRespondant() {
    var payload: any = [];
    this.teamCreateForm.value.forEach((element: any) => {
      let surveyRec: any = {
        survey_id: element.id.value,
        users: [],
      };
      element.respondents.value.forEach((user: any) => {
        surveyRec.users.push(user);
      });
      payload.push(surveyRec);
    });

    this.surveyService.saveSurveyDetail(payload).subscribe((res: any) => {
      this.toastrService.success("Survey updated successfully");
    });
  }

  surveyName: any;
  surveyId: any;
  surveyOptions: any = [];
  surveyData: any;
  optionType: any;
  surveyType: any;

  emitSurveyEvent(
    surveyId: any,
    surveyName: any,
    surveyGraphId: any,
    type: any
  ) {
    this.surveyId = surveyId;
    this.surveyType = type;
    this.surveyName = surveyName;
    this.loadSurveyData(surveyId, surveyName);
    this.getPowerBIReportDetails(surveyName);
    // this.loadPowerBIReport(surveyId,surveyName,surveyGraphId);
    this.loadSurveySummary(surveyId);
    this.loadSurveyResponse();
    // this.surveyIdEmit.emit({surveyId:surveyId,surveyName:surveyName});
  }

  loadSurveySummary(surveyId: any) {
    this.surveyService.getSurveySummary(surveyId).subscribe((res: any) => {
      this.surveySummary = res;
    });
  }
  loadSurveyData(surveyId: any, surveyName: any) {
    this.spinner.show();
    this.surveyOptions = [];
    this.surveyService.loadSurveyById(surveyId).subscribe((res: any) => {
      this.spinner.hide();
      this.surveyData = res;
      this.surveyData.forEach((elem: any) => {
        if (elem?.question_type != "single_choice") {
          elem?.options.forEach((elm: any, key: any) => {
            this.surveyOptions.push({
              id: elm?.option?.id,
              display_text: elm?.option?.display_text,
            });
          });
        }
      });
      this.surveyOptions = this.surveyOptions
        .map((item: any) => item.display_text)
        .filter(
          (value: any, index: any, self: any) => self.indexOf(value) === index
        );
      this.optionType = this.surveyOptions[0];
      this.loadQuestionByOption();
    });
  }
  clickOptions(option: any) {
    this.optionType = option;
    this.loadQuestionByOption();
  }
  surveyDetailsByEachSubOption: any = [];
  surveyDetailsBySubotionCategory: any = [];
  singleChoiceQuestionEachOption: any = [];
  singleChoiceQuestionQuestionOptionCategory: any = [];

  loadQuestionByOption() {
    this.surveyDetailsByEachSubOption = [];
    this.surveyDetailsBySubotionCategory = [];
    this.singleChoiceQuestionEachOption = [];
    this.singleChoiceQuestionQuestionOptionCategory = [];
    this.surveyData.forEach((elem: any) => {
      var eachSubOption: any = [];
      var subOptionByCategories: any = [];
      var singleChoiceEachOption: any = [];
      var singleChoiceOptionByCategories: any = [];
      if (elem?.question_type == "single_choice") {
        singleChoiceEachOption.push({ options: elem?.options });
        singleChoiceOptionByCategories.push({
          positive: elem?.options[0]?.percentage
            ? Number(elem?.options[0]?.percentage)
            : 0 + elem?.options[1]?.percentage
            ? Number(elem?.options[1]?.percentage)
            : 0,
          neutral: elem?.options[2]?.percentage
            ? Number(elem?.options[2]?.percentage)
            : 0,
          negative: elem?.options[3]?.percentage
            ? Number(elem?.options[3]?.percentage)
            : 0 + elem?.options[4]?.percentage
            ? Number(elem?.options[4]?.percentage)
            : 0 + elem?.options[5]?.percentage
            ? Number(elem?.options[5]?.percentage)
            : 0,
        });
      } else {
        elem?.options.forEach((option: any) => {
          if (option?.option?.display_text == this.optionType) {
            eachSubOption.push({
              option: option?.option?.display_text,
              sub_options: option?.sub_options,
            });
            subOptionByCategories.push({
              option: option?.option?.display_text,
              positive:
                Number(option?.sub_options[0]?.sub_option_percentage) +
                Number(option?.sub_options[1]?.sub_option_percentage),
              neutral: Number(option?.sub_options[2]?.sub_option_percentage),
              negative:
                Number(option?.sub_options[3]?.sub_option_percentage) +
                Number(option?.sub_options[4]?.sub_option_percentage) +
                Number(option?.sub_options[5]?.sub_option_percentage),
            });
          }
        });
      }
      if (eachSubOption?.length > 0) {
        this.surveyDetailsByEachSubOption.push({
          display_text: elem?.display_text,
          eachSubOption,
        });
        this.surveyDetailsBySubotionCategory.push({
          display_text: elem?.display_text,
          subOptionByCategories,
        });
      }
      if (singleChoiceEachOption?.length > 0) {
        this.singleChoiceQuestionEachOption.push({
          display_text: elem?.display_text,
          singleChoiceEachOption,
        });
        this.singleChoiceQuestionQuestionOptionCategory.push({
          display_text: elem?.display_text,
          singleChoiceOptionByCategories,
        });
      }
    });
  }
  biReportFlag: any = {
    reportConfig: false,
    reportConfigSUnBurst: false,
    sentimentGraph: false,
    reportConfigRadarChart: false,
  };
  getPowerBIReportDetails(surveyName: any) {
    let name = surveyName.toLowerCase().replace(/ /g, "_");
    this.PowerBiService.getPowerBIReportDetails(name).subscribe((res: any) => {
      let report = res;
      this.loadPowerBIReport(report);
    });
  }

  loadPowerBIReport(report: any) {
    this.biReportFlag = {
      reportConfig: false,
      reportConfigSUnBurst: false,
      sentimentGraph: false,
      reportConfigRadarChart: false,
    };
    this.reportConfig = this.reportModel;
    this.reportConfigSUnBurst = this.reportModel;
    this.sentimentGraph = this.reportModel;
    this.reportConfigRadarChart = this.reportModel;
    this.setBIZoomLevel();
    var basicFilter: any = {
      // $schema: "http://powerbi.com/product/schema#basic",
      // target: {
      //     table: "survey_data",
      //     column: "survey_id"
      // },
      // operator: "In",
      // values:[`${surveyName}`],
      // filterType: models.FilterType.Basic
    };
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
      report.forEach((element: any) => {
        this.PowerBiService.getBIEmbedURL(
          res.access_token,
          element?.report_id
        ).subscribe((reportData: any) => {
          this.PowerBiService.getBIEmbedToken(
            res.access_token,
            element?.report_id,
            element?.group
          ).subscribe((tokenData: any) => {
            if (element.section == "avg_score_each_quest") {
              this.biReportFlag.reportConfig = true;
              this.reportConfig = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "comp_avg_score_port_subport") {
              this.biReportFlag.reportConfigSUnBurst = true;
              this.reportConfigSUnBurst = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "sentiment_score_avg_Score_each_quest") {
              this.biReportFlag.sentimentGraph = true;
              this.sentimentGraph = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
            if (element.section == "section_vendor_comp") {
              this.biReportFlag.reportConfigRadarChart = true;
              this.reportConfigRadarChart = this.loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                element?.report_name
              );
            }
          });
        });
      });
    });
  }
  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      pageName: pageName,
      //  filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: this.zoomLevel,
        background: models.BackgroundType.Transparent,
      },
    };
  }
  setBIZoomLevel() {
    if (window.innerWidth > 1500) {
      this.zoomLevel = 0.6;
    } else if (window.innerWidth <= 1500 && window.innerWidth > 1246) {
      this.zoomLevel = 0.5;
    } else if (window.innerWidth <= 1245) {
      this.zoomLevel = 0.4;
    }
  }

  refresh(id: any) {
    this.spinner.show();
    this.surveyService.refreshData(id).subscribe((res: any) => {
      this.loadData.emit();
      this.spinner.hide();
    });
  }

  closeSurveyDetails() {
    this.surveyName = "";
    this.totalSurveyResponse = [];
  }
  totalSurveyResponse: any;
  loadSurveyResponse() {
    this.spinner.show();

    this.totalSurveyResponse = [];
    this.surveyService
      .getSurveyResponseById(this.surveyId)
      .subscribe((response: any) => {
        this.accordionTask("one");
        this.spinner.hide();

        // this.surveyResponseNew = this.barchartNew(response);
        // let objDiv: any = document.getElementById("centerPaneD");
        // objDiv.scrollTop = objDiv.scrollHeight;
        var maxAvrageVendor: any = [];

        response.forEach((res: any) => {
          var options: any = [];
          var datas: any = [];
          var avrages: any = [];
          maxAvrageVendor = [];
          res.options.forEach((opt: any) => {
            options.push(opt?.option?.display_text);
            opt?.stats.forEach((val: any) => {
              const index = avrages.findIndex((x: any) => x.name === val?.year);
              if (index >= 0) {
                avrages[index]?.data?.push(Number(val?.average));
              } else {
                avrages.push({
                  type: "column",
                  name: val?.year,
                  data: [Number(val?.average)],
                  dataLabels: { enabled: true },
                });
              }
              maxAvrageVendor.push({
                option: opt?.option?.display_text,
                avg: Number(val?.average),
                year: val?.year,
              });
            });
          });
          var res: any = {
            display_text: res.display_text,
            chart: this.barchartReturn(res.display_text, options, avrages),
            id: res.id,
            question_type: res.question_type,
            maxAvrageVendor,
          };
          this.totalSurveyResponse.push(res);
        });
      });
  }
  accordionTask(type: any) {
    var collapseOne = document.getElementById("collapseOne");
    var collapseOneButton = document.getElementById("collapseOneButton");
    var collapseTwo = document.getElementById("collapseTwo");
    var collapseTwoButton = document.getElementById("collapseTwoButton");
    if (type == "one") {
      collapseOne?.classList.remove("collapse");
      collapseOneButton?.classList.remove("collapsed");
      collapseTwo?.classList.add("collapse");
      collapseTwoButton?.classList.add("collapsed");
    }
    if (type == "two") {
      collapseOne?.classList.add("collapse");
      collapseOneButton?.classList.add("collapsed");
      collapseTwo?.classList.remove("collapse");
      collapseTwoButton?.classList.remove("collapsed");
    }
  }
  barchartReturn(question: any, options: any, values: any) {
    return {
      chart: {
        type: "category",
        height: 180,
      },
      title: {
        text: "",
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        scrollbar: {
          enabled: false,
        },
        categories: options,
      },
      series: values,
      dataLabels: {
        enabled: true,
      },
      // series: [
      //   {
      //     'type': 'column',
      //     data: values,
      //     dataLabels: {
      //       enabled: true,
      //      // rotation: -90,
      //       color: '#00000',
      //       align: 'right',
      //       y: 10, // 10 pixels down from the top
      //       style: {
      //           fontSize: '10px',
      //        //   fontFamily: 'helvetica, arial, sans-serif',
      //           textShadow: false,
      //           fontWeight: 'normal'

      //       }
      //   }
      //   }
      // ],
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
      legend: { enabled: true },
      yAxis: {
        title: false,
      },
    };
  }

  getHighestField(data: any) {
    if (data.length > 0) {
      return data.reduce(function (prev: any, current: any) {
        if (+current.avg > +prev.avg) {
          return current;
        } else {
          return prev;
        }
      });
    }
    return;
  }

  deleteTemplate(id: any, name: string) {
    let dialogRef = this.dialog.open(DeletePopupComponent, {
      data: name,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.surveyService.deleteSurveyTemplate(id).subscribe((res: any) => {
          this.toastrService.success("Template deleted successfully !");
          this.getDataSource();
        });
      }
    });
  }

  search() {
    this.teamCreateForm.clear();
    this.backgroundColors = [];
    this.surveyService
      .getFilteredSurveyCentral(
        this.filterName,
        this.filterApplicability,
        this.filterType
      )
      .subscribe((res: any) => {
        this.handleResponse(res);
      });
  }
}
