import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interactive-reviews',
  templateUrl: './interactive-reviews.component.html',
  styleUrls: ['./interactive-reviews.component.scss']
})
export class InteractiveReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
